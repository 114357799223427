var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: "shrine-" + _vm.shrine.id + " shrine-type-" + _vm.shrine.type,
      on: {
        click: function ($event) {
          _vm.rewardPopup = true
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "shrine",
          class: {
            opened: _vm.isShrineOpen,
            closed: !_vm.isShrineOpen,
            "points-positive": _vm.pointsAmountPositive,
            "points-zero": !_vm.pointsAmountPositive,
          },
        },
        [
          _c("div", {
            staticClass: "pos-absolute shrine-glow",
            class: "bg-" + _vm.shrine.imgName + "-glow-gold",
          }),
          _c("div", {
            staticClass: "pos-absolute shrine-hover d-none",
            class: "bg-" + _vm.shrine.imgName + "-glow-purple",
          }),
          _c("div", {
            staticClass: "pos-absolute shrine-img",
            class: "bg-" + _vm.shrine.imgName,
          }),
          _vm.shrine.rewards.length
            ? _c(
                "div",
                {
                  staticClass:
                    "pos-absolute shrine-stock d-flex flex-row justify-center",
                  class: "stock-" + _vm.shrine.type,
                },
                _vm._l(_vm.rewardsToShow, function (reward) {
                  return _c("div", { class: "mini-" + reward.imgClass })
                }),
                0
              )
            : _vm._e(),
          _vm.isShrineOpen && !_vm.loadingPoints
            ? _c("span", { staticClass: "points h5" }, [
                _c("img", {
                  staticClass: "work-icon",
                  attrs: { src: "/img/staking-map/work.svg" },
                }),
                _vm._v(_vm._s(_vm.points) + " "),
              ])
            : _vm._e(),
          _vm.isShrineOpen && _vm.loadingPoints
            ? _c("v-progress-circular", {
                staticClass: "points-spinner",
                attrs: { indeterminate: "", size: "30" },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.shrine.rewards.length > 0
        ? _c("popup-shrine", {
            attrs: {
              limitedRewards: _vm.limitedRewards,
              milestoneId: this.milestoneId,
              model: _vm.rewardPopup,
              pointsAmount: _vm.points,
              pointsDecimals: _vm.pointsDecimals,
              rewardType: _vm.shrine.rewardType,
              shrine: _vm.shrine,
              "content-class": _vm.contentClass,
            },
            on: {
              dialogClosed: function ($event) {
                _vm.rewardPopup = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }