var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "select-dialog" },
    [
      _c(
        "v-dialog",
        {
          attrs: {
            scrollable: "",
            width: "600",
            "max-width": "96%",
            "content-class": _vm.contentClass,
          },
          model: {
            value: _vm.isOpen,
            callback: function ($$v) {
              _vm.isOpen = $$v
            },
            expression: "isOpen",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "dialog-box-decorated-edges-mini" },
            [
              _c("div", {
                staticClass: "dialog-box-decorated-edges-close-btn-mini",
                on: {
                  click: function ($event) {
                    return _vm.closeBtnClick()
                  },
                },
              }),
              _c("v-card-title", { staticClass: "title" }, [
                _c("div", {
                  staticClass: "ml-md--2",
                  class: "bg-" + _vm.shrine.headerClass,
                }),
                _c("span", { staticClass: "ml-md-4 title-text" }, [
                  _vm._v(_vm._s(_vm.title)),
                ]),
                _c("span", { staticClass: "ml-auto mr-1 title-day" }, [
                  _vm._v(_vm._s(_vm.day) + " day"),
                ]),
              ]),
              _vm.loadingForSuccess
                ? _c(
                    "v-card-text",
                    { staticClass: "rewards mt-2 pt-6 text-center" },
                    [
                      _c(
                        "div",
                        { staticClass: "progress-container" },
                        [
                          _vm.loadingForSuccess
                            ? _c("v-progress-circular", {
                                attrs: {
                                  indeterminate: "",
                                  size: 150,
                                  color: "amber",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              !_vm.loadingForSuccess && !_vm.isSuccessInformationView
                ? _c(
                    "v-card-text",
                    { staticClass: "rewards mt-2 pt-6 pa-2" },
                    [
                      _c(
                        "v-tabs",
                        {
                          staticClass: "tabs-names",
                          class: !_vm.shrine.rewardTabs ? "tabs-hidden" : "",
                          attrs: {
                            grow: "",
                            "background-color": "transparent",
                          },
                          model: {
                            value: _vm.rewardTab,
                            callback: function ($$v) {
                              _vm.rewardTab = $$v
                            },
                            expression: "rewardTab",
                          },
                        },
                        _vm._l(_vm.shrine.rewards, function (tabs, id) {
                          return _c(
                            "v-tab",
                            { key: "t" + id, staticClass: "tab-name" },
                            [
                              tabs.icon
                                ? _c("icon-element", {
                                    attrs: { element: tabs.icon, inline: "" },
                                  })
                                : _vm._e(),
                              _vm._v(" " + _vm._s(tabs.name) + " "),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                      _c(
                        "v-tabs-items",
                        {
                          staticClass: "tabs-content",
                          model: {
                            value: _vm.rewardTab,
                            callback: function ($$v) {
                              _vm.rewardTab = $$v
                            },
                            expression: "rewardTab",
                          },
                        },
                        _vm._l(_vm.shrine.rewards, function (tabs, tabId) {
                          return _c(
                            "v-tab-item",
                            { key: "r" + tabId },
                            _vm._l(tabs.items, function (rew, id) {
                              return _c(
                                "v-row",
                                {
                                  key: "rr" + id,
                                  staticClass: "reward ml-0 mt-0",
                                  attrs: {
                                    "no-gutters":
                                      _vm.$vuetify.breakpoint.xsOnly,
                                  },
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0 text-center pt-2",
                                      attrs: { cols: "3", sm: "2" },
                                    },
                                    [
                                      _c("div", {
                                        staticClass: "reward-img",
                                        class: "bg-" + rew.imgClass,
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "9", sm: "7" } },
                                    [
                                      _c("h3", [_vm._v(_vm._s(rew.name))]),
                                      _c("div", { staticClass: "mt-2" }, [
                                        _vm._v(_vm._s(rew.description)),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "3" } },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "reward-cost" },
                                        [
                                          _vm._v(" " + _vm._s(rew.cost) + " "),
                                          _c("img", {
                                            staticClass: "work-icon",
                                            attrs: {
                                              src: "/img/staking-map/work.svg",
                                            },
                                          }),
                                        ]
                                      ),
                                      !_vm.isWalletConnected &&
                                      ((8 === _vm.shrine.id &&
                                        5 === rew.rewardId) ||
                                        (14 === _vm.shrine.id &&
                                          3 === rew.rewardId))
                                        ? _c("div", [
                                            _c(
                                              "span",
                                              { staticClass: "text--primary" },
                                              [
                                                _c("center", [
                                                  _vm._v(
                                                    " Connect wallet to see remaining amount "
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ])
                                        : _vm.limitedRewards[rew.rewardId] &&
                                          _vm.limitedRewards[rew.rewardId].max >
                                            0
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "my-2" },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      "Available: " +
                                                        _vm._s(
                                                          _vm.limitedRewards[
                                                            rew.rewardId
                                                          ].remaining
                                                        ) +
                                                        " / " +
                                                        _vm._s(
                                                          _vm.limitedRewards[
                                                            rew.rewardId
                                                          ].max
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                                  _c("v-progress-linear", {
                                                    attrs: {
                                                      color:
                                                        _vm.availableRewardsCounterColor(
                                                          _vm.limitedRewards[
                                                            rew.rewardId
                                                          ].remaining
                                                        ),
                                                      value:
                                                        (_vm.limitedRewards[
                                                          rew.rewardId
                                                        ].remaining /
                                                          _vm.limitedRewards[
                                                            rew.rewardId
                                                          ].max) *
                                                        100,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm.limitedRewards[rew.rewardId]
                                                .remaining > 0
                                                ? _c("input-amount", {
                                                    attrs: {
                                                      id: rew.rewardId,
                                                      allowZeroValue: "",
                                                      "init-value":
                                                        _vm.selectedAmounts[
                                                          rew.rewardId
                                                        ],
                                                      "max-value": Math.min(
                                                        _vm.calculateMaxValue(
                                                          rew.rewardId,
                                                          rew
                                                        ),
                                                        _vm.limitedRewards[
                                                          rew.rewardId
                                                        ].remaining
                                                      ),
                                                    },
                                                    on: {
                                                      valueChanged:
                                                        _vm.valueChanged,
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _c("input-amount", {
                                            attrs: {
                                              id: rew.rewardId,
                                              allowZeroValue: "",
                                              "init-value":
                                                _vm.selectedAmounts[
                                                  rew.rewardId
                                                ],
                                              "max-value":
                                                _vm.calculateMaxValue(
                                                  rew.rewardId,
                                                  rew
                                                ),
                                            },
                                            on: {
                                              valueChanged: _vm.valueChanged,
                                            },
                                          }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isSuccessInformationView
                ? _c(
                    "v-card-text",
                    { staticClass: "rewards mt-2 pt-6 text-center" },
                    [
                      _c("h3", { staticClass: "mb-4 text-none" }, [
                        _vm._v("You have received:"),
                      ]),
                      _vm._l(
                        Object.entries(this.selectedAmounts),
                        function (row) {
                          return row[1] > 0
                            ? _c("div", [
                                _c(
                                  "span",
                                  {
                                    staticClass: "m-0 p-0 d-flex align-center",
                                    staticStyle: { "font-size": "120%" },
                                  },
                                  [
                                    _vm._v(" " + _vm._s(row[1]) + "x "),
                                    _c("div", {
                                      staticClass: "ml-2 mr-2",
                                      class:
                                        "bg-" +
                                        _vm.rewardObjectByRewardId(row[0])
                                          .imgClass,
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.rewardObjectByRewardId(row[0])
                                            .name
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e()
                        }
                      ),
                      _c("br"),
                      _vm._v(" You can view them in: "),
                      _c("br"),
                      _vm.isEnhancementType
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                to: { name: "MyEnhancements", params: {} },
                              },
                            },
                            [_vm._v("Bottomless Bag")]
                          )
                        : _vm._e(),
                      _vm.isCardNftType
                        ? _c(
                            "v-btn",
                            { attrs: { to: { name: "MyCards", params: {} } } },
                            [_vm._v("My Cards")]
                          )
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
              _c(
                "v-card-actions",
                { staticClass: "card-action" },
                [
                  _c("span", { staticClass: "action-text" }, [
                    _vm._v(" You have "),
                    _c("b", [_vm._v(_vm._s(_vm.pointsAmount))]),
                    _c("img", {
                      staticClass: "work-icon ml-1",
                      staticStyle: { "margin-top": "-3px" },
                      attrs: { src: "/img/staking-map/work.svg" },
                    }),
                    _vm._v(" remaining at this milestone "),
                  ]),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-get",
                      attrs: {
                        disabled:
                          !_vm.isCostWithinPoints ||
                          0 === _vm.sumAllChosenRewards,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.requestReward()
                        },
                      },
                    },
                    [
                      _vm._v(" Get for -"),
                      _c(
                        "span",
                        { class: !_vm.isCostWithinPoints ? "red--text" : "" },
                        [_vm._v(_vm._s(_vm.sumAllChosenRewards))]
                      ),
                      _c("img", {
                        staticClass: "work-icon ml-1",
                        attrs: { src: "/img/staking-map/work.svg" },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }