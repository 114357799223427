<template>
    <div :class="'shrine-'+shrine.id+ ' shrine-type-'+shrine.type" @click="rewardPopup=true">
        <div class="shrine"
             :class="{
                'opened': isShrineOpen,
                'closed': !isShrineOpen,
                'points-positive': pointsAmountPositive,
                'points-zero': !pointsAmountPositive
             }"
        >
            <div class="pos-absolute shrine-glow" :class="'bg-'+shrine.type+'-glow-gold'"></div>
            <div class="pos-absolute shrine-hover d-none" :class="'bg-'+shrine.type+'-glow-purple'"></div>
            <div class="pos-absolute shrine-img" :class="'bg-'+shrine.imgName+(isShrineOpen?'-open':'-close')"></div>
            <span class="points h5" v-if="isShrineOpen && !loadingPoints">
            <img class="work-icon" src="/img/staking-map/work.svg"/>{{ points }}
        </span>
            <v-progress-circular
                v-if="isShrineOpen && loadingPoints"
                size="30"
                indeterminate
                class="points-spinner"
            ></v-progress-circular>
        </div>
        <popup-shrine
            v-if="shrine.rewards.length>0"
            :milestoneId="this.milestoneId"
            :shrine="shrine"
            :model="rewardPopup"
            :pointsAmount="points"
            :pointsDecimals="pointsDecimals"
            v-on:dialogClosed="rewardPopup=false"
            :rewardType="shrine.rewardType"
            :limitedRewards="limitedRewards"
        >
        </popup-shrine>
    </div>
</template>

<script>
import PopupShrine from "./popup-shrine";
import {StakingMapMilestoneFactory} from "../../services/contracts/stakingMapV2Contract";

export default {
    components: {PopupShrine},
    props: {
        milestoneId: Number,
        shrine: Object,
        open: Boolean,
        decimals: {
            type: Number,
            default: 0
        },
        limitedRewardId: Number
    },
    data() {
        return {
            loadingPoints: true,
            points: 0,
            pointsDecimals: 0,
            rewardPopup: false,
            limitedRewards: {},
        }
    },
    mounted() {
        if (this.isWalletConnected) {
            this.fetchPoints(this.web3);
        } else {
            window.addEventListener('user-wallet-loaded', () => {
                this.fetchPoints(this.web3);
            });
        }
    },
    methods: {
        shrineImgByStatus(shrine, open = false) {
            const status = open ? 'open' : 'close';
            return '/img/staking-map/' + shrine.type + '/' + shrine.imgName + '-' + status + '.png';
        },
        fetchPoints(web3) {
            this.loadingPoints = true;
            const rewardType = this.shrine.rewardType;
            const stakingMapMilestone = new StakingMapMilestoneFactory(rewardType);
            const stakingMapMilestoneContract = stakingMapMilestone.getContractClass(web3)

            stakingMapMilestoneContract.getRewardPointsAmountForMilestone(this.milestoneId)
                .then((res) => {
                    this.points = Math.floor(parseInt(res) / this.decimalDivider);
                    this.pointsDecimals = parseInt(res) % this.decimalDivider;
                    this.loadingPoints = false;
                })
                .catch((error) => {
                    console.error('Shrine-' + this.shrine.id + ': ' + error);
                })
                .finally(() => {
                });

            // Only for limited rewards
            if ('undefined' !== typeof this.limitedRewardId) {
                const rewardId = this.limitedRewardId;
                stakingMapMilestoneContract.getRewardLimitForId(this.milestoneId, rewardId)
                    .then((res) => {
                        this.limitedRewards[rewardId] = {
                            remaining: res._remaining,
                            max: res._max,
                        };
                    })
                    .catch((error) => {
                        console.error('Shrine-' + this.shrine.id + ': ' + error);
                    })
                    .finally(() => {
                    });
            }
        },
    },
    computed: {
        web3() {
            return this.$store.state.web3;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        },
        isShrineOpen() {
            return this.open || this.pointsAmountPositive;
        },
        pointsAmountPositive() {
            return this.points > 0;
        },
        decimalDivider() {
            return Math.pow(10, this.decimals)
        }
    }
};
</script>

<style lang="scss" scoped>
.shrine {
    position: relative;
    z-index: 20;

    &:hover {
        cursor: pointer;

        .shrine-hover {
            display: block !important;
        }
    }

    .shrine-glow {
        display: none;
    }

    &.opened {
        &.points-positive {
            .shrine-glow {
                display: block;
            }
        }
    }

    &.closed {
        -webkit-filter: grayscale(1);
        filter: grayscale(1);
    }

    .shrine-img {
        top: 10px;
        left: 10px
    }

    .shrine-stock {
        top: 30px;
        left: 20px
    }

    .points {
        width: 70px;
        text-align: center;
        font-size: 22px;
        position: absolute;
        top: 89px;
        display: block;
        left: 20px;
    }

    .points-spinner {
        position: absolute;
        top: 88px;
        left: 37px;
    }

    .work-icon {
        width: auto;
        height: 16px;
        display: inline;
        margin-left: -5px;
        margin-right: 2px;
    }
}

.shrine-type-stone-shrine {
    .points {
        top: 83px;
    }
}

.shrine-type-village-shrine {
    .points {
        top: 90px;
        left: 34px;
    }
}

.shrine-type-shrine-105 {
    .points {
        display: none;
    }
}

@media only screen and (max-width: 520px) {
    .shrine {
        img {
            width: 50px;
        }

        .shrine-glow,
        .shrine-hover {
            margin: 2px 0 0 5px;
        }

        .points {
            width: 44px;
            font-size: 16px;
            top: 55px;
            left: 13px;
        }

        .work-icon {
            height: 12px;
            margin-left: -1px;
            margin-right: 0;
        }
    }

    .shrine-type-stone-shrine {
        .points {
            top: 52px;
        }
    }

    .shrine-type-village-shrine {
        .points {
            left: 18px;
        }
    }
}
</style>

<style>
.bg-card-1 {
    width: 76px;
    height: 92px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -211px -459px;
}

.bg-card-2 {
    width: 76px;
    height: 92px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -307px -459px;
}

.bg-card-3 {
    width: 76px;
    height: 93px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -115px -459px;
}

.bg-leaf-shrine-glow-gold {
    width: 105px;
    height: 135px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -260px -10px;
}

.bg-leaf-shrine-glow-purple {
    width: 105px;
    height: 135px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -10px -169px;
}

.bg-phoenix-shrine-75-angry {
    width: 56px;
    height: 82px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -595px -10px;
}

.bg-phoenix-shrine-75-excited {
    width: 56px;
    height: 82px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -595px -112px;
}

.bg-phoenix-shrine-75-gender {
    width: 56px;
    height: 82px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -595px -214px;
}

.bg-phoenix-shrine-75-grumpy {
    width: 56px;
    height: 82px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -595px -316px;
}

.bg-phoenix-shrine-75-hair {
    width: 56px;
    height: 82px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -595px -418px;
}

.bg-phoenix-shrine-75-happy {
    width: 56px;
    height: 82px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -671px -10px;
}

.bg-phoenix-shrine-75-noble {
    width: 56px;
    height: 82px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -671px -112px;
}

.bg-phoenix-shrine-75-wicked {
    width: 56px;
    height: 82px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -671px -214px;
}

.bg-phoenix-tier-1 {
    width: 56px;
    height: 82px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -671px -316px;
}

.bg-phoenix-tier-2-blue {
    width: 56px;
    height: 82px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -671px -418px;
}

.bg-phoenix-tier-2-green {
    width: 56px;
    height: 82px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -10px -594px;
}

.bg-phoenix-tier-2-red {
    width: 56px;
    height: 82px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -86px -594px;
}

.bg-phoenix-tier-3-blue {
    width: 56px;
    height: 82px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -162px -594px;
}

.bg-phoenix-tier-3-green {
    width: 56px;
    height: 82px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -238px -594px;
}

.bg-phoenix-tier-3-red {
    width: 56px;
    height: 82px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -314px -594px;
}

.bg-shrine-header-potions {
    width: 88px;
    height: 60px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -403px -459px;
}

.bg-shrine-potions-close {
    width: 85px;
    height: 117px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -135px -169px;
}

.bg-shrine-potions-open {
    width: 85px;
    height: 117px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -240px -169px;
}

.bg-shrine-potions2-close {
    width: 85px;
    height: 117px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -385px -10px;
}

.bg-shrine-potions2-open {
    width: 85px;
    height: 117px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -385px -147px;
}

.bg-single-use-land-potion {
    width: 39px;
    height: 82px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -747px -112px;
}

.bg-single-use-wizard-potion {
    width: 44px;
    height: 82px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -747px -10px;
}

.bg-stone-shrine-75-close {
    width: 85px;
    height: 115px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -10px -324px;
}

.bg-stone-shrine-75-header {
    width: 68px;
    height: 60px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -479px -594px;
}

.bg-stone-shrine-75-open {
    width: 85px;
    height: 115px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -115px -324px;
}

.bg-stone-shrine-cards-close {
    width: 85px;
    height: 115px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -220px -324px;
}

.bg-stone-shrine-cards-open {
    width: 85px;
    height: 115px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -325px -324px;
}

.bg-stone-shrine-glow-gold {
    width: 105px;
    height: 139px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -10px -10px;
}

.bg-stone-shrine-glow-purple {
    width: 105px;
    height: 139px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -135px -10px;
}

.bg-stone-shrine-header-cards {
    width: 68px;
    height: 60px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -567px -594px;
}

.bg-stone-shrine-header-phoenix {
    width: 69px;
    height: 60px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -390px -594px;
}

.bg-stone-shrine-phoenix-close {
    width: 85px;
    height: 115px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -490px -10px;
}

.bg-stone-shrine-phoenix-open {
    width: 85px;
    height: 115px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -490px -145px;
}

.bg-stone-shrine-tshirt-close-tshirt {
    width: 85px;
    height: 115px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -490px -280px;
}

.bg-stone-shrine-tshirt-open-tshirt {
    width: 85px;
    height: 115px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -10px -459px;
}

.bg-shrine-90-close {
    width: 112px;
    height: 117px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -801px -258px;
}

.bg-shrine-90-header2 {
    width: 74px;
    height: 60px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -820px -686px;
}

.bg-shrine-90-open {
    width: 112px;
    height: 117px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -801px -375px;
}

.bg-egg {
    width: 82px;
    height: 82px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -801px -492px;
}

.bg-frog-s-legs {
    width: 82px;
    height: 82px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -801px -574px;
}

.bg-frog-Squawk {
    width: 82px;
    height: 82px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -0 -686px;
}

.bg-frog-squawk-pink {
    width: 82px;
    height: 82px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -82px -686px;
}

.bg-honeycomb {
    width: 82px;
    height: 82px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -164px -686px;
}

.bg-mushrooms-blue {
    width: 82px;
    height: 82px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -246px -686px;
}

.bg-mushrooms {
    width: 82px;
    height: 82px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -328px -686px;
}

.bg-nettle-s-leaf {
    width: 82px;
    height: 82px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -410px -686px;
}

.bg-nettle-s-leaf-blue {
    width: 82px;
    height: 82px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -492px -686px;
}

.bg-nettle-s-leaf-red {
    width: 82px;
    height: 82px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -574px -686px;
}

.bg-pile-bones {
    width: 82px;
    height: 82px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -656px -686px;
}

.bg-snail-slime {
    width: 82px;
    height: 82px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -738px -686px;
}

.bg-village-shrine-glow-gold {
    width: 128px;
    height: 129px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -801px -129px;
}

.bg-village-shrine-glow-purple {
    width: 128px;
    height: 129px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -801px -0;
}

.bg-shrine-105-glow-gold {
    width: 87px;
    height: 133px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -0 -768px;
}

.bg-shrine-105-glow-purple {
    width: 87px;
    height: 133px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -87px -768px;
}

.bg-shrine-105-open,
.bg-shrine-105-close {
    width: 67px;
    height: 117px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -862px -768px;
}

.bg-shrine-120-banner-limited-edition {
    width: 86px;
    height: 117px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -174px -768px;
}

.bg-shrine-120-banner-sold-out {
    width: 86px;
    height: 117px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -260px -768px;
}

.bg-shrine-120-empty-close {
    width: 86px;
    height: 117px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -346px -768px;
}

.bg-shrine-120-empty-open {
    width: 86px;
    height: 117px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -432px -768px;
}

.bg-shrine-120-green-close {
    width: 86px;
    height: 117px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -518px -768px;
}

.bg-shrine-120-green-open {
    width: 86px;
    height: 117px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -604px -768px;
}

.bg-shrine-120-header {
    width: 69px;
    height: 60px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -929px -85px;
}

.bg-shrine-120-red-close {
    width: 86px;
    height: 117px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -690px -768px;
}

.bg-shrine-120-red-open {
    width: 86px;
    height: 117px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -776px -768px;
}

.bg-shrine-120-tshirt-header {
    width: 69px;
    height: 60px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -929px -145px;
}

.bg-tshirt-icon {
    width: 78px;
    height: 85px;
    background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') -929px -0;
}


</style>
<style lang="scss">
@media only screen and (max-width: 520px) {
    $ratio: 1.7;
    $spriteWidth: 998px;
    $spriteHeight: 901px;

    @function resize($number) {
        @return calc(#{$number} / #{$ratio});
    }

    .shrine {
        .bg-shrine-glow {
            width: resize(105px);
            height: resize(135px);
            background-size: resize($spriteWidth) resize($spriteHeight) !important;
        }

        .bg-shrine-box {
            width: resize(85px);
            height: resize(117px);
            background-size: resize($spriteWidth) resize($spriteHeight) !important;
        }

        .bg-leaf-shrine-glow-gold {
            @extend .bg-shrine-glow;
            background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') resize(-260px) resize(-10px);
        }

        .bg-leaf-shrine-glow-purple {
            @extend .bg-shrine-glow;
            background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') resize(-10px) resize(-169px);
        }

        .bg-stone-shrine-glow-gold {
            @extend .bg-shrine-glow;
            background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') resize(-10px) resize(-10px);
        }

        .bg-stone-shrine-glow-purple {
            @extend .bg-shrine-glow;
            background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') resize(-135px) resize(-10px);
        }

        .bg-village-shrine-glow-gold {
            @extend .bg-shrine-glow;
            background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') resize(-801px) resize(-0px);
        }

        .bg-village-shrine-glow-purple {
            @extend .bg-shrine-glow;
            background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') resize(-801px) resize(-129px);
        }

        .bg-shrine-potions-close {
            @extend .bg-shrine-box;
            background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') resize(-135px) resize(-169px);
        }

        .bg-shrine-potions-open {
            @extend .bg-shrine-box;
            background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') resize(-240px) resize(-169px);
        }

        .bg-shrine-potions2-close {
            @extend .bg-shrine-box;
            background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') resize(-385px) resize(-10px);
        }

        .bg-shrine-potions2-open {
            @extend .bg-shrine-box;
            background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') resize(-385px) resize(-147px);
        }

        .bg-stone-shrine-cards-close {
            @extend .bg-shrine-box;
            background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') resize(-220px) resize(-324px);
        }

        .bg-stone-shrine-cards-open {
            @extend .bg-shrine-box;
            background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') resize(-325px) resize(-324px);
        }

        .bg-stone-shrine-phoenix-close {
            @extend .bg-shrine-box;
            background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') resize(-490px) resize(-10px);
        }

        .bg-stone-shrine-phoenix-open {
            @extend .bg-shrine-box;
            background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') resize(-490px) resize(-145px);
        }

        .bg-stone-shrine-75-open {
            @extend .bg-shrine-box;
            background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') resize(-115px) resize(-324px);
        }

        .bg-stone-shrine-75-close {
            @extend .bg-shrine-box;
            background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') resize(-10px) resize(-324px);
        }

        .bg-shrine-90-open {
            @extend .bg-shrine-box;
            width: resize(112px);
            height: resize(117px);
            background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') resize(-801px) resize(-375px);
        }

        .bg-shrine-90-close {
            @extend .bg-shrine-box;
            width: resize(112px);
            height: resize(117px);
            background: url('/img/staking-map/biomes/desert/shrine-reward-sprite5.png') resize(-801px) resize(-258px);
        }

        .bg-shrine-105-glow-gold,
        .bg-shrine-105-glow-purple {
            width: resize(87px);
            height: resize(133px);
            background-position: resize(-0) resize(-768px);
            background-size: resize($spriteWidth) resize($spriteHeight) !important;
        }

        .bg-shrine-105-glow-purple {
            background-position: resize(-87px) resize(-768px);
        }

        .bg-shrine-105-open,
        .bg-shrine-105-close {
            width: resize(67px);
            height: resize(117px);
            background-position: resize(-853px) resize(-770px);
            background-size: resize($spriteWidth) resize($spriteHeight) !important;
        }

        .bg-shrine-120-red-close {
            width: resize(86px);
            height: resize(117px);
            background-position: resize(-682px) resize(-768px);
            background-size: resize($spriteWidth) resize($spriteHeight) !important;
        }

        .bg-shrine-120-red-open {
            width: resize(86px);
            height: resize(117px);
            background-position: resize(-768px) resize(-768px);
            background-size: resize($spriteWidth) resize($spriteHeight) !important;
        }
    }
}
</style>
