var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-row", [
    _c(
      "div",
      {
        staticClass: "biome-1",
        class: { nextMapPreview: _vm.spyglassUsed },
        attrs: { id: "map" },
      },
      [
        _c("canvas", { attrs: { id: "canvas", "data-paper-hidpi": "off" } }),
        _c("div", {
          class: { nextMapPreviewMap: _vm.spyglassUsed },
          attrs: { id: "map-img" },
        }),
        _vm._l(_vm.pawns, function (pawn) {
          return _c("img", {
            staticClass: "d-none",
            attrs: { id: "pawn-" + pawn.id, src: pawn.img },
          })
        }),
        _vm._l(_vm.pawns, function (pawn) {
          return _c("img", {
            staticClass: "d-none",
            attrs: { id: "mini-pawn-" + pawn.id, src: pawn.imgMini },
          })
        }),
        _c("img", {
          staticClass: "d-none",
          attrs: { id: "work-icon", src: "/img/staking-map/work_16.png" },
        }),
        _c("img", {
          staticClass: "d-none",
          attrs: { id: "start-stand", src: "/img/staking-map/start-stand.png" },
        }),
        _vm._l(_vm.milestoneDays, function (m, id) {
          return _c(
            "div",
            { key: "m" + id, class: "milestone mile-" + (id + 1) },
            [_vm._v(_vm._s(m))]
          )
        }),
        _vm._l(_vm.milestones, function (shrine) {
          return _c(
            "div",
            {
              key: "sh" + shrine.id,
              staticClass: "step d-flex flex-column",
              class: "step-" + shrine.id,
            },
            [
              parseInt(shrine.id) === 7
                ? _c(
                    "div",
                    {
                      on: {
                        mouseover: function ($event) {
                          _vm.isShrineOpen(shrine)
                            ? (_vm.spyglassUsed = true)
                            : null
                        },
                        mouseleave: function ($event) {
                          _vm.spyglassUsed = false
                        },
                      },
                    },
                    [
                      _c("reward-shrine", {
                        attrs: {
                          milestoneId: parseInt(shrine.id),
                          shrine: shrine,
                          open: _vm.isShrineOpen(shrine),
                        },
                      }),
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      parseInt(shrine.id) === 8
                        ? _c("reward-shrine", {
                            attrs: {
                              milestoneId: parseInt(shrine.id),
                              shrine: shrine,
                              open: _vm.isShrineOpen(shrine),
                              "limited-reward-id": 5,
                            },
                          })
                        : _c("reward-shrine", {
                            attrs: {
                              milestoneId: parseInt(shrine.id),
                              shrine: shrine,
                              open: _vm.isShrineOpen(shrine),
                            },
                          }),
                    ],
                    1
                  ),
            ]
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }