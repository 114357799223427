<template>
    <v-row>
        <div id="map" :class="{'nextMapPreview':spyglassUsed}" class="biome-1">
            <canvas id="canvas" data-paper-hidpi="off" />
            <div id="map-img" :class="{'nextMapPreviewMap':spyglassUsed}"/>
            <img
                v-for="(pawn) in pawns"
                :id="'pawn-'+pawn.id" class="d-none"
                :src="pawn.img"
            />
            <img
                v-for="(pawn) in pawns"
                :id="'mini-pawn-'+pawn.id" class="d-none"
                :src="pawn.imgMini"
            />
            <img id="work-icon" class="d-none" src="/img/staking-map/work_16.png"/>


            <img id="start-stand" src="/img/staking-map/start-stand.png" class="d-none"/>

            <div :class="'milestone mile-'+(id+1)" v-for="(m,id) in milestoneDays" :key="'m'+id">{{ m }}</div>

            <div v-for="(shrine) in milestones" :key="'sh'+shrine.id"
                 class="step d-flex flex-column" :class="'step-' + shrine.id"
            >
                <div
                    v-if="parseInt(shrine.id) === 7"
                    @mouseover="isShrineOpen(shrine) ? spyglassUsed=true : null"
                    @mouseleave="spyglassUsed=false"
                >
                    <reward-shrine
                        :milestoneId="parseInt(shrine.id)"
                        :shrine="shrine"
                        :open="isShrineOpen(shrine)"
                    ></reward-shrine>
                </div>
                <div v-else>
                    <reward-shrine
                        v-if="parseInt(shrine.id) === 8"
                        :milestoneId="parseInt(shrine.id)"
                        :shrine="shrine"
                        :open="isShrineOpen(shrine)"
                        :limited-reward-id=5
                    ></reward-shrine>

                    <reward-shrine
                        v-else
                        :milestoneId="parseInt(shrine.id)"
                        :shrine="shrine"
                        :open="isShrineOpen(shrine)"
                    ></reward-shrine>
                </div>
            </div>

        </div>
    </v-row>

</template>

<script>
import Price from "../../components/price";
import RewardShrine from "../../components/staking-map/reward-shrine";
import paper from "paper";

const MAP_ID = 0;

export default {
    components: {RewardShrine, Price},
    props: {
        pawns: Array,
        shrineDetails: Object,
    },
    data: function () {
        return {
            currentSize: 'm',
            rewardPopup: {
                1: false,
                2: false,
                3: false,
                4: false,
                5: false,
                6: false,
                7: false,
                8: false,
            },
            spyglassUsed: false
        };
    },
    created() {
        if (this.isWindowSizeSm()) {
            this.currentSize = 's';
        }

        if (this.isWindowSizeXXSm()) {
            this.currentSize = 'xs';
        }

        window.addEventListener("resize", this.resizeWindowHandler);
    },
    destroyed() {
        window.removeEventListener("resize", this.resizeWindowHandler);
    },
    mounted: function () {
        this.initCanvas();
    },

    methods: {
        resizeWindowHandler() {
            let newSize = null;
            if (this.windowWidth() <= 520) {
                newSize = 'xs'
            } else if (this.windowWidth() <= 959) {
                newSize = 's'
            } else {
                newSize = 'm'
            }
            if (newSize !== this.currentSize) {
                this.currentSize = newSize;
                this.initCanvas();
            }
        },
        windowWidth() {
            return window.innerWidth;
        },
        isWindowSizeSm() {
            return this.windowWidth() <= 959; //TODO: export number to global var
        },
        isWindowSizeXXSm() {
            return this.windowWidth() <= 520; //TODO: export number to global var
        },
        isOnStartingPosition(day) {
            return day < 1;
        },
        initCanvas() {
            let canvas = new paper.PaperScope();
            canvas.setup('canvas');

            let mapPathSvg = '/img/staking-map/biomes/desert/map-path.svg';
            let mapPathPosition = {x: 500, y: 300};
            let pawnStartPosition = new paper.Point(60, 310);
            let mapScale = 1;
            if ('s' === this.currentSize || 'xs' === this.currentSize) {
                mapPathSvg = '/img/staking-map/biomes/desert/map-path-mobile.svg';
                mapPathPosition = {x: 272, y: 1000};
                pawnStartPosition = new paper.Point(70, 200);
            }

            if ('xs' === this.currentSize) {
                mapScale = 0.43;
                mapPathPosition = {x: 125, y: 440};
                pawnStartPosition = new paper.Point(50, 120);
            }

            canvas.paper.project.importSVG(mapPathSvg, (map) => {
                map.position = new paper.Point(mapPathPosition.x, mapPathPosition.y);
                map.scale(mapScale);

                // Pawns
                if (this.pawns.length) {
                    // Move pawns
                    const posCorrection = 0;
                    const pawnCorrectionY = 2;
                    this.pawns.forEach((pawn) => {
                        if (MAP_ID !== pawn.activeMap) { //Display pawns only for this map
                            return;
                        }
                        const currentPathSegment = map.children[pawn.offsetDayOnActiveMap + posCorrection] || map.children[map.children.length - 1];
                        let pawnImg = new paper.Raster(pawn.fullId);
                        pawnImg.position = currentPathSegment.position;
                        pawnImg.opacity = 0;

                        //1st day
                        if (this.isOnStartingPosition(pawn.offsetDayOnActiveMap)) {
                            let startStand = new paper.Raster('start-stand');
                            startStand.position = pawnStartPosition;
                            pawnImg = new paper.Raster('mini-' + pawn.fullId);
                            pawnImg.position = pawnStartPosition;
                        }
                        pawnImg.opacity = 0;

                        let pawnText = new paper.PointText(new paper.Point(30, 30));
                        pawnText.content = '+' + pawn.size;
                        pawnText.fontSize = 14;
                        pawnText.fillColor = '#fff';
                        pawnText.position = currentPathSegment.position;
                        pawnText.position.x += 8;
                        pawnText.position.y -= 12 + pawnCorrectionY;
                        pawnText.opacity = 0;

                        let workIcon = new paper.Raster('work-icon');
                        workIcon.position = currentPathSegment.position;
                        workIcon.position.x -= 8;
                        workIcon.position.y -= 13 + pawnCorrectionY;
                        workIcon.size = 16;
                        workIcon.opacity = 0;

                        setTimeout(() => {
                            if (pawnImg.height > 0) {
                                pawnImg.position.y -= pawnImg.height / 2 + pawnCorrectionY;
                                pawnImg.opacity = 1;
                                pawnText.opacity = 1;
                                workIcon.opacity = 1;

                                if (this.isOnStartingPosition(pawn.day)) {
                                    pawnText.opacity = 0;
                                    workIcon.opacity = 0;
                                }
                            }
                        }, 100);
                    });

                    // Color path walked
                    for (let i = 1; i <= Math.min(this.maxDay, map.children.length - 1); i++) {
                        map.children[i].fillColor = '#000';
                    }

                    // Color pawn's position
                    this.pawns.forEach((pawn) => {
                        if (MAP_ID !== pawn.activeMap) {  //Display pawns only for this map
                            return;
                        }
                        const pathSegment = map.children[pawn.day + posCorrection] || map.children[map.children.length - 1];
                        pathSegment.fillColor = 'purple';
                    });
                }
            });
        },
        isShrineOpen(shrine) {
            return this.maxDay >= shrine.day;
        },
    },
    computed: {
        milestones() {
            return Object.values(this.shrineDetails.milestones);
        },
        milestoneDays() {
            return this.milestones.map(v => v.day);
        },
        maxDay() {
            //TODO: get max days from contract (all stakes)?
            const daysOnMap = this.pawns.filter(a => a.activeMap === MAP_ID).map((a) => a.offsetDayOnActiveMap);
            const daysOfVeterans = this.pawns.filter(a => !!Number(a.activeBonuses[MAP_ID])).map((a) => a.day);
            return Math.max(...daysOnMap, ...daysOfVeterans);
        },
    },
    watch: {
        pawns(newPawns, oldPawns) {
            if (oldPawns.length === newPawns.length) {
                //do nothing
            } else {
                this.initCanvas();
            }
        }
    }
}
</script>

<style lang="scss" scoped>
#map {
    width: 1000px;
    height: 600px;
    position: relative;
    margin: auto;
}

#canvas {
    width: 1000px;
    height: 600px;
    position: relative;
    z-index: 10;
}

#map-img {
    width: 1000px;
    height: 600px;
    position: absolute;
    top: 0;
    left: 0;
    background: url('/img/staking-map/biomes/desert/map-big.png') 0 0 no-repeat;

    &.nextMapPreviewMap {
        background: url('/img/staking-map/biomes/shrooms/map-big.png') 0 0 no-repeat;
    }
}

.work-icon {
    width: auto;
    height: 16px;
}

.start-stand {
    position: absolute;
    top: 200px;
    left: 70px;
}

.milestone {
    top: 0;
    left: 0;
    position: absolute;
    margin: 0;
    padding: 0;

    &.mile-1 {
        top: 380px;
        left: 355px;
    }

    &.mile-2 {
        top: 437px;
        left: 675px;
    }

    &.mile-3 {
        top: 367px;
        left: 955px;
    }

    &.mile-4 {
        top: 172px;
        left: 676px;
    }

    &.mile-5 {
        top: 290px;
        left: 350px;
    }

    &.mile-6 {
        top: 38px;
        left: 277px;
    }

    &.mile-7 {
        top: 118px;
        left: 606px;
    }

    &.mile-8 {
        top: 32px;
        left: 938px;
    }
}

.step {
    top: 0;
    left: 0;
    position: absolute;
    margin: 0;
    padding: 0;

    &.step-1 {
        top: calc(380px + 50px);
        left: calc(355px - 45px);
    }

    &.step-2 {
        top: calc(440px + 20px);
        left: calc(675px - 90px);
    }

    &.step-3 {
        top: calc(367px - 80px);
        left: calc(960px - 160px);
    }

    &.step-4 {
        top: 215px;
        left: 632px;
    }

    &.step-5 {
        top: 145px;
        left: 330px;
    }

    &.step-6 {
        top: 65px;
        left: 225px;
    }

    &.step-7 {
        top: -30px;
        left: 575px;
    }

    &.step-8 {
        top: 68px;
        left: 892px;
    }

    &.unknown-icon {
        img {
            width: 80px;
        }
    }
}

@media only screen and (max-width: $sm) {
    #map {
        width: 556px;
        height: 2166px;
    }

    #canvas {
        width: 556px;
        height: 2166px;
    }

    #map-img {
        width: 556px;
        height: 2166px;
        background: url('/img/staking-map/biomes/desert/map-mobile.png') 0 0 no-repeat;

        &.nextMapPreviewMap {
            background: url('/img/staking-map/biomes/shrooms/map-mobile.png') 0 0 no-repeat;
        }
    }

    .milestone {
        font-size: 30px;

        &.mile-1 {
            top: 328px;
            left: 517px;
        }

        &.mile-2 {
            top: 475px;
            left: 164px;
        }

        &.mile-3 {
            top: 800px;
            left: 130px;
        }

        &.mile-4 {
            top: 995px;
            left: 515px;
        }

        &.mile-5 {
            top: 1165px;
            left: 165px;
        }

        &.mile-6 {
            top: 1475px;
            left: 112px;
        }

        &.mile-7 {
            top: 1670px;
            left: 530px;
        }

        &.mile-8 {
            top: 1850px;
            left: 174px;
        }
    }

    .step {
        &.step-1 {
            top: calc(360px - 60px - 20px);
            left: calc(510px - 150px);
        }

        &.step-2 {
            top: calc(500px + 80px - 20px);
            left: calc(172px - 40px);
        }

        &.step-3 {
            top: calc(802px + 90px);
            left: calc(142px - 50px);
        }

        &.step-4 {
            top: calc(995px - 10px);
            left: calc(515px - 150px);
        }

        &.step-5 {
            top: 1250px;
            left: 130px;
        }

        &.step-6 {
            top: calc(1600px - 10px - 20px);
            left: calc(112px - 25px);
        }

        &.step-7 {
            top: 1630px;
            left: 370px;
        }

        &.step-8 {
            top: calc(1850px + 100px);
            left: calc(174px - 10px);
        }

        &.unknown-icon {
            img {
                width: 80px;
            }
        }
    }
}

@media only screen and (max-width: 520px) {
    #map {
        width: 250px;
        height: 974px;
    }

    #canvas {
        width: 250px;
        height: 974px;
    }

    #map-img {
        width: 250px;
        height: 974px;
        background: url('/img/staking-map/biomes/desert/map-mobile-xs.png') 0 0 no-repeat;

        &.nextMapPreviewMap {
            background: url('/img/staking-map/biomes/shrooms/map-mobile.png') 0 0 no-repeat;
            background-size: 100% 100%;
        }
    }

    .milestone {
        font-size: 30px;
        display: none;
    }

    .step {
        &.step-1 {
            top: calc(130px);
            left: calc(130px);
        }

        &.step-2 {
            top: calc(250px);
            left: calc(60px);
        }

        &.step-3 {
            top: calc(390px);
            left: calc(30px);
        }

        &.step-4 {
            top: calc(405px);
            left: calc(140px);
        }

        &.step-5 {
            top: 545px;
            left: 60px;
        }

        &.step-6 {
            top: 675px;
            left: 20px;
        }

        &.step-7 {
            top: 700px;
            left: 160px;
        }

        &.step-8 {
            top: 830px;
            left: 60px;
        }

        &.unknown-icon {
            img {
                width: 50px;
            }
        }

        &.tornado-icon {
            img {
                width: 60px;
            }
        }
    }
}
</style>
