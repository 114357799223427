var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "home-container", attrs: { fluid: "" } },
    [
      _c(
        "page-filters-section",
        { attrs: { "top-img": "/img/staking-map/staking-map-header.png" } },
        [
          _c("h1", { staticClass: "main-title" }, [_vm._v("Staking Map")]),
          _c(
            "div",
            { staticClass: "header-text" },
            [
              _vm._v(
                ' All staked SCRL can be taken back anytime by clicking the "Reduce" button. Each ' +
                  _vm._s(_vm._f("localFormatNumber")(100000)) +
                  " gives one Work Point. Every Shrine has a separate Work Point's "
              ),
              _c("img", {
                staticClass: "work-icon",
                attrs: { src: "/img/staking-map/work.svg" },
              }),
              _vm._v(
                " collection. These points are used to get rewards. The stake amount always remains " +
                  _vm._s(_vm._f("localFormatNumber")(100000)) +
                  " SCRL, and the refundable amount of SCRL corresponds to the staked amount. "
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: {
                    "x-small": "",
                    target: "_blank",
                    href: "https://medium.com/@wizarre/a-complete-guide-to-wizarres-new-staking-feature-c1a84f0a1bc7",
                  },
                },
                [_vm._v(" Read more ")]
              ),
            ],
            1
          ),
          _vm.isWalletConnected
            ? _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-column flex-md-row justify-space-between pos-relative",
                  attrs: { id: "actions" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "input-approve-create-box d-flex flex-row pos-relative justify-center",
                    },
                    [
                      _c("input-amount", {
                        attrs: {
                          "init-value": _vm.stakeAmount,
                          "max-value": _vm.maxStakesAmountWithinBalance,
                          label: "Stakes amount",
                        },
                        on: { valueChanged: _vm.valueChanged },
                      }),
                      _c(
                        "v-btn",
                        {
                          staticClass: "input-approve-max-btn",
                          attrs: { "x-small": "" },
                          on: {
                            click: function ($event) {
                              _vm.stakeAmount = Number(
                                _vm.maxStakesAmountWithinBalance
                              )
                            },
                          },
                        },
                        [_vm._v(" Max ")]
                      ),
                      _c("price", {
                        staticClass: "ml-4",
                        staticStyle: { "margin-top": "-16px" },
                        attrs: { price: _vm.stakesPrice },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "btn-approve-create-box d-flex flex-row justify-center mt-4 mt-md-0",
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "m-0",
                          attrs: {
                            loading: _vm.loadingData,
                            disabled: !_vm.isPriceWithinAllowance,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.approveStake()
                            },
                          },
                        },
                        [_vm._v(" 1. Approve ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "m-0 ml-2",
                          attrs: {
                            loading: _vm.loadingData,
                            disabled:
                              _vm.isPriceWithinAllowance ||
                              _vm.stakeAmount <= 0 ||
                              _vm.isPriceWithinUserBalance,
                            color: "primary",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.createStake()
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "hidden-sm-and-down" }, [
                            _vm._v("2. Create stake"),
                          ]),
                          _c("span", { staticClass: "hidden-md-and-up" }, [
                            _vm._v("2. Create"),
                          ]),
                          _c("img", {
                            staticClass: "work-icon ml-2",
                            attrs: { src: "/img/staking-map/work.svg" },
                          }),
                          _vm._v(" +" + _vm._s(_vm.stakeAmount) + " "),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
      _c("div", { staticClass: "spacer" }),
      _c(
        "div",
        { staticClass: "page-content mx-auto" },
        [
          _vm.displayNewMapTimestamp
            ? _c(
                "v-chip",
                {
                  staticClass: "next-map-timer",
                  attrs: { color: "secondary" },
                },
                [
                  _vm._v(" Next map starts in "),
                  _c("timer", {
                    attrs: { "deadline-timestamp": _vm.nextMap.startTimestamp },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-column flex-sm-row align-center justify-center justify-md-end",
            },
            [
              _c(
                "v-menu",
                {
                  attrs: {
                    absolute: "",
                    bottom: "",
                    rounded: "",
                    transition: "fade-transition",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "map-selector-btn",
                                  class: {
                                    selected:
                                      _vm.displayedMapId <
                                      _vm.latestDisplayedMapId,
                                  },
                                  attrs: { color: "primary", dark: "" },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c("img", {
                                attrs: { src: "/img/staking-map/tornado.png" },
                              }),
                              _vm._v(" Archive "),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.archive,
                    callback: function ($$v) {
                      _vm.archive = $$v
                    },
                    expression: "archive",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-list",
                        { attrs: { rounded: "" } },
                        [
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mx-1 map-archive-btn",
                                  class: { selected: _vm.displayedMapId === 0 },
                                  attrs: { color: "brown darken-1" },
                                  on: {
                                    click: function ($event) {
                                      _vm.displayedMapId = 0
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "/img/staking-map/biomes/desert/icon.svg",
                                    },
                                  }),
                                  _c("span", [_vm._v("Desert")]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mx-1 map-archive-btn",
                                  class: { selected: _vm.displayedMapId === 1 },
                                  attrs: { color: "teal darken-3" },
                                  on: {
                                    click: function ($event) {
                                      _vm.displayedMapId = 1
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "/img/staking-map/biomes/shrooms/icon.svg",
                                    },
                                  }),
                                  _c("span", [_vm._v("Forest")]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mx-1 map-archive-btn",
                                  class: { selected: _vm.displayedMapId === 2 },
                                  attrs: { color: "#727272" },
                                  on: {
                                    click: function ($event) {
                                      _vm.displayedMapId = 2
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "/img/staking-map/biomes/spooky/icon.svg",
                                    },
                                  }),
                                  _c("span", [_vm._v("Spooky")]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mx-1 map-archive-btn",
                                  class: { selected: _vm.displayedMapId === 3 },
                                  attrs: { color: "#D6749BFF" },
                                  on: {
                                    click: function ($event) {
                                      _vm.displayedMapId = 3
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "/img/staking-map/biomes/clouds/icon.svg",
                                    },
                                  }),
                                  _c("span", [_vm._v("Clouds")]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mx-1 map-archive-btn",
                                  class: { selected: _vm.displayedMapId === 4 },
                                  attrs: { color: "#194189" },
                                  on: {
                                    click: function ($event) {
                                      _vm.displayedMapId = 4
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "/img/staking-map/biomes/shores/icon.svg",
                                    },
                                  }),
                                  _c("span", [_vm._v("Shores")]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mx-1 map-archive-btn",
                                  class: { selected: _vm.displayedMapId === 5 },
                                  attrs: { color: "#3E1907A0" },
                                  on: {
                                    click: function ($event) {
                                      _vm.displayedMapId = 5
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "/img/staking-map/biomes/volcano/icon.svg",
                                    },
                                  }),
                                  _c("span", [_vm._v("Volcano")]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mx-1 map-archive-btn",
                                  class: { selected: _vm.displayedMapId === 6 },
                                  attrs: { color: "#e4b986" },
                                  on: {
                                    click: function ($event) {
                                      _vm.displayedMapId = 6
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "/img/staking-map/biomes/sweeties/icon.svg",
                                    },
                                  }),
                                  _c("span", [_vm._v("Sweeties")]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mx-1 map-archive-btn",
                                  class: { selected: _vm.displayedMapId === 7 },
                                  attrs: { color: "brown darken-1" },
                                  on: {
                                    click: function ($event) {
                                      _vm.displayedMapId = 7
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "/img/staking-map/biomes/desert/icon.svg",
                                    },
                                  }),
                                  _c("span", [_vm._v("Desert II")]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm.isThisMapArchived(
                            _vm.mapTimestamps.find((map) => map.id === 9)
                              .startTimestamp
                          )
                            ? _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mx-1 map-archive-btn",
                                      class: {
                                        selected:
                                          _vm.displayedMapId ===
                                          _vm.mapTimestamps.find(
                                            (map) => map.id === 8
                                          ).id,
                                      },
                                      attrs: { color: "teal darken-3" },
                                      on: {
                                        click: function ($event) {
                                          _vm.displayedMapId =
                                            _vm.mapTimestamps.find(
                                              (map) => map.id === 8
                                            ).id
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: "/img/staking-map/biomes/shrooms/icon.svg",
                                        },
                                      }),
                                      _c("span", [_vm._v("Forest II")]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isThisMapArchived(
                            _vm.mapTimestamps.find((map) => map.id === 10)
                              .startTimestamp
                          )
                            ? _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mx-1 map-archive-btn",
                                      class: {
                                        selected:
                                          _vm.displayedMapId ===
                                          _vm.mapTimestamps.find(
                                            (map) => map.id === 9
                                          ).id,
                                      },
                                      attrs: { color: "#727272" },
                                      on: {
                                        click: function ($event) {
                                          _vm.displayedMapId =
                                            _vm.mapTimestamps.find(
                                              (map) => map.id === 9
                                            ).id
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: "/img/staking-map/biomes/spooky/icon.svg",
                                        },
                                      }),
                                      _c("span", [_vm._v("Spooky II")]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mx-4 map-selector-btn current-map",
                  class: { selected: _vm.displayedMapId === _vm.currentMap.id },
                  attrs: { color: "#727272" },
                  on: {
                    click: function ($event) {
                      _vm.displayedMapId = _vm.currentMap.id
                    },
                  },
                },
                [_c("span", [_vm._v("Current Map")])]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "stake-amount-total text-left mt-6" },
            [
              _c("h3", { staticClass: "mt-4 ml-2 mb-2" }, [_vm._v("Total")]),
              _c("price", {
                staticClass: "ml-4 mt-2",
                attrs: {
                  price: _vm.sumAllStakesAmount * _vm.singleStakeTokenValue,
                },
              }),
              _c("br"),
              _c("div", { staticClass: "work-points" }, [
                _c("img", {
                  staticClass: "ml-4",
                  staticStyle: { height: "30px" },
                  attrs: { src: "/img/staking-map/work.svg" },
                }),
                _c("span", { staticClass: "ml-2" }, [
                  _vm._v(
                    _vm._s(_vm._f("localFormatNumber")(_vm.sumAllStakesAmount))
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "v-fade-transition",
            { attrs: { "hide-on-leave": "" } },
            [
              (!_vm.displayedMapId && _vm.displayedMapId !== 0) || _vm.archive
                ? _c(
                    "v-card",
                    [
                      _c("v-skeleton-loader", {
                        staticClass: "mx-auto pt-16 pb-4",
                        attrs: { height: "600", type: "image" },
                      }),
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _vm.displayedMapId === 0
                        ? _c("biome1-desert", {
                            attrs: {
                              pawns: _vm.pawns,
                              shrineDetails:
                                _vm.getShrineDetailsByName("desert"),
                            },
                          })
                        : _vm._e(),
                      _vm.displayedMapId === 1
                        ? _c("biome2-shrooms", {
                            attrs: {
                              pawns: _vm.pawns,
                              shrineDetails:
                                _vm.getShrineDetailsByName("shrooms"),
                            },
                          })
                        : _vm._e(),
                      _vm.displayedMapId === 2
                        ? _c("biome3-spooky", {
                            attrs: {
                              pawns: _vm.pawns,
                              shrineDetails:
                                _vm.getShrineDetailsByName("spooky"),
                            },
                          })
                        : _vm._e(),
                      _vm.displayedMapId === 3
                        ? _c("biome4-clouds", {
                            attrs: {
                              pawns: _vm.pawns,
                              shrineDetails:
                                _vm.getShrineDetailsByName("clouds"),
                            },
                          })
                        : _vm._e(),
                      _vm.displayedMapId === 4
                        ? _c("biome5-shores", {
                            attrs: {
                              pawns: _vm.pawns,
                              shrineDetails:
                                _vm.getShrineDetailsByName("shores"),
                            },
                          })
                        : _vm._e(),
                      _vm.displayedMapId === 5
                        ? _c("biome6-volcano", {
                            attrs: {
                              pawns: _vm.pawns,
                              shrineDetails:
                                _vm.getShrineDetailsByName("volcano"),
                            },
                          })
                        : _vm._e(),
                      _vm.displayedMapId === 6
                        ? _c("biome7-sweeties", {
                            attrs: {
                              pawns: _vm.pawns,
                              shrineDetails:
                                _vm.getShrineDetailsByName("sweeties"),
                            },
                          })
                        : _vm._e(),
                      _vm.displayedMapId === 7
                        ? _c("biome1-cycle2-desert", {
                            attrs: {
                              pawns: _vm.pawns,
                              shrineDetails:
                                _vm.getShrineDetailsByName("desert_c2"),
                            },
                          })
                        : _vm._e(),
                      _vm.displayedMapId === 8
                        ? _c("biome2-cycle2-desert", {
                            attrs: {
                              pawns: _vm.pawns,
                              shrineDetails:
                                _vm.getShrineDetailsByName("shrooms_c2"),
                            },
                          })
                        : _vm._e(),
                      _vm.displayedMapId === 9
                        ? _c("biome3-cycle2-spooky", {
                            attrs: {
                              pawns: _vm.pawns,
                              shrineDetails:
                                _vm.getShrineDetailsByName("spooky_c2"),
                            },
                          })
                        : _vm._e(),
                      _vm.displayedMapId === 10
                        ? _c("biome4-cycle2-clouds", {
                            attrs: {
                              pawns: _vm.pawns,
                              shrineDetails:
                                _vm.getShrineDetailsByName("clouds_c2"),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _vm.isWalletConnected
                ? _c("staking-table", {
                    attrs: {
                      stakes: _vm.stakes,
                      shrinesDetails: _vm.getShrinesDetails,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.DEV_createdAt
        ? _c(
            "v-menu",
            {
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(
                          "v-text-field",
                          _vm._g(
                            _vm._b(
                              {
                                staticStyle: {
                                  position: "absolute",
                                  left: "0",
                                  top: "0px",
                                  background: "pink",
                                  color: "#000",
                                  overflow: "hidden",
                                },
                                attrs: {
                                  label: "DEV startAt",
                                  light: "",
                                  "prepend-icon": "mdi-calendar",
                                  readonly: "",
                                },
                                model: {
                                  value: _vm.DEV_createdAt,
                                  callback: function ($$v) {
                                    _vm.DEV_createdAt = $$v
                                  },
                                  expression: "DEV_createdAt",
                                },
                              },
                              "v-text-field",
                              attrs,
                              false
                            ),
                            on
                          )
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                3589193070
              ),
            },
            [
              _c(
                "v-date-picker",
                {
                  attrs: { "no-title": "", scrollable: "" },
                  model: {
                    value: _vm.DEV_createdAt,
                    callback: function ($$v) {
                      _vm.DEV_createdAt = $$v
                    },
                    expression: "DEV_createdAt",
                  },
                },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.menu = false
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$refs.menu.save(_vm.DEV_createdAt)
                        },
                      },
                    },
                    [_vm._v(" OK ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }