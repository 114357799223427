var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "table",
    { staticClass: "ma-auto my-6", attrs: { id: "pawn-table" } },
    [
      _vm._m(0),
      _c(
        "tbody",
        _vm._l(_vm.orderedStakes, function (stake) {
          return _c(
            "tr",
            { key: "st" + stake.id, attrs: { "data-id": stake.id } },
            [
              _c("td", { staticClass: "td-pawn-image" }, [
                _c("img", {
                  attrs: {
                    src: _vm.pawnImage(
                      stake.stakesAmount,
                      stake.bonusPercents,
                      true
                    ),
                  },
                }),
              ]),
              _c("td", { staticClass: "td-work-point" }, [
                _c("div", [
                  _c("img", {
                    staticClass: "work-icon",
                    attrs: { src: "/img/staking-map/work.svg" },
                  }),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm._f("localFormatNumber")(stake.stakesAmount))
                    ),
                  ]),
                  _vm.getBonusPoints(stake.stakesAmount, stake.bonusPercents) >
                  0
                    ? _c("span", { staticClass: "golden--text" }, [
                        _vm._v(
                          " +" +
                            _vm._s(
                              _vm._f("localFormatNumber")(
                                _vm.getBonusPoints(
                                  stake.stakesAmount,
                                  stake.bonusPercents
                                )
                              )
                            ) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _c("td", { staticClass: "td-bonus d-none d-sm-table-cell" }, [
                _c(
                  "div",
                  [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "div",
                                    _vm._g(_vm._b({}, "div", attrs, false), on),
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.sumBonuses(stake.bonusPercents)
                                          ) + "%"
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _vm.sumBonuses(stake.bonusPercents) > 0
                          ? _c("div", [
                              _vm._v(" Gathered bonuses: "),
                              stake.bonusPercents[0] &&
                              stake.bonusPercents[0] !== "0"
                                ? _c(
                                    "div",
                                    { staticClass: "d-flex align-center" },
                                    [
                                      _c("img", {
                                        staticClass: "minion-icon mr-2",
                                        attrs: {
                                          src: "/img/staking-map/pawns/mini/minion-desert.png",
                                        },
                                      }),
                                      _vm._v(
                                        " Desert veteran: " +
                                          _vm._s(stake.bonusPercents[0]) +
                                          "% "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              stake.bonusPercents[1] &&
                              stake.bonusPercents[1] !== "0"
                                ? _c(
                                    "div",
                                    { staticClass: "d-flex align-center" },
                                    [
                                      _c("img", {
                                        staticClass: "minion-icon mr-2",
                                        attrs: {
                                          src: "/img/staking-map/pawns/mini/minion-shrooms.png",
                                        },
                                      }),
                                      _vm._v(
                                        " Forest veteran: " +
                                          _vm._s(stake.bonusPercents[1]) +
                                          "% "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              stake.bonusPercents[2] &&
                              stake.bonusPercents[2] !== "0"
                                ? _c(
                                    "div",
                                    { staticClass: "d-flex align-center" },
                                    [
                                      _c("img", {
                                        staticClass: "minion-icon mr-2",
                                        attrs: {
                                          src: "/img/staking-map/pawns/mini/minion-spooky-30.png",
                                        },
                                      }),
                                      _vm._v(
                                        " Spooky veteran: " +
                                          _vm._s(stake.bonusPercents[2]) +
                                          "% "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              stake.bonusPercents[3] &&
                              stake.bonusPercents[3] !== "0"
                                ? _c(
                                    "div",
                                    { staticClass: "d-flex align-center" },
                                    [
                                      _c("img", {
                                        staticClass: "minion-icon mr-2",
                                        attrs: {
                                          src: "/img/staking-map/pawns/mini/minion-clouds-30.png",
                                        },
                                      }),
                                      _vm._v(
                                        " Clouds veteran: " +
                                          _vm._s(stake.bonusPercents[3]) +
                                          "% "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              stake.bonusPercents[4] &&
                              stake.bonusPercents[4] !== "0"
                                ? _c(
                                    "div",
                                    { staticClass: "d-flex align-center" },
                                    [
                                      _c("img", {
                                        staticClass: "minion-icon mr-2",
                                        attrs: {
                                          src: "/img/staking-map/pawns/mini/minion-shores-30.png",
                                        },
                                      }),
                                      _vm._v(
                                        " Shores veteran: " +
                                          _vm._s(stake.bonusPercents[4]) +
                                          "% "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              stake.bonusPercents[5] &&
                              stake.bonusPercents[5] !== "0"
                                ? _c(
                                    "div",
                                    { staticClass: "d-flex align-center" },
                                    [
                                      _c("img", {
                                        staticClass: "minion-icon mr-2",
                                        attrs: {
                                          src: "/img/staking-map/pawns/mini/minion-volcano-30.png",
                                        },
                                      }),
                                      _vm._v(
                                        " Volcano veteran: " +
                                          _vm._s(stake.bonusPercents[5]) +
                                          "% "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              stake.bonusPercents[6] &&
                              stake.bonusPercents[6] !== "0"
                                ? _c(
                                    "div",
                                    { staticClass: "d-flex align-center" },
                                    [
                                      _c("img", {
                                        staticClass: "minion-icon mr-2",
                                        attrs: {
                                          src: "/img/staking-map/pawns/mini/minion-sweeties-30.png",
                                        },
                                      }),
                                      _vm._v(
                                        " Sweeties veteran: " +
                                          _vm._s(stake.bonusPercents[6]) +
                                          "% "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              stake.bonusPercents[7] &&
                              stake.bonusPercents[7] !== "0"
                                ? _c(
                                    "div",
                                    { staticClass: "d-flex align-center" },
                                    [
                                      _c("img", {
                                        staticClass: "minion-icon mr-2",
                                        attrs: {
                                          src: "/img/staking-map/pawns/mini/minion-desert.png",
                                        },
                                      }),
                                      _vm._v(
                                        " Desert II veteran: " +
                                          _vm._s(stake.bonusPercents[7]) +
                                          "% "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ])
                          : _c("div", [
                              _c("span", [
                                _vm._v(
                                  "This is rookie caravan. It needs to explore more maps to gain bonuses."
                                ),
                              ]),
                            ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "td",
                {
                  staticClass: "d-none d-md-table-cell",
                  attrs: {
                    set: (_vm.rowBiome = _vm.calculateMapBiomeStakeIsOn(stake)),
                  },
                },
                [
                  _c(
                    "v-chip",
                    {
                      staticClass: "at-day-chip",
                      class: {
                        "brown darken-1": "desert" === _vm.rowBiome.biomeName,
                        "teal darken-3": "shrooms" === _vm.rowBiome.biomeName,
                        spookyBg: "spooky" === _vm.rowBiome.biomeName,
                      },
                      attrs: { label: "", small: "" },
                    },
                    [
                      _c("img", {
                        staticClass: "at-day-icon",
                        attrs: {
                          src: `/img/staking-map/biomes/${_vm.rowBiome.biomeName}/icon.svg`,
                        },
                      }),
                    ]
                  ),
                  _vm._v(" " + _vm._s(_vm.rowBiome.dayOnBiome) + " "),
                ],
                1
              ),
              _c("td", [
                _c(
                  "b",
                  [
                    _c("timer", {
                      attrs: {
                        "deadline-timestamp":
                          _vm.calculateTimeToNextStop(stake),
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("td", { staticClass: "d-none d-md-table-cell" }, [
                _vm._v(
                  _vm._s(
                    _vm._f("moment")(parseInt(stake.createdAt), "D MMMM YYYY")
                  )
                ),
              ]),
              _c("td", [
                1 !== stake.mapVersion
                  ? _c(
                      "div",
                      [
                        _c("popup-reduce-stake", {
                          attrs: {
                            id: parseInt(stake.id),
                            "stake-amount": Number(stake.stakesAmount),
                            image: _vm.pawnImage(
                              stake.stakesAmount,
                              stake.bonusPercents,
                              true
                            ),
                            model: _vm.reducePopupRead(stake.id),
                          },
                          on: {
                            dialogClosed: function ($event) {
                              return _vm.setPopup(stake.id, false)
                            },
                          },
                        }),
                        _c(
                          "v-btn",
                          {
                            attrs: { loading: _vm.loading },
                            on: {
                              click: function ($event) {
                                return _vm.setPopup(stake.id, true)
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "hidden-sm-and-down" }, [
                              _vm._v("Reduce stake"),
                            ]),
                            _c("span", { staticClass: "hidden-md-and-up" }, [
                              _vm._v("Reduce"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                1 === stake.mapVersion
                  ? _c(
                      "div",
                      [
                        _c("popup-remove-stake", {
                          attrs: {
                            id: parseInt(stake.id),
                            "stake-amount": stake.stakesAmount,
                            image: _vm.pawnImage(
                              stake.stakesAmount,
                              stake.bonusPercents,
                              true
                            ),
                            model: _vm.reducePopupRead(stake.id),
                          },
                          on: {
                            dialogClosed: function ($event) {
                              return _vm.setPopup(stake.id, false)
                            },
                          },
                        }),
                        _c(
                          "v-btn",
                          {
                            attrs: { loading: _vm.loading },
                            on: {
                              click: function ($event) {
                                return _vm.setPopup(stake.id, true)
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "hidden-sm-and-down" }, [
                              _vm._v("Remove stake"),
                            ]),
                            _c("span", { staticClass: "hidden-md-and-up" }, [
                              _vm._v("Remove"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Caravan")]),
        _c("th", [_vm._v("Work points")]),
        _c("th", { staticClass: "d-none d-sm-table-cell" }, [_vm._v("Bonus")]),
        _c("th", { staticClass: "d-none d-md-table-cell" }, [_vm._v("At day")]),
        _c("th", [_vm._v("To the next stop")]),
        _c("th", { staticClass: "d-none d-md-table-cell" }, [
          _vm._v("Started on"),
        ]),
        _c("th", [_vm._v("Actions")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }