var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-row", [
    _c(
      "div",
      { staticClass: "biome-4-cycle-2", attrs: { id: "map" } },
      [
        _c("canvas", { attrs: { id: "canvas", "data-paper-hidpi": "off" } }),
        _vm._l(_vm.pawnDetails, function (pawn) {
          return _c(
            "v-tooltip",
            {
              key: pawn.id,
              attrs: { top: "", "z-index": "1000" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(
                          "div",
                          _vm._g(
                            _vm._b(
                              {
                                staticClass: "pawn-details-container",
                                style: _vm.getPawnDetailsFrameStyle(
                                  pawn.position,
                                  pawn.height,
                                  pawn.width
                                ),
                              },
                              "div",
                              attrs,
                              false
                            ),
                            on
                          )
                        ),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            },
            [
              _c("div", { staticClass: "pawn-details-tooltip" }, [
                _vm._v(" Caravan Details: "),
                _c("ul", [
                  _c("li", [
                    _vm._v(
                      "On map: " + _vm._s(pawn.offsetDayOnActiveMap) + " days"
                    ),
                  ]),
                  _c("li", [
                    _vm._v(" Work points: " + _vm._s(pawn.size) + " "),
                    _c("img", {
                      staticClass: "work-icon",
                      attrs: { src: "/img/staking-map/work_16.png" },
                    }),
                  ]),
                  pawn.activeBonuses[0]
                    ? _c("li", [
                        _vm._v("Veteran: Desert "),
                        _c("img", {
                          staticClass: "work-icon",
                          attrs: {
                            src: "/img/staking-map/pawns/mini/minion-desert.png",
                          },
                        }),
                      ])
                    : _vm._e(),
                  pawn.activeBonuses[1]
                    ? _c("li", [
                        _vm._v("Veteran: Forest "),
                        _c("img", {
                          staticClass: "work-icon",
                          attrs: {
                            src: "/img/staking-map/pawns/mini/minion-shrooms.png",
                          },
                        }),
                      ])
                    : _vm._e(),
                  pawn.activeBonuses[2]
                    ? _c("li", [
                        _vm._v("Veteran: Spooky "),
                        _c("img", {
                          staticClass: "work-icon",
                          attrs: {
                            src: "/img/staking-map/pawns/mini/minion-spooky-30.png",
                          },
                        }),
                      ])
                    : _vm._e(),
                  pawn.activeBonuses[3]
                    ? _c("li", [
                        _vm._v("Veteran: Clouds "),
                        _c("img", {
                          staticClass: "work-icon",
                          attrs: {
                            src: "/img/staking-map/pawns/mini/minion-clouds-30.png",
                          },
                        }),
                      ])
                    : _vm._e(),
                  pawn.activeBonuses[4]
                    ? _c("li", [
                        _vm._v("Veteran: Shores "),
                        _c("img", {
                          staticClass: "work-icon",
                          attrs: {
                            src: "/img/staking-map/pawns/mini/minion-shores-30.png",
                          },
                        }),
                      ])
                    : _vm._e(),
                  pawn.activeBonuses[5]
                    ? _c("li", [
                        _vm._v("Veteran: Volcano "),
                        _c("img", {
                          staticClass: "work-icon",
                          attrs: {
                            src: "/img/staking-map/pawns/mini/minion-volcano-30.png",
                          },
                        }),
                      ])
                    : _vm._e(),
                  pawn.activeBonuses[6]
                    ? _c("li", [
                        _vm._v("Veteran: Sweeties "),
                        _c("img", {
                          staticClass: "work-icon",
                          attrs: {
                            src: "/img/staking-map/pawns/mini/minion-sweeties-30.png",
                          },
                        }),
                      ])
                    : _vm._e(),
                  pawn.activeBonuses[7]
                    ? _c("li", [
                        _vm._v("Veteran: Desert II "),
                        _c("img", {
                          staticClass: "work-icon",
                          attrs: {
                            src: "/img/staking-map/pawns/mini/minion-desert.png",
                          },
                        }),
                      ])
                    : _vm._e(),
                  pawn.activeBonuses[8]
                    ? _c("li", [
                        _vm._v("Veteran: Forest II "),
                        _c("img", {
                          staticClass: "work-icon",
                          attrs: {
                            src: "/img/staking-map/pawns/mini/minion-shrooms.png",
                          },
                        }),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]
          )
        }),
        _c("div", { attrs: { id: "map-img" } }),
        _vm._l(_vm.pawns, function (pawn) {
          return _c("img", {
            staticClass: "d-none",
            attrs: { id: "pawn-" + pawn.id, src: pawn.img },
          })
        }),
        _vm._l(_vm.pawns, function (pawn) {
          return _c("img", {
            staticClass: "d-none",
            attrs: { id: "mini-pawn-" + pawn.id, src: pawn.imgMini },
          })
        }),
        _vm._l(_vm.pawns, function (pawn) {
          return _c("img", {
            staticClass: "d-none",
            attrs: {
              id: "mini-pawn-" + pawn.id + "-minion-desert",
              src: "/img/staking-map/pawns/mini/minion-desert.png",
            },
          })
        }),
        _vm._l(_vm.pawns, function (pawn) {
          return _c("img", {
            staticClass: "d-none",
            attrs: {
              id: "mini-pawn-" + pawn.id + "-minion-shrooms",
              src: "/img/staking-map/pawns/mini/minion-shrooms.png",
            },
          })
        }),
        _vm._l(_vm.pawns, function (pawn) {
          return _c("img", {
            staticClass: "d-none",
            attrs: {
              id: "mini-pawn-" + pawn.id + "-minion-spooky",
              src: "/img/staking-map/pawns/mini/minion-spooky-30.png",
            },
          })
        }),
        _vm._l(_vm.pawns, function (pawn) {
          return _c("img", {
            staticClass: "d-none",
            attrs: {
              id: "mini-pawn-" + pawn.id + "-minion-clouds",
              src: "/img/staking-map/pawns/mini/minion-clouds-30.png",
            },
          })
        }),
        _vm._l(_vm.pawns, function (pawn) {
          return _c("img", {
            staticClass: "d-none",
            attrs: {
              id: "mini-pawn-" + pawn.id + "-minion-shores",
              src: "/img/staking-map/pawns/mini/minion-shores-30.png",
            },
          })
        }),
        _vm._l(_vm.pawns, function (pawn) {
          return _c("img", {
            staticClass: "d-none",
            attrs: {
              id: "mini-pawn-" + pawn.id + "-minion-volcano",
              src: "/img/staking-map/pawns/mini/minion-volcano-30.png",
            },
          })
        }),
        _vm._l(_vm.pawns, function (pawn) {
          return _c("img", {
            staticClass: "d-none",
            attrs: {
              id: "mini-pawn-" + pawn.id + "-minion-sweeties",
              src: "/img/staking-map/pawns/mini/minion-sweeties-30.png",
            },
          })
        }),
        _c("div", { staticClass: "bg-end-signpost" }),
        _c("img", {
          staticClass: "d-none",
          attrs: { id: "work-icon", src: "/img/staking-map/work_16.png" },
        }),
        _vm._l(_vm.milestoneDays, function (m, id) {
          return _c(
            "div",
            { key: "m" + id, class: "milestone mile-" + (id + 1) },
            [_vm._v(_vm._s(m))]
          )
        }),
        _vm._l(_vm.milestones, function (shrine) {
          return _c(
            "div",
            {
              key: "sh" + shrine.id,
              staticClass: "step d-flex flex-column",
              class: "step-" + shrine.id,
            },
            [
              _c(
                "div",
                [
                  _c("reward-shrine-v2", {
                    attrs: {
                      decimals: 2,
                      milestoneId: parseInt(shrine.id),
                      open: _vm.isShrineOpen(shrine),
                      shrine: shrine,
                      "content-class": "biome-4-cycle-2",
                    },
                  }),
                ],
                1
              ),
            ]
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }