var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "select-dialog" },
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "600" },
          model: {
            value: _vm.isOpen,
            callback: function ($$v) {
              _vm.isOpen = $$v
            },
            expression: "isOpen",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "box-decorated-edges-mini" },
            [
              _c(
                "div",
                {
                  staticClass: "close-btn pull-right",
                  on: {
                    click: function ($event) {
                      _vm.isOpen = false
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close-box")])],
                1
              ),
              _c(
                "v-card-title",
                { staticClass: "title justify-space-between" },
                [_c("span", [_vm._v("Reduce stake #" + _vm._s(_vm.id))])]
              ),
              _c(
                "v-card-text",
                { staticClass: "rewards mt-2 pt-6" },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "4", md: "2" } }, [
                        _c("img", {
                          staticStyle: { height: "105px" },
                          attrs: { src: _vm.image },
                        }),
                      ]),
                      _c("v-col", { attrs: { cols: "8", md: "6" } }, [
                        _c("p", { staticClass: "ml-2 mt-5" }, [
                          _vm._v(
                            " Return all staked SCRL and remove Caravan from the map. "
                          ),
                        ]),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("price", {
                            staticClass: "pull-right ml-5 mt-md-5",
                            attrs: { price: _vm.stakesPrice },
                          }),
                          _c(
                            "v-btn",
                            {
                              staticClass: "pull-right",
                              attrs: { loading: _vm.loadingData },
                              on: {
                                click: function ($event) {
                                  return _vm.requestRemove()
                                },
                              },
                            },
                            [_vm._v(" Remove ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-card-actions"),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }