<template>
    <table id="pawn-table" class="ma-auto my-6">
        <thead>
        <tr>
            <th>Caravan</th>
            <th>Work points</th>
            <th class="d-none d-sm-table-cell">Bonus</th>
            <th class="d-none d-md-table-cell">At day</th>
            <th>To the next stop</th>
            <th class="d-none d-md-table-cell">Started on</th>
            <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="stake in orderedStakes" :key="'st'+stake.id" :data-id="stake.id">
            <td class="td-pawn-image">
                <img :src="pawnImage(stake.stakesAmount, stake.bonusPercents,true)"/>
            </td>
            <td class="td-work-point">
                <div>
                    <img class="work-icon" src="/img/staking-map/work.svg"/>
                    <span>{{ stake.stakesAmount | localFormatNumber }}</span>
                    <span class="golden--text" v-if="getBonusPoints(stake.stakesAmount, stake.bonusPercents)>0">
                        +{{ getBonusPoints(stake.stakesAmount, stake.bonusPercents) | localFormatNumber }}
                    </span>
                </div>
            </td>
            <td class="td-bonus d-none d-sm-table-cell">
                <!-- Bonus -->
                <div>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                                <span>{{ sumBonuses(stake.bonusPercents) }}%</span>
                            </div>
                        </template>
                        <div v-if="sumBonuses(stake.bonusPercents) > 0">
                            Gathered bonuses:
                            <div v-if="stake.bonusPercents[0] && stake.bonusPercents[0] !== '0'"
                                 class="d-flex align-center">
                                <img class="minion-icon mr-2" src="/img/staking-map/pawns/mini/minion-desert.png"/>
                                Desert veteran: {{ stake.bonusPercents[0] }}%
                            </div>
                            <div v-if="stake.bonusPercents[1] && stake.bonusPercents[1] !== '0'"
                                 class="d-flex align-center">
                                <img class="minion-icon mr-2" src="/img/staking-map/pawns/mini/minion-shrooms.png"/>
                                Forest veteran: {{ stake.bonusPercents[1] }}%
                            </div>
                            <div v-if="stake.bonusPercents[2] && stake.bonusPercents[2] !== '0'"
                                 class="d-flex align-center">
                                <img class="minion-icon mr-2" src="/img/staking-map/pawns/mini/minion-spooky-30.png"/>
                                Spooky veteran: {{ stake.bonusPercents[2] }}%
                            </div>
                            <div v-if="stake.bonusPercents[3] && stake.bonusPercents[3] !== '0'"
                                 class="d-flex align-center">
                                <img class="minion-icon mr-2" src="/img/staking-map/pawns/mini/minion-clouds-30.png"/>
                                Clouds veteran: {{ stake.bonusPercents[3] }}%
                            </div>
                            <div v-if="stake.bonusPercents[4] && stake.bonusPercents[4] !== '0'"
                                 class="d-flex align-center">
                                <img class="minion-icon mr-2" src="/img/staking-map/pawns/mini/minion-shores-30.png"/>
                                Shores veteran: {{ stake.bonusPercents[4] }}%
                            </div>
                            <div v-if="stake.bonusPercents[5] && stake.bonusPercents[5] !== '0'"
                                 class="d-flex align-center">
                                <img class="minion-icon mr-2" src="/img/staking-map/pawns/mini/minion-volcano-30.png"/>
                                Volcano veteran: {{ stake.bonusPercents[5] }}%
                            </div>
                            <div v-if="stake.bonusPercents[6] && stake.bonusPercents[6] !== '0'"
                                 class="d-flex align-center">
                                <img class="minion-icon mr-2"
                                     src="/img/staking-map/pawns/mini/minion-sweeties-30.png"/>
                                Sweeties veteran: {{ stake.bonusPercents[6] }}%
                            </div>
                            <div v-if="stake.bonusPercents[7] && stake.bonusPercents[7] !== '0'"
                                 class="d-flex align-center">
                                <img class="minion-icon mr-2" src="/img/staking-map/pawns/mini/minion-desert.png"/>
                                Desert II veteran: {{ stake.bonusPercents[7] }}%
                            </div>
                        </div>
                        <div v-else>
                            <span>This is rookie caravan. It needs to explore more maps to gain bonuses.</span>
                        </div>
                    </v-tooltip>
                </div>
            </td>
            <td class="d-none d-md-table-cell" :set="rowBiome = calculateMapBiomeStakeIsOn(stake)">
                <!-- At day-->
                <v-chip
                    :class="{
                        'brown darken-1': 'desert' === rowBiome.biomeName,
                        'teal darken-3': 'shrooms' === rowBiome.biomeName,
                        'spookyBg': 'spooky' === rowBiome.biomeName,
                    }"
                    class="at-day-chip"
                    label
                    small
                >
                    <img
                        :src="`/img/staking-map/biomes/${rowBiome.biomeName}/icon.svg`"
                        class="at-day-icon"
                    />
                </v-chip>
                {{ rowBiome.dayOnBiome }}
            </td>
            <td>
                <!-- To the next stop -->
                <b>
                    <timer :deadline-timestamp="calculateTimeToNextStop(stake)"></timer>
                </b>
            </td>
            <td class="d-none d-md-table-cell">{{ parseInt(stake.createdAt) | moment("D MMMM YYYY") }}</td>
            <td>
                <div v-if="1 !== stake.mapVersion">
                    <popup-reduce-stake
                        :id="parseInt(stake.id)"
                        :stake-amount="Number(stake.stakesAmount)"
                        :image="pawnImage(stake.stakesAmount,stake.bonusPercents,true)"
                        v-bind:model="reducePopupRead(stake.id)"
                        v-on:dialogClosed="setPopup(stake.id,false)"
                    ></popup-reduce-stake>

                    <v-btn
                        :loading="loading"
                        @click="setPopup(stake.id,true)"
                    >
                        <span class="hidden-sm-and-down">Reduce stake</span>
                        <span class="hidden-md-and-up">Reduce</span>
                    </v-btn>
                </div>

                <div v-if="1 === stake.mapVersion">
                    <popup-remove-stake
                        :id="parseInt(stake.id)"
                        :stake-amount="stake.stakesAmount"
                        :image="pawnImage(stake.stakesAmount,stake.bonusPercents,true)"
                        v-bind:model="reducePopupRead(stake.id)"
                        v-on:dialogClosed="setPopup(stake.id,false)"
                    >
                    </popup-remove-stake>

                    <v-btn
                        :loading="loading"
                        @click="setPopup(stake.id,true)"
                    >
                        <span class="hidden-sm-and-down">Remove stake</span>
                        <span class="hidden-md-and-up">Remove</span>
                    </v-btn>
                </div>
            </td>
        </tr>
        </tbody>
    </table>
</template>


<script>
import Timer from "../timer";
import PopupReduceStake from "./popup-reduce-stake";
import Vue from "vue";
import {SECONDS_IN_DAY, stakingMapServiceVue} from "../../services/stakingMapService.vue";
import PopupRemoveStake from "./popup-remove-stake";
import * as timeHelper from "../../helpers/timeHelper";

export default {
    components: {PopupRemoveStake, Timer, PopupReduceStake},
    props: {
        stakes: Array,
        shrinesDetails: Object,
    },
    data() {
        return {
            loading: false,
            reducePopup: {},
        }
    },
    mounted() {
    },
    computed: {
        orderedStakes() {
            return this.stakes.sort((a, b) => a.createdAt - b.createdAt);
        },
        milestones() {
            let milestones = [];
            let lastMilestone = 0;
            for (const mapBiome of Object.keys(this.shrinesDetails)) {
                lastMilestone = milestones.at(-1) || 0;
                milestones = milestones.concat(
                    this.shrinesDetails[mapBiome].milestones.map(v => v.day + lastMilestone)
                );
            }

            return milestones;
        },
    },
    methods: {
        getShrineDetailsByName(name) {
            return this.shrinesDetails[name] || [];
        },
        setPopup(index, value) {
            Vue.set(this.reducePopup, index, value);
        },
        reducePopupRead(id) {
            return this.reducePopup[id] || false;
        },
        calculateTimeToNextStop(stake) {
            const currentDay = this.calculateAbsoluteDays(stake)
            let nextDayNumber = this.milestones[0];
            for (let i = 0; i < this.milestones.length; i++) {
                if (currentDay >= this.milestones[i]) {
                    nextDayNumber = this.milestones[i + 1];
                }
            }
            let timeLeft = timeHelper.nowInSeconds() - stake.currentPos + nextDayNumber * SECONDS_IN_DAY;
            if (timeLeft <= 0) {
                timeLeft = 0;
            }

            return timeLeft;
        },
        /**
         * @return {{dayOnBiome: number, biomeName: string}}
         */
        calculateMapBiomeStakeIsOn(stake) {
            const stakeDayOn = this.calculateAbsoluteDays(stake);
            let dayOnBiome = 0;
            let accumulatedDays = 0;
            let lastBiomeName
            for (const biomeName of Object.keys(this.shrinesDetails)) {
                if (stakeDayOn < (accumulatedDays + this.shrinesDetails[biomeName].days)) { //Find first biome stake is on
                    dayOnBiome = stakeDayOn - accumulatedDays;
                    return {
                        dayOnBiome,
                        biomeName: this.shrinesDetails[biomeName].biomeName ?? biomeName
                    };
                }
                accumulatedDays += this.shrinesDetails[biomeName].days;
                lastBiomeName = this.shrinesDetails[biomeName].biomeName ?? biomeName;
            }

            // Fallback if stake finished all available maps
            return {
                dayOnBiome: stakeDayOn - accumulatedDays,
                biomeName: lastBiomeName
            };
        },
        calculateAbsoluteDays(stake) {
            return stakingMapServiceVue.calculateDays(stake);
        },
        pawnImage(stakeSize, bonusPercents, mini = false) {
            const size = Number(stakeSize) + this.getBonusPoints(stakeSize, bonusPercents);
            return stakingMapServiceVue.pawnImage(size, mini);
        },
        sumBonuses(bonusPercents) {
            return bonusPercents.map(v => Number(v)).reduce((a, b) => a + b) || 0;
        },
        getBonusPoints(basePoints, bonusPercents) {
            return Math.floor((Number(basePoints) * this.sumBonuses(bonusPercents)) / 100);
        }
    },
};
</script>

<style lang="scss" scoped>
.spookyBg {
    background: #727272;
}

#pawn-table {
    width: 100%;
    max-width: 1000px;
    background: var(--v-background-base);
}

.at-day-chip {
    height: 20px !important;
    margin-top: -4px;
    margin-right: 3px;
    padding: 0 4px;
}

.at-day-icon {
    width: 16px;
    height: 16px;
}

.td-work-point div {
    display: flex;
    justify-content: center;
    align-content: center;
    line-height: 32px;

    span {
        margin-left: 5px;
    }
}

.td-pawn-image img {
    height: 65px;
}

.work-icon {
    width: auto;
    height: 25px;
}

.minion-icon {
    width: auto;
    height: 15px;
}

table {
    border-collapse: collapse;

    thead {
        tr {
            background: #3C0851;

            th {
                padding: 2px 0;
            }
        }
    }

    tbody {
        td {
            padding: 4px 0;
        }

        tr:nth-child(even) {
            background: var(--v-navbarBg-base);
        }
    }
}


</style>
