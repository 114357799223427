export const SECONDS_IN_DAY = process.env.VUE_APP_CONTRACT_STAKING_MAP_SECONDS_IN_DAY; //24h

export const stakingMapServiceVue = {
    calculateDays(stake) {
        return this.calculatePeriodToDays(stake.currentPos);
    },
    calculatePeriodToDays(period) {
        return Math.floor((period)/SECONDS_IN_DAY);
    },
    pawnImage(stakeSize, mini = false) {
        stakeSize = parseInt(stakeSize);
        let img = null;
        switch (true) {
            case 1 === stakeSize:
                img = '1';
                break
            case 2 === stakeSize:
                img = '2';
                break
            case 3 === stakeSize:
                img = '3';
                break
            case stakeSize >= 4 && stakeSize <= 5:
                img = '4-5';
                break
            case stakeSize >= 6 && stakeSize <= 10:
                img = '6-10';
                break
            case stakeSize >= 11:
                img = '11-15';
                break
        }
        let size = '';
        if (mini) {
            size = 'mini/'
        }

        return '/img/staking-map/pawns/' + size + 'pawn-' + img + '-stakes.png';
    },
};
