<template>
    <div :class="'shrine-'+shrine.id+ ' shrine-type-'+shrine.type" @click="rewardPopup=true">
        <div :class="{
                'opened': isShrineOpen,
                'closed': !isShrineOpen,
                'points-positive': pointsAmountPositive,
                'points-zero': !pointsAmountPositive
             }"
             class="shrine"
        >
            <div :class="'bg-'+shrine.imgName+'-glow-gold'" class="pos-absolute shrine-glow"></div>
            <div :class="'bg-'+shrine.imgName+'-glow-purple'" class="pos-absolute shrine-hover d-none"></div>
            <div class="pos-absolute shrine-img" :class="'bg-'+shrine.imgName"></div>
            <div v-if="shrine.rewards.length" :class="'stock-'+shrine.type"
                 class="pos-absolute shrine-stock d-flex flex-row justify-center"
            >
                <div v-for="reward in rewardsToShow" :class="'mini-'+reward.imgClass"/>
            </div>
            <span v-if="isShrineOpen && !loadingPoints" class="points h5">
            <img class="work-icon" src="/img/staking-map/work.svg"/>{{ points }}
        </span>
            <v-progress-circular
                v-if="isShrineOpen && loadingPoints"
                class="points-spinner"
                indeterminate
                size="30"
            ></v-progress-circular>
        </div>
        <popup-shrine
            v-if="shrine.rewards.length>0"
            :limitedRewards="limitedRewards"
            :milestoneId="this.milestoneId"
            :model="rewardPopup"
            :pointsAmount="points"
            :pointsDecimals="pointsDecimals"
            :rewardType="shrine.rewardType"
            :shrine="shrine"
            v-on:dialogClosed="rewardPopup=false"
            :content-class="contentClass"
        >
        </popup-shrine>
    </div>
</template>
<script>
import PopupShrine from "./popup-shrine";
import {StakingMapMilestoneFactory} from "../../services/contracts/stakingMapV2Contract";

export default {
    components: {PopupShrine},
    props: {
        milestoneId: Number,
        shrine: Object,
        open: Boolean,
        contentClass: String,
        decimals: {
            type: Number,
            default: 0
        },
        limitedRewardId: Number
    },
    data() {
        return {
            loadingPoints: true,
            points: 0,
            pointsDecimals: 0,
            rewardPopup: false,
            limitedRewards: {},
        }
    },
    mounted() {
        if (this.isWalletConnected) {
            this.fetchPoints(this.web3);
        } else {
            window.addEventListener('user-wallet-loaded', () => {
                this.fetchPoints(this.web3);
            });
        }
    },
    methods: {
        shrineImgByStatus(shrine, open = false) {
            const status = open ? 'open' : 'close';
            return '/img/staking-map/' + shrine.type + '/' + shrine.imgName + '-' + status + '.png';
        },
        fetchPoints(web3) {
            this.loadingPoints = true;
            const rewardType = this.shrine.rewardType;
            const stakingMapMilestone = new StakingMapMilestoneFactory(rewardType);
            const stakingMapMilestoneContract = stakingMapMilestone.getContractClass(web3)
            stakingMapMilestoneContract.getRewardPointsAmountForMilestone(this.milestoneId)
                .then((res) => {
                    this.points = Math.floor(parseInt(res) / this.decimalDivider);
                    this.pointsDecimals = parseInt(res) % this.decimalDivider;
                    this.loadingPoints = false;
                })
                .catch((error) => {
                    console.error('Shrine-' + this.shrine.id + ': ' + error);
                })
                .finally(() => {
                });
            // Only for limited rewards
            if ('undefined' !== typeof this.limitedRewardId) {
                const rewardId = this.limitedRewardId;
                stakingMapMilestoneContract.getRewardLimitForId(this.milestoneId, rewardId)
                    .then((res) => {
                        this.limitedRewards[rewardId] = {
                            remaining: Number(res._remaining),
                            max: Number(res._max),
                        };
                    })
                    .catch((error) => {
                        console.error('Shrine-' + this.shrine.id + ': ' + error);
                    })
                    .finally(() => {
                    });
            }
        },
    },
    computed: {
        web3() {
            return this.$store.state.web3;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        },
        isShrineOpen() {
            return this.open || this.pointsAmountPositive;
        },
        pointsAmountPositive() {
            return this.points > 0;
        },
        decimalDivider() {
            return Math.pow(10, this.decimals)
        },
        rewardsToShow() {
            return this.shrine.rewards.length > 1
                ? this.shrine.rewards.map(rewardsTab => rewardsTab.items.at(-1))
                : this.shrine.rewards[0].items
        },
    }
};
</script>
<style lang="scss" scoped>
.biome-2 {
    .shrine-stock {
        width: 110px;
        top: 40px;
        left: 10px;
    }
}

.biome-3 {
    .shrine-stock {
        width: 110px;
        top: 45px;
        left: 7px;
    }
}

.biome-4 {
    .shrine-stock {
        width: 110px;
        top: 45px;
        left: 10px;
    }
}

.biome-5 {
    .shrine-stock {
        width: 110px;
        top: 50px;
        left: 11px;
    }

    .shrine-img {
        &.bg-shrine-spyglass {
            top: 20px;
            left: 20px
        }
    }
}

.biome-6 {
    .shrine-stock {
        width: 110px;
        top: 45px;
        left: 10px;
    }
}

.biome-7 {
    .shrine-stock {
        width: 110px;
        top: 48px;
        left: 2px;
    }
}

.shrine {
    position: relative;
    z-index: 20;

    &:hover {
        cursor: pointer;

        .shrine-hover {
            display: block !important;
        }
    }

    .shrine-glow {
        display: none;
    }

    &.opened {
        &.points-positive {
            .shrine-glow {
                display: block;
            }
        }
    }

    &.closed {
        -webkit-filter: grayscale(1);
        filter: grayscale(1);
    }

    .shrine-img {
        top: 10px;
        left: 10px
    }

    .points {
        width: 70px;
        text-align: center;
        font-size: 22px;
        position: absolute;
        top: 89px;
        display: block;
        left: 31px;
    }

    .points-spinner {
        position: absolute;
        top: 88px;
        left: 48px;
    }

    .work-icon {
        width: auto;
        height: 16px;
        display: inline;
        margin-left: -5px;
        margin-right: 2px;
    }
}

.shrine-type-shrine-spyglass {
    .points {
        display: none;
    }
}

@media only screen and (max-width: 520px) {
    .shrine {
        img {
            width: 50px;
        }

        .shrine-glow,
        .shrine-hover {
            margin: 2px 0 0 5px;
        }

        .points {
            width: 44px;
            font-size: 16px;
            top: 55px;
            left: 20px;
        }

        .work-icon {
            height: 12px;
            margin-left: -1px;
            margin-right: 0px;
        }
    }
}
</style>
<style lang="scss">
$ratio: 2.3;
$spriteWidth: 655px;
$spriteHeight: 633px;
@function resize($number) {
    @return calc(#{$number} / #{$ratio});
}

.biome-2 {
    .bg-card-4 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/shrooms/shrooms-sprite.png') -138px -190px;
    }

    .mini-card-4 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize($spriteWidth) resize($spriteHeight) !important;
        background: url('/img/staking-map/biomes/shrooms/shrooms-sprite.png') resize(-138px) resize(-190px);
    }

    .bg-card-5 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/shrooms/shrooms-sprite.png') -240px -190px;
    }

    .mini-card-5 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize($spriteWidth) resize($spriteHeight) !important;
        background: url('/img/staking-map/biomes/shrooms/shrooms-sprite.png') resize(-240px) resize(-190px);
    }

    .bg-toolbox-soul {
        background: url('/img/staking-map/biomes/shrooms/shrooms-sprite.png') -97px -324px;
        width: 82px;
        height: 82px;
    }

    .mini-toolbox-soul {
        width: resize(82px);
        height: resize(82px);
        background-size: resize($spriteWidth) resize($spriteHeight) !important;
        background: url('/img/staking-map/biomes/shrooms/shrooms-sprite.png') resize(-97px) resize(-324px);
    }

    .bg-toolbox-twin {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/shrooms/shrooms-sprite.png') -199px -324px;
    }

    .mini-toolbox-twin {
        width: resize(82px);
        height: resize(82px);
        background-size: resize($spriteWidth) resize($spriteHeight) !important;
        background: url('/img/staking-map/biomes/shrooms/shrooms-sprite.png') resize(-199px) resize(-324px);
    }

    .bg-toolbox-voodoo {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/shrooms/shrooms-sprite.png') -301px -324px;
    }

    .mini-toolbox-voodoo {
        width: resize(82px);
        height: resize(82px);
        background-size: resize($spriteWidth) resize($spriteHeight) !important;
        background: url('/img/staking-map/biomes/shrooms/shrooms-sprite.png') resize(-301px) resize(-324px);
    }

    .bg-frog-s-legs {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/shrooms/shrooms-sprite.png') -465px -10px;
    }

    .mini-frog-s-legs {
        width: resize(82px);
        height: resize(82px);
        background-size: resize($spriteWidth) resize($spriteHeight) !important;
        background: url('/img/staking-map/biomes/shrooms/shrooms-sprite.png') resize(-465px) resize(-10px);
    }

    .bg-mushroom-shrine-header {
        width: 89px;
        height: 60px;
        background: url('/img/staking-map/biomes/shrooms/shrooms-sprite.png') -10px -563px;
    }

    .bg-nettle-s-leaf-blue {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/shrooms/shrooms-sprite.png') -465px -214px;
    }

    .bg-nettle-s-leaf-red {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/shrooms/shrooms-sprite.png') -465px -316px;
    }

    .bg-nettle-s-leaf {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/shrooms/shrooms-sprite.png') -10px -461px;
    }

    .mini-nettle-s-leaf-blue {
        width: resize(82px);
        height: resize(82px);
        background: url('/img/staking-map/biomes/shrooms/shrooms-sprite.png') resize(-465px) resize(-112px);
        background-size: resize($spriteWidth) resize($spriteHeight) !important;
    }

    .bg-shrine-15-header {
        width: 88px;
        height: 60px;
        background: url('/img/staking-map/biomes/shrooms/shrooms-sprite.png') -228px -563px;
    }

    .bg-shrine-30-header {
        width: 88px;
        height: 60px;
        background: url('/img/staking-map/biomes/shrooms/shrooms-sprite.png') -336px -563px;
    }

    .bg-shrine-45-header {
        width: 89px;
        height: 60px;
        background: url('/img/staking-map/biomes/shrooms/shrooms-sprite.png') -119px -563px;
    }

    .bg-shrine-mushroom-glow-gold,
    .bg-shrine-mushroom-glow-purple {
        width: 154px;
        height: 160px;
        margin: -12px 0 0 -12px;
        background: url('/img/staking-map/biomes/shrooms/shrooms-sprite.png') -10px -10px;
    }

    .bg-shrine-mushroom-glow-purple {
        background: url('/img/staking-map/biomes/shrooms/shrooms-sprite.png') -184px -10px;
    }

    .bg-shrine-mushroom {
        width: 108px;
        height: 114px;
        background: url('/img/staking-map/biomes/shrooms/shrooms-sprite.png') -10px -190px;
    }

    .bg-shrine-spyglass-glow-gold {
        width: 87px;
        height: 133px;
        background: url('/img/staking-map/biomes/shrooms/shrooms-sprite.png') -358px -10px;
    }

    .bg-shrine-spyglass-glow-purple {
        width: 87px;
        height: 133px;
        background: url('/img/staking-map/biomes/shrooms/shrooms-sprite.png') -358px -163px;
    }

    .bg-shrine-spyglass {
        width: 67px;
        height: 117px;
        background: url('/img/staking-map/biomes/shrooms/shrooms-sprite.png') -10px -324px;
    }

    .bg-single-use-land-potion {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/shrooms/shrooms-sprite.png') -112px -461px;
    }

    .mini-single-use-land-potion {
        width: resize(82px);
        height: resize(82px);
        background: url('/img/staking-map/biomes/shrooms/shrooms-sprite.png') resize(-112px) resize(-461px);
        background-size: resize($spriteWidth) resize($spriteHeight) !important;
    }

    .bg-tshirt-card {
        width: 78px;
        height: 85px;
        background: url('/img/staking-map/biomes/shrooms/shrooms-sprite.png') -567px -10px;
    }

    .mini-nettle-s-leaf,
    .mini-tshirt-card {
        width: resize(78px);
        height: resize(85px);
        background: url('/img/staking-map/biomes/shrooms/shrooms-sprite.png') resize(-567px) resize(-10px);
        background-size: resize($spriteWidth) resize($spriteHeight) !important;
    }

    .mini-unicorn-horn-blue {
        width: 36px;
        height: 35px;
        background: url('/img/staking-map/biomes/shrooms/shrooms-sprite.png') -567px -220px;
    }

    .bg-unicorn-horn-blue {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/shrooms/shrooms-sprite.png') -214px -461px;
    }

    .mini-unicorn-horn-green {
        width: 35px;
        height: 35px;
        background: url('/img/staking-map/biomes/shrooms/shrooms-sprite.png') -567px -330px;
    }

    .bg-unicorn-horn-green {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/shrooms/shrooms-sprite.png') -316px -461px;
    }

    .mini-unicorn-horn-red {
        width: 36px;
        height: 35px;
        background: url('/img/staking-map/biomes/shrooms/shrooms-sprite.png') -567px -275px;
    }

    .bg-unicorn-horn-red {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/shrooms/shrooms-sprite.png') -418px -461px;
    }
}

.biome-3 {
    .bg-0 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/spooky/shrine-sprite3.png') -97px -324px;
    }

    .mini-0 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize($spriteWidth) resize($spriteHeight) !important;
        background: url('/img/staking-map/biomes/spooky/shrine-sprite3.png') resize(-97px) resize(-324px);
    }

    .bg-1 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/spooky/shrine-sprite3.png') -199px -324px;
    }

    .mini-1 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize($spriteWidth) resize($spriteHeight) !important;
        background: url('/img/staking-map/biomes/spooky/shrine-sprite3.png') resize(-199px) resize(-324px);
    }

    .bg-19 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/spooky/shrine-sprite3.png') -301px -324px;
    }

    .mini-19 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize($spriteWidth) resize($spriteHeight) !important;
        background: url('/img/staking-map/biomes/spooky/shrine-sprite3.png') resize(-301px) resize(-324px);
    }

    .bg-21 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/spooky/shrine-sprite3.png') -461px -10px;
    }

    .mini-21 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize($spriteWidth) resize($spriteHeight) !important;
        background: url('/img/staking-map/biomes/spooky/shrine-sprite3.png') resize(-461px) resize(-10px);
    }

    .bg-22 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/spooky/shrine-sprite3.png') -461px -112px;
    }

    .mini-22 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize($spriteWidth) resize($spriteHeight) !important;
        background: url('/img/staking-map/biomes/spooky/shrine-sprite3.png') resize(-461px) resize(-112px);
    }

    .bg-27 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/spooky/shrine-sprite3.png') -461px -214px;
    }

    .mini-27 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize($spriteWidth) resize($spriteHeight) !important;
        background: url('/img/staking-map/biomes/spooky/shrine-sprite3.png') resize(-461px) resize(-214px);
    }

    .bg-35 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/spooky/shrine-sprite3.png') -461px -316px;
    }

    .mini-35 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize($spriteWidth) resize($spriteHeight) !important;
        background: url('/img/staking-map/biomes/spooky/shrine-sprite3.png') resize(-461px) resize(-316px);
    }

    .bg-36 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/spooky/shrine-sprite3.png') -10px -461px;
    }

    .mini-36 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize($spriteWidth) resize($spriteHeight) !important;
        background: url('/img/staking-map/biomes/spooky/shrine-sprite3.png') resize(-10px) resize(-461px);
    }

    .bg-37 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/spooky/shrine-sprite3.png') -112px -461px;
    }

    .mini-37 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize($spriteWidth) resize($spriteHeight) !important;
        background: url('/img/staking-map/biomes/spooky/shrine-sprite3.png') resize(-112px) resize(-461px);
    }

    .bg-card-9 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/spooky/shrine-sprite3.png') -214px -461px;
    }

    .mini-card-9 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize($spriteWidth) resize($spriteHeight) !important;
        background: url('/img/staking-map/biomes/spooky/shrine-sprite3.png') resize(-214px) resize(-461px);
    }

    .bg-card-10 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/spooky/shrine-sprite3.png') -316px -461px;
    }

    .mini-card-10 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize($spriteWidth) resize($spriteHeight) !important;
        background: url('/img/staking-map/biomes/spooky/shrine-sprite3.png') resize(-316px) resize(-461px);
    }

    .bg-card-11 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/spooky/shrine-sprite3.png') -418px -461px;
    }

    .mini-card-11 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize($spriteWidth) resize($spriteHeight) !important;
        background: url('/img/staking-map/biomes/spooky/shrine-sprite3.png') resize(-418px) resize(-461px);
    }

    .bg-end-signpost {
        width: 119px;
        height: 74px;
        background: url('/img/staking-map/biomes/spooky/shrine-sprite3.png') -133px -190px;
    }

    .bg-shrine-glow-purple,
    .bg-shrine-glow-gold {
        width: 152px;
        height: 160px;
        margin: -12px 0 0 -12px;
        background: url('/img/staking-map/biomes/spooky/shrine-sprite3.png') -10px -10px;
    }

    .bg-shrine-glow-purple {
        background: url('/img/staking-map/biomes/spooky/shrine-sprite3.png') -182px -10px;
    }

    .bg-shrine-header {
        width: 88px;
        height: 60px;
        background: url('/img/staking-map/biomes/spooky/shrine-sprite3.png') -10px -563px;
    }

    .bg-shrine-spyglass-glow-gold {
        width: 87px;
        height: 133px;
        background: url('/img/staking-map/biomes/spooky/shrine-sprite3.png') -354px -10px;
    }

    .bg-shrine-spyglass-glow-purple {
        width: 87px;
        height: 133px;
        background: url('/img/staking-map/biomes/spooky/shrine-sprite3.png') -354px -163px;
    }

    .bg-shrine-spyglass {
        width: 67px;
        height: 117px;
        background: url('/img/staking-map/biomes/spooky/shrine-sprite3.png') -10px -324px;
    }

    .bg-shrine {
        width: 103px;
        height: 114px;
        background: url('/img/staking-map/biomes/spooky/shrine-sprite3.png') -10px -190px;
    }

    .bg-wizard-shadow {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/spooky/shrine-sprite3.png') -563px -10px;
    }

    .mini-wizard-shadow {
        width: resize(82px);
        height: resize(82px);
        background-size: resize($spriteWidth) resize($spriteHeight) !important;
        background: url('/img/staking-map/biomes/spooky/shrine-sprite3.png') resize(-563px) resize(-10px);
    }
}

.biome-4 {
    .bg-2 {
        width: 56px;
        height: 82px;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png') -542px -316px;
    }

    .mini-2 {
        width: resize(56px);
        height: resize(82px);
        background-size: resize(634px) resize(623px) !important;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png') resize(-542px) resize(-316px);
    }

    .bg-3 {
        width: 56px;
        height: 82px;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png')  -455px -147px;
    }

    .mini-3 {
        width: resize(56px);
        height: resize(82px);
        background-size: resize(634px) resize(623px) !important;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png') resize(-455px) resize(-147px);
    }

    .bg-4 {
        width: 56px;
        height: 82px;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png')  -542px -418px;
    }

    .mini-4 {
        width: resize(56px);
        height: resize(82px);
        background-size: resize(634px) resize(623px) !important;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png') resize(-542px) resize(-418px);
    }

    .bg-5 {
        width: 56px;
        height: 82px;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png') -10px -531pxx;
    }

    .mini-5 {
        width: resize(56px);
        height: resize(82px);
        background-size: resize(634px) resize(623px) !important;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png') resize(-10px) resize(-531px);
    }

    .bg-6 {
        width: 56px;
        height: 82px;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png')  -86px -531px;
    }

    .mini-6 {
        width: resize(56px);
        height: resize(82px);
        background-size: resize(634px) resize(623px) !important;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png') resize(-86px) resize(-531px);
    }

    .bg-7 {
        width: 56px;
        height: 82px;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png') -162px -531px;
    }

    .mini-7 {
        width: resize(56px);
        height: resize(82px);
        background-size: resize(634px) resize(623px) !important;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png') resize(-162px) resize(-531px);
    }

    .bg-8 {
        width: 56px;
        height: 82px;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png') -238px -531px;
    }

    .mini-8 {
        width: resize(56px);
        height: resize(82px);
        background-size: resize(634px) resize(623px) !important;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png') resize(-238px) resize(-531px);
    }

    .bg-29 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png')  -10px -327px;
    }

    .mini-29 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(634px) resize(623px) !important;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png') resize(-10px) resize(-327px);
    }

    .bg-30 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png') -112px -327px;
    }

    .mini-30 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(634px) resize(623px) !important;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png') resize(-112px) resize(-327px);
    }

    .bg-31 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png') -214px -327px;
    }

    .mini-31 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(634px) resize(623px) !important;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png') resize(-214px) resize(-327px);
    }

    .bg-32 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png') -418px -327px;
    }

    .mini-32 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(634px) resize(623px) !important;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png') resize(-418px) resize(-327px);
    }

    .bg-33 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png') -316px -327px;
    }

    .mini-33 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(634px) resize(623px) !important;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png') resize(-316px) resize(-327px);
    }

    .bg-34 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png') -10px -429px;
    }

    .mini-34 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(634px) resize(623px) !important;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png') resize(-10px) resize(-429px);
    }

    .bg-38 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png')  -214px -429px;
    }

    .mini-38 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(634px) resize(623px) !important;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png') resize(-214px) resize(-429px);
    }

    .bg-39 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png') -112px -429px;
    }

    .mini-39 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(634px) resize(623px) !important;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png') resize(-112px) resize(-429px);
    }

    .bg-40 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png') -316px -429px;
    }

    .mini-40 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(634px) resize(623px) !important;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png') resize(-316px) resize(-429px);
    }

    .bg-41 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png') -542px -10px;
    }

    .mini-41 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(634px) resize(623px) !important;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png') resize(-542px) resize(-10px);
    }

    .bg-card_13 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png') -418px -429px;
    }

    .mini-card_13 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(634px) resize(623px) !important;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png') resize(-418px) resize(-429px);
    }

    .bg-card_14 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png') -542px -112px;
    }

    .mini-card_14 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(634px) resize(623px) !important;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png') resize(-542px) resize(-112px);
    }

    .bg-card_15 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png') -542px -214px;
    }

    .mini-card_15 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(634px) resize(623px) !important;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png') resize(-542px) resize(-214px);
    }

    .bg-end_signpost {
        width: 119px;
        height: 74px;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png') -141px -190px;
    }

    .bg-shrine {
        width: 111px;
        height: 117px;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png') -10px -190px;
    }

    .bg-shrine-glow-purple {
        width: 149px;
        height: 160px;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png') -10px -10px;
        margin: -12px 0 0 -10px;
    }

    .bg-shrine-glow-gold {
        width: 149px;
        height: 160px;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png') -179px -10px;
        margin: -12px 0 0 -12px;
    }

    .bg-shrine_header {
        width: 72px;
        height: 60px;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png') -314px -531px;
    }

    .bg-shrine-spyglass {
        width: 67px;
        height: 117px;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png') -455px -10px;
    }

    .bg-shrine-spyglass-glow-gold {
        width: 87px;
        height: 133px;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png') -348px -10px;
    }

    .bg-shrine-spyglass-glow-purple {
        width: 87px;
        height: 133px;
        background: url('/img/staking-map/biomes/clouds/shrine-sprite1.png') -348px -163px;
    }
}

.biome-5 {
    .bg-2 {
        width: 56px;
        height: 82px;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') -478px -147px;
    }

    .mini-2 {
        width: resize(56px);
        height: resize(82px);
        background-size: resize(733px) resize(666px) !important;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') resize(-478px) resize(-147px);
    }

    .bg-9 {
        width: 56px;
        height: 82px;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') -667px -10px;
    }

    .mini-9 {
        width: resize(56px);
        height: resize(82px);
        background-size: resize(733px) resize(666px) !important;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') resize(-667px) resize(-10px);
    }

    .bg-10 {
        width: 56px;
        height: 82px;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') -478px -249px;
    }

    .mini-10 {
        width: resize(56px);
        height: resize(82px);
        background-size: resize(733px) resize(666px) !important;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') resize(-478px) resize(-249px);
    }

    .bg-11 {
        width: 56px;
        height: 82px;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') -667px -112px;
    }

    .mini-11 {
        width: resize(56px);
        height: resize(82px);
        background-size: resize(733px) resize(666px) !important;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') resize(-667px) resize(-112px);
    }

    .bg-12 {
        width: 56px;
        height: 82px;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') -667px -214px;
    }

    .mini-12 {
        width: resize(56px);
        height: resize(82px);
        background-size: resize(733px) resize(666px) !important;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') resize(-667px) resize(-214px);
    }

    .bg-13 {
        width: 56px;
        height: 82px;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') -10px -574px;
    }

    .mini-13 {
        width: resize(56px);
        height: resize(82px);
        background-size: resize(733px) resize(666px) !important;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') resize(-10px) resize(-574px);
    }

    .bg-14 {
        width: 56px;
        height: 82px;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') -667px -316px;
    }

    .mini-14 {
        width: resize(56px);
        height: resize(82px);
        background-size: resize(733px) resize(666px) !important;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') resize(-667px) resize(-316px);
    }

    .bg-15 {
        width: 56px;
        height: 82px;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') -667px -418px;
    }

    .mini-15 {
        width: resize(56px);
        height: resize(82px);
        background-size: resize(733px) resize(666px) !important;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') resize(-667px) resize(-418px);
    }

    .bg-16 {
        width: 56px;
        height: 82px;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') -86px -574px;
    }

    .mini-16 {
        width: resize(56px);
        height: resize(82px);
        background-size: resize(733px) resize(666px) !important;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') resize(-86px) resize(-574px);
    }

    .bg-26 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') -10px -370px;
    }

    .mini-26 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(733px) resize(666px) !important;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') resize(-10px) resize(-370px);
    }

    .bg-27 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') -214px -370px;
    }

    .mini-27 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(733px) resize(666px) !important;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') resize(-214px) resize(-370px);
    }

    .bg-35 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') -112px -370px;
    }

    .mini-35 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(733px) resize(666px) !important;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') resize(-112px) resize(-370px);
    }

    .bg-36 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') -316px -370px;
    }

    .mini-36 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(733px) resize(666px) !important;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') resize(-316px) resize(-370px);
    }

    .bg-37 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') -418px -370px;
    }

    .mini-37 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(733px) resize(666px) !important;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') resize(-418px) resize(-370px);
    }

    .bg-card_16 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') -565px -112px;
    }

    .mini-card_16 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(733px) resize(666px) !important;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') resize(-565px) resize(-112px);
    }

    .bg-card_17 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') -565px -10px;
    }

    .mini-card_17 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(733px) resize(666px) !important;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') resize(-565px) resize(-10px);
    }

    .bg-card_18 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') -565px -214px;
    }

    .mini-card_18 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(733px) resize(666px) !important;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') resize(-565px) resize(-214px);
    }

    .bg-mb_e {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') -565px -316px;
    }

    .mini-mb_e {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(733px) resize(666px) !important;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') resize(-565px) resize(-316px);
    }

    .bg-mb_s {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') -112px -472px;
    }

    .mini-mb_s {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(733px) resize(666px) !important;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') resize(-112px) resize(-472px);
    }

    .bg-mb_i {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') -10px -472px;
    }

    .mini-mb_i {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(733px) resize(666px) !important;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') resize(-10px) resize(-472px);
    }

    .bg-end_signpost {
        width: 119px;
        height: 74px;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') -273px -190px;
    }

    .bg-shrine {
        width: 112px;
        height: 117px;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') -141px -190px;
    }

    .bg-shrine-glow-purple {
        width: 148px;
        height: 159px;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') -179px -10px;
        margin: -12px 0 0 -10px;
    }

    .bg-shrine-glow-gold {
        width: 149px;
        height: 160px;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') -10px -10px;
        margin: -12px 0 0 -12px;
    }

    .bg-shrine_header {
        width: 93px;
        height: 60px;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') -214px -472px;
    }

    .bg-shrine-spyglass {
        width: 67px;
        height: 117px;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') -478px -10px;
    }

    .bg-shrine-spyglass-glow-gold {
        width: 111px;
        height: 160px;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') -347px -10px;
    }

    .bg-shrine-spyglass-glow-purple {
        width: 111px;
        height: 160px;
        background: url('/img/staking-map/biomes/shores/css_sprites_1.png') -10px -190px;
    }
}

.biome-6 {
    .bg-3 {
        width: 56px;
        height: 82px;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') -455px -147px;
    }

    .mini-3 {
        width: resize(56px);
        height: resize(82px);
        background-size: resize(720px) resize(623px) !important;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') resize(-455px) resize(-147px);
    }

    .bg-5 {
        width: 56px;
        height: 82px;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') -644px -10px;
    }

    .mini-5 {
        width: resize(56px);
        height: resize(82px);
        background-size: resize(720px) resize(623px) !important;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') resize(-644px) resize(-10px);
    }

    .bg-7 {
        width: 56px;
        height: 82px;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') -644px -112px;
    }

    .mini-7 {
        width: resize(56px);
        height: resize(82px);
        background-size: resize(720px) resize(623px) !important;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') resize(-644px) resize(-112px);
    }

    .bg-10 {
        width: 56px;
        height: 82px;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') -644px -214px;
    }

    .mini-10 {
        width: resize(56px);
        height: resize(82px);
        background-size: resize(720px) resize(623px) !important;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') resize(-644px) resize(-214px);
    }

    .bg-17 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') -10px -327px;
    }

    .mini-17 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(720px) resize(623px) !important;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') resize(-10px) resize(-327px);
    }

    .bg-18 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') -112px -327px;
    }

    .mini-18 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(720px) resize(623px) !important;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') resize(-112px) resize(-327px);
    }

    .bg-19 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') -214px -327px;
    }

    .mini-19 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(720px) resize(623px) !important;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') resize(-214px) resize(-327px);
    }

    .bg-20 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') -316px -327px;
    }

    .mini-20 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(720px) resize(623px) !important;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') resize(-316px) resize(-327px);
    }

    .bg-23 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') -418px -327px;
    }

    .mini-23 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(720px) resize(623px) !important;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') resize(-418px) resize(-327px);
    }

    .bg-24 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') -10px -429px;
    }

    .mini-24 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(720px) resize(623px) !important;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') resize(-10px) resize(-429px);
    }

    .bg-25 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') -112px -429px;;
    }

    .mini-25 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(720px) resize(623px) !important;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') resize(-112px) resize(-429px);
    }

    .bg-38 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') -214px -429px;;
    }

    .mini-38 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(720px) resize(623px) !important;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') resize(-214px) resize(-429px);
    }

    .bg-39 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') -418px -429px;
    }

    .mini-39 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(720px) resize(623px) !important;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') resize(-418px) resize(-429px);
    }

    .bg-40 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') -316px -429px;
    }

    .mini-40 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(720px) resize(623px) !important;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') resize(-316px) resize(-429px);
    }

    .bg-41 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') -542px -10px;;
    }

    .mini-41 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(720px) resize(623px) !important;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') resize(-542px) resize(-10px);
    }

    .bg-42 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') -542px -112px;
    }

    .mini-42 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(720px) resize(623px) !important;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') resize(-542px) resize(-112px);
    }

    .bg-43 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') -542px -214px;
    }

    .mini-43 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(720px) resize(623px) !important;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') resize(-542px) resize(-214px);
    }

    .bg-card_19 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') -542px -316px;
    }

    .mini-card_19 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(720px) resize(623px) !important;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') resize(-542px) resize(-316px);
    }

    .bg-card_20 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') -542px -418px;
    }

    .mini-card_20 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(720px) resize(623px) !important;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') resize(-542px) resize(-418px);
    }

    .bg-card_21 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') -10px -531px;
    }

    .mini-card_21 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(720px) resize(623px) !important;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') resize(-10px) resize(-531px);
    }

    .bg-end_signpost {
        width: 119px;
        height: 74px;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png')  -151px -190px;
    }

    .bg-shrine {
        width: 121px;
        height: 117px;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') -13px -190px;
    }

    .bg-shrine-glow-purple {
        width: 150px;
        height: 160px;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') -179px -10px;
        margin: -12px 0 0 -10px;
    }

    .bg-shrine-glow-gold {
        width: 149px;
        height: 160px;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') -10px -10px;
        margin: -12px 0 0 -12px;
    }

    .bg-shrine_header {
        width: 80px;
        height: 60px;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') -112px -531px;
    }

    .bg-shrine-spyglass {
        width: 67px;
        height: 117px;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') -455px -10px;
    }

    .bg-shrine-spyglass-glow-gold {
        width: 87px;
        height: 133px;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') -348px -10px;
    }

    .bg-shrine-spyglass-glow-purple {
        width: 87px;
        height: 133px;
        background: url('/img/staking-map/biomes/volcano/css_sprites_1.png') -348px -163px;
    }
}

.biome-7 {
    .bg-2 {
        width: 56px;
        height: 82px;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png') -316px -448px;
    }

    .mini-2 {
        width: resize(56px);
        height: resize(82px);
        background-size: resize(696px) resize(620px) !important;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png') resize(-316px) resize(-448px);
    }

    .bg-9 {
        width: 56px;
        height: 82px;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png') -441px -147px;
    }

    .mini-9 {
        width: resize(56px);
        height: resize(82px);
        background-size: resize(696px) resize(620px) !important;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png') resize(-441px) resize(-147px);
    }

    .bg-11 {
        width: 56px;
        height: 82px;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png')  -630px -10px;
    }

    .mini-11 {
        width: resize(56px);
        height: resize(82px);
        background-size: resize(696px) resize(620px) !important;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png') resize(-630px) resize(-10px);
    }

    .bg-12 {
        width: 56px;
        height: 82px;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png') -468px -448px;
    }

    .mini-12 {
        width: resize(56px);
        height: resize(82px);
        background-size: resize(696px) resize(620px) !important;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png') resize(-468px) resize(-448px);
    }

    .bg-13 {
        width: 56px;
        height: 82px;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png')  -544px -448px;
    }

    .mini-13 {
        width: resize(56px);
        height: resize(82px);
        background-size: resize(696px) resize(620px) !important;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png') resize(-544px) resize(-448px);
    }

    .bg-14 {
        width: 56px;
        height: 82px;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png')  -630px -316px;
    }

    .mini-14 {
        width: resize(56px);
        height: resize(82px);
        background-size: resize(696px) resize(620px) !important;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png') resize(-630px) resize(-316px);
    }

    .bg-15 {
        width: 56px;
        height: 82px;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png') -630px -112px;
    }

    .mini-15 {
        width: resize(56px);
        height: resize(82px);
        background-size: resize(696px) resize(620px) !important;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png') resize(-630px) resize(-112px);
    }

    .bg-16 {
        width: 56px;
        height: 82px;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png') -630px -214px;
    }

    .mini-16 {
        width: resize(56px);
        height: resize(82px);
        background-size: resize(696px) resize(620px) !important;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png') resize(-630px) resize(-214px);
    }

    .bg-21 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png') -112px -346px;
    }

    .mini-21 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(696px) resize(620px) !important;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png') resize(-112px) resize(-346px);
    }

    .bg-22 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png') -10px -346px;
    }

    .mini-22 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(696px) resize(620px) !important;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png') resize(-10px) resize(-346px);
    }

    .bg-29 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png') -528px -316px;
    }

    .mini-29 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(696px) resize(620px) !important;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png') resize(-528px) resize(-316px);
    }

    .bg-30 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png') -214px -346px;
    }

    .mini-30 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(696px) resize(620px) !important;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png') resize(-214px) resize(-346px);
    }

    .bg-31 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png') -418px -346px;
    }

    .mini-31 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(696px) resize(620px) !important;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png') resize(-418px) resize(-346px);
    }

    .bg-36 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png') -316px -346px;
    }

    .mini-36 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(696px) resize(620px) !important;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png') resize(-316px) resize(-346px);
    }

    .bg-37 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png') -528px -214px;
    }

    .mini-37 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(696px) resize(620px) !important;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png') resize(-528px) resize(-214px);
    }

    .bg-44 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png') -528px -10px;
    }

    .mini-44 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(696px) resize(620px) !important;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png') resize(-528px) resize(-10px);
    }

    .bg-45 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png') -10px -448px;
    }

    .mini-45 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(696px) resize(620px) !important;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png') resize(-10px) resize(-448px);
    }

    .bg-card_22 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png') -528px -112px;
    }

    .mini-card_22 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(696px) resize(620px) !important;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png') resize(-528px) resize(-112px);
    }

    .bg-card_23 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png') -214px -448px;
    }

    .mini-card_23 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(696px) resize(620px) !important;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png') resize(-214px) resize(-448px);
    }

    .bg-card_24 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png') -112px -448px;
    }

    .mini-card_24 {
        width: resize(82px);
        height: resize(82px);
        background-size: resize(696px) resize(620px) !important;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png') resize(-112px) resize(-448px);
    }

    .bg-end_signpost {
        width: 119px;
        height: 74px;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png')  -233px -193px;;
    }

    .bg-shrine {
        width: 96px;
        height: 117px;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png') -117px -193px;
    }

    .bg-shrine-glow-purple {
        width: 142px;
        height: 163px;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png') -172px -10px;
        margin: -12px 0 0 -10px;
    }

    .bg-shrine-glow-gold {
        width: 142px;
        height: 163px;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png')  -10px -10px;
        margin: -12px 0 0 -12px;
    }

    .bg-shrine_header {
        width: 70px;
        height: 60px;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png') -10px -550px;
    }

    .bg-shrine-spyglass {
        width: 67px;
        height: 117px;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png') -441px -10px;
    }

    .bg-shrine-spyglass-glow-gold {
        width: 87px;
        height: 133px;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png') -10px -193px;
    }

    .bg-shrine-spyglass-glow-purple {
        width: 87px;
        height: 133px;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png') -334px -10px;
    }
}

.biome-1-cycle-2,
.biome-2-cycle-2,
.biome-3-cycle-2,
.biome-4-cycle-2,
.biome-5-cycle-2,
.biome-6-cycle-2,
.biome-7-cycle-2 {
    .shrine-stock {
        width: 120px;
        top: 55px;
        left: 12px;
    }
}

.biome-3-cycle-2 {
    .shrine-stock {
        width: 120px;
        top: 48px;
        left: 1px;
    }
}

.biome-1-cycle-2,
.biome-2-cycle-2 {
    .points {
        width: 70px;
        text-align: center;
        font-size: 22px;
        position: absolute;
        top: 113px !important;
        display: block;
        left: 38px !important;
    }

    .points-spinner {
        position: absolute;
        top: 113px !important;
        left: 57px !important;
    }
}

.biome-1-cycle-2,
.biome-2-cycle-2,
.biome-3-cycle-2,
.biome-4-cycle-2,
.biome-5-cycle-2,
.biome-6-cycle-2,
.biome-7-cycle-2 {
    .bg-end-signpost {
        width: 119px;
        height: 74px;
        background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png')  -233px -193px;;
    }

    .bg-3 {
        width: 56px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -1079px  -115px;
    }

    .bg-2 {
        width: 56px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -1079px  -217px;
    }

    .bg-0 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -861px  -147px;
    }

    .bg-1 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -861px  -249px;
    }

    .bg-5 {
        width: 56px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -1079px  -319px;
    }

    .bg-7 {
        width: 56px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -1079px  -421px;
    }

    .bg-4 {
        width: 56px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -1079px  -523px;
    }

    .bg-9 {
        width: 56px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -1079px  -625px;
    }

    .bg-6 {
        width: 56px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -1079px  -727px;
    }

    .bg-8 {
        width: 56px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -1079px  -829px;
    }

    .bg-11 {
        width: 56px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -1079px  -931px;
    }

    .bg-13 {
        width: 56px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -610px  -370px;
    }

    .bg-15 {
        width: 56px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -10px  -1041px;
    }

    .bg-10 {
        width: 56px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -86px  -1041px;
    }

    .bg-12 {
        width: 56px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -162px  -1041px;
    }

    .bg-14 {
        width: 56px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -238px  -1041px;
    }

    .bg-18 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -861px  -351px;
    }

    .bg-19 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -861px  -453px;
    }

    .bg-17 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -861px  -555px;
    }

    .bg-20 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -10px  -735px;
    }

    .bg-21 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -112px  -735px;
    }

    .bg-22 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -214px  -735px;
    }

    .bg-23 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -316px  -735px;
    }

    .bg-16 {
        width: 56px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -314px  -1041px;
    }

    .bg-26 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -418px  -735px;
    }

    .bg-27 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -520px  -735px;
    }

    .bg-24 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -622px  -735px;
    }

    .bg-29 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -724px  -735px;
    }

    .bg-30 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -826px  -735px;
    }

    .bg-28 {
        width: 78px;
        height: 85px;
        background: url('/img/staking-map/css_sprites_v1.png') -1079px  -10px;
    }

    .bg-31 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -10px  -837px;
    }

    .bg-37 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -112px  -837px;
    }

    .bg-36 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -214px  -837px;
    }

    .bg-25 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -316px  -837px;
    }

    .bg-33 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -418px  -837px;
    }

    .bg-34 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -520px  -837px;
    }

    .bg-39 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -622px  -837px;
    }

    .bg-32 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -724px  -837px;
    }

    .bg-38 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -826px  -837px;
    }

    .bg-35 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -977px  -10px;
    }

    .bg-40 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -977px  -112px;
    }

    .bg-44 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -977px  -214px;
    }

    .bg-41 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -977px  -316px;
    }

    .bg-43 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -977px  -418px;
    }

    .bg-45 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -977px  -520px;
    }

    .bg-mystery-card {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -977px  -622px;
    }

    .bg-42 {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -977px  -724px;
    }

    .bg-end-signpost {
        width: 119px;
        height: 74px;
        background: url('/img/staking-map/css_sprites_v1.png') -696px  -550px;
    }

    .bg-shrine-1-glow-gold {
        width: 125px;
        height: 165px;
        background: url('/img/staking-map/css_sprites_v1.png') -10px  -550px;
        margin: 10px 0 0 10px;
    }

    .bg-mystery-elixirs {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -977px  -826px;
    }

    .bg-mystery-ingredients {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -10px  -939px;
    }

    .bg-mystery-wizard {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -112px  -939px;
    }

    .bg-shrine-1-header {
        width: 60px;
        height: 28px;
        background: url('/img/staking-map/css_sprites_v1.png') -861px  -657px;
    }

    .bg-shrine-2-glow-gold {
        margin-left: -7px;
        margin-top: 10px;
        width: 154px;
        height: 160px;
        background: url('/img/staking-map/css_sprites_v1.png') -10px  -10px;
    }

    .bg-mystery-random {
        width: 82px;
        height: 82px;
        background: url('/img/staking-map/css_sprites_v1.png') -214px  -939px;
    }

    .bg-shrine-1 {
        width: 125px;
        height: 165px;
        background: url('/img/staking-map/css_sprites_v1.png') -155px  -550px;
    }

    .bg-shrine-2 {
        margin-left: 7px;
        margin-top: 24px;
        width: 108px;
        height: 114px;
        background: url('/img/staking-map/css_sprites_v1.png') -445px  -550px;
    }

    .bg-shrine-2-header {
        width: 89px;
        height: 60px;
        background: url('/img/staking-map/css_sprites_v1.png') -429px  -939px;
    }

    .bg-shrine-1-glow-purple {
        width: 125px;
        height: 165px;
        background: url('/img/staking-map/css_sprites_v1.png') -300px  -550px;
        margin: 10px 0 0 10px;
    }

    .bg-shrine-3-glow-gold {
        margin: -12px 0 0 -12px;
        width: 152px;
        height: 160px;
        background: url('/img/staking-map/css_sprites_v1.png') -358px  -10px;
    }

    .bg-shrine-2-glow-purple {
        margin-left: -5px;
        margin-top: 10px;
        width: 154px;
        height: 160px;
        background: url('/img/staking-map/css_sprites_v1.png') -184px  -10px;
    }

    .bg-shrine-3 {
        width: 103px;
        height: 114px;
        background: url('/img/staking-map/css_sprites_v1.png') -573px  -550px;
    }

    .bg-shrine-3-glow-purple {
        margin: -12px 0 0 -12px;
        width: 152px;
        height: 160px;
        background: url('/img/staking-map/css_sprites_v1.png') -10px  -190px;
    }

    .bg-shrine-3-header {
        width: 88px;
        height: 60px;
        background: url('/img/staking-map/css_sprites_v1.png') -538px  -939px;
    }

    .bg-shrine-4-glow-purple {
        margin: -12px 0 0 -12px;
        width: 149px;
        height: 160px;
        background: url('/img/staking-map/css_sprites_v1.png') -182px  -190px;
    }

    .bg-shrine-4-header {
        width: 72px;
        height: 60px;
        background: url('/img/staking-map/css_sprites_v1.png') -746px  -939px;
    }

    .bg-shrine-6 {
        width: 121px;
        height: 117px;
        background: url('/img/staking-map/css_sprites_v1.png') -699px  -376px;
    }

    .bg-shrine-4-glow-gold {
        margin: -12px 0 0 -12px;
        width: 149px;
        height: 160px;
        background: url('/img/staking-map/css_sprites_v1.png') -351px  -190px;
    }

    .bg-shrine-5-glow-purple {
        width: 148px;
        height: 159px;
        background: url('/img/staking-map/css_sprites_v1.png') -179px  -370px;
    }

    .bg-shrine-5-header {
        width: 93px;
        height: 60px;
        background: url('/img/staking-map/css_sprites_v1.png') -316px  -939px;
    }

    .bg-shrine-5-glow-gold {
        width: 149px;
        height: 160px;
        background: url('/img/staking-map/css_sprites_v1.png') -530px  -10px;
    }

    .bg-shrine-4 {
        width: 111px;
        height: 117px;
        background: url('/img/staking-map/css_sprites_v1.png') -479px  -370px;
    }

    .bg-shrine-6-glow-gold {
        width: 149px;
        height: 160px;
        background: url('/img/staking-map/css_sprites_v1.png') -530px  -190px;
    }

    .bg-shrine-6-header {
        width: 80px;
        height: 60px;
        background: url('/img/staking-map/css_sprites_v1.png') -646px  -939px;
    }

    .bg-shrine-5 {
        width: 112px;
        height: 117px;
        background: url('/img/staking-map/css_sprites_v1.png') -347px  -370px;
    }

    .bg-shrine-6-glow-purple {
        width: 149px;
        height: 160px;
        background: url('/img/staking-map/css_sprites_v1.png') -10px  -370px;
    }

    .bg-shrine-7 {
        width: 96px;
        height: 117px;
        background: url('/img/staking-map/css_sprites_v1.png') -861px  -10px;
    }

    .bg-shrine-7-header {
        width: 70px;
        height: 60px;
        background: url('/img/staking-map/css_sprites_v1.png') -838px  -939px;
    }

    .bg-shrine-7-glow-purple {
        width: 142px;
        height: 163px;
        background: url('/img/staking-map/css_sprites_v1.png') -699px  -10px;
    }

    .bg-shrine-7-glow-gold {
        width: 142px;
        height: 163px;
        background: url('/img/staking-map/css_sprites_v1.png') -699px  -193px;
    }

    .mini-3 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(56px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-1079px) resize(-115px);
    }

    .mini-2 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(56px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-1079px) resize(-217px);
    }

    .mini-0 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(82px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-861px) resize(-147px);
    }

    .mini-1 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(82px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-861px) resize(-249px);
    }

    .mini-5 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(56px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-1079px) resize(-319px);
    }

    .mini-7 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(56px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-1079px) resize(-421px);
    }

    .mini-4 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(56px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-1079px) resize(-523px);
    }

    .mini-9 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(56px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-1079px) resize(-625px);
    }

    .mini-6 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(56px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-1079px) resize(-727px);
    }

    .mini-8 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(56px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-1079px) resize(-829px);
    }

    .mini-11 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(56px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-1079px) resize(-931px);
    }

    .mini-13 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(56px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-610px) resize(-370px);
    }

    .mini-15 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(56px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-10px) resize(-1041px);
    }

    .mini-10 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(56px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-86px) resize(-1041px);
    }

    .mini-12 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(56px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-162px) resize(-1041px);
    }

    .mini-14 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(56px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-238px) resize(-1041px);
    }

    .mini-18 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(82px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-861px) resize(-351px);
    }

    .mini-19 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(82px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-861px) resize(-453px);
    }

    .mini-17 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(82px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-861px) resize(-555px);
    }

    .mini-20 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(82px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-10px) resize(-735px);
    }

    .mini-21 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(82px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-112px) resize(-735px);
    }

    .mini-22 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(82px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-214px) resize(-735px);
    }

    .mini-23 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(82px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-316px) resize(-735px);
    }

    .mini-16 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(56px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-314px) resize(-1041px);
    }

    .mini-26 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(82px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-418px) resize(-735px);
    }

    .mini-27 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(82px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-520px) resize(-735px);
    }

    .mini-24 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(82px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-622px) resize(-735px);
    }

    .mini-29 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(82px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-724px) resize(-735px);
    }

    .mini-30 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(82px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-826px) resize(-735px);
    }

    .mini-28 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(78px);
        height: resize(85px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-1079px) resize(-10px);
    }

    .mini-31 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(82px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-10px) resize(-837px);
    }

    .mini-37 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(82px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-112px) resize(-837px);
    }

    .mini-36 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(82px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-214px) resize(-837px);
    }

    .mini-25 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(82px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-316px) resize(-837px);
    }

    .mini-33 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(82px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-418px) resize(-837px);
    }

    .mini-34 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(82px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-520px) resize(-837px);
    }

    .mini-39 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(82px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-622px) resize(-837px);
    }

    .mini-32 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(82px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-724px) resize(-837px);
    }

    .mini-38 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(82px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-826px) resize(-837px);
    }

    .mini-35 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(82px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-977px) resize(-10px);
    }

    .mini-40 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(82px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-977px) resize(-112px);
    }

    .mini-44 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(82px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-977px) resize(-214px);
    }

    .mini-41 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(82px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-977px) resize(-316px);
    }

    .mini-43 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(82px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-977px) resize(-418px);
    }

    .mini-45 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(82px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-977px) resize(-520px);
    }

    .mini-mystery-card {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(82px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-977px) resize(-622px);
    }

    .mini-42 {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(82px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-977px) resize(-724px);
    }

    .mini-end-signpost {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(119px);
        height: resize(74px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-696px) resize(-550px);
    }

    .mini-mystery-elixirs {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(82px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-977px) resize(-826px);
    }

    .mini-mystery-ingredients {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(82px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-10px) resize(-939px);
    }

    .mini-mystery-wizard {
        background-size: resize(1167px) resize(1133px) !important;
        width: resize(82px);
        height: resize(82px);
        background: url('/img/staking-map/css_sprites_v1.png') resize(-112px) resize(-939px);
    }
}
</style>
<style lang="scss" scoped>
@media only screen and (max-width: 520px) {
    $ratio: 1.7;
    $spriteWidth: 655px;
    $spriteHeight: 633px;
    @function resize($number) {
        @return calc(#{$number} / #{$ratio});
    }
    .biome-2 {
        .shrine {
            .points-spinner {
                top: 50px;
                left: 28px;
            }

            .shrine-stock {
                width: 90px;
                top: 20px;
                left: -5px;
            }

            .bg-shrine-mushroom {
                width: resize(108px);
                height: resize(114px);
                background-size: resize($spriteWidth) resize($spriteHeight) !important;
                background-position: resize(-10px) resize(-190px);
            }

            .bg-shrine-mushroom-glow-gold,
            .bg-shrine-mushroom-glow-purple {
                width: resize(154px);
                height: resize(160px);
                margin: -2px 0 0 -2px !important;
                background-size: resize($spriteWidth) resize($spriteHeight) !important;
                background-position: resize(-10px) resize(-10px);
            }

            .bg-shrine-mushroom-glow-purple {
                background-position: resize(-184px) resize(-10px);
            }

            .bg-shrine-spyglass {
                width: resize(67px);
                height: resize(117px);
                background-size: resize($spriteWidth) resize($spriteHeight) !important;
                background-position: resize(-10px) resize(-324px);
            }

            .bg-shrine-spyglass-glow-gold,
            .bg-shrine-spyglass-glow-purple {
                width: resize(87px);
                height: resize(133px);
                background-size: resize($spriteWidth) resize($spriteHeight) !important;
                background-position: resize(-358px) resize(-10px);
            }

            .bg-shrine-spyglass-glow-purple {
                background-position: resize(-358px) resize(-163px);
            }
        }
    }
    .biome-3 {
        .shrine {
            .points-spinner {
                top: 50px;
                left: 28px;
            }

            .shrine-stock {
                width: 90px;
                top: 20px;
                left: -5px;
            }

            .bg-shrine {
                width: resize(108px);
                height: resize(114px);
                background-size: resize($spriteWidth) resize($spriteHeight) !important;
                background-position: resize(-10px) resize(-190px);
            }

            .bg-shrine-glow-gold,
            .bg-shrine-glow-purple {
                width: resize(154px);
                height: resize(160px);
                margin: -2px 0 0 -2px !important;
                background-size: resize($spriteWidth) resize($spriteHeight) !important;
                background-position: resize(-10px) resize(-10px);
            }

            .bg-shrine-glow-purple {
                background-position: resize(-184px) resize(-10px);
            }

            .bg-shrine-spyglass {
                width: resize(67px);
                height: resize(117px);
                background-size: resize($spriteWidth) resize($spriteHeight) !important;
                background-position: resize(-10px) resize(-324px);
            }

            .bg-shrine-spyglass-glow-gold,
            .bg-shrine-spyglass-glow-purple {
                width: resize(87px);
                height: resize(133px);
                background-size: resize($spriteWidth) resize($spriteHeight) !important;
                background-position: resize(-358px) resize(-10px);
            }

            .bg-shrine-spyglass-glow-purple {
                background-position: resize(-358px) resize(-163px);
            }
        }
    }
    .biome-4 {
        .shrine {
            .points-spinner {
                top: 50px;
                left: 28px;
            }

            .shrine-stock {
                width: 90px;
                top: 20px;
                left: -5px;
            }

            .bg-shrine {
                width: resize(108px);
                height: resize(114px);
                background-size: resize(634px) resize(623px) !important;
                background-position: resize(-10px) resize(-190px);
            }

            .bg-shrine-glow-gold,
            .bg-shrine-glow-purple {
                width: resize(154px);
                height: resize(160px);
                background-size: resize(634px) resize(623px) !important;
                background-position: resize(-10px) resize(-10px);
                margin-left: 2.5px !important;
                margin-top: -1px !important;
            }

            .bg-shrine-glow-purple {
                background-position: resize(-184px) resize(-10px);
            }

            .bg-shrine-spyglass {
                width: resize(67px);
                height: resize(117px);
                background-size: resize(634px) resize(623px) !important;
                background-position: resize(-455px) resize(-10px);
            }

            .bg-shrine-spyglass-glow-gold,
            .bg-shrine-spyglass-glow-purple {
                width: resize(87px);
                height: resize(133px);
                background-size: resize(634px) resize(623px) !important;
                background-position: resize(-348px) resize(-10px);
                margin-left: 10px;
                margin-top: 4px;
            }

            .bg-shrine-spyglass-glow-purple {
                background-position: resize(-358px) resize(-163px);
            }
        }
    }
    .biome-5 {
        .shrine {
            .points-spinner {
                top: 50px;
                left: 28px;
            }

            .shrine-stock {
                width: 90px;
                top: 20px;
                left: -5px;
            }

            .bg-shrine {
                width: resize(108px);
                height: resize(114px);
                background-size: resize(733px) resize(666px) !important;
                background-position: resize(-141px) resize(-190px);
            }

            .bg-shrine-glow-gold,
            .bg-shrine-glow-purple {
                width: resize(154px);
                height: resize(160px);
                background-size: resize(733px) resize(666px) !important;
                background-position: resize(-10px) resize(-10px);
                margin-left: -1px !important;
                margin-top: -2px !important;
            }

            .bg-shrine-glow-purple {
                background-position: resize(-179px) resize(-10px);
            }

            .bg-shrine-spyglass {
                width: resize(67px);
                height: resize(117px);
                background-size: resize(733px) resize(666px) !important;
                background-position: resize(-478px) resize(-10px);
            }

            .bg-shrine-spyglass-glow-gold,
            .bg-shrine-spyglass-glow-purple {
                width: resize(111px);
                height: resize(160px);
                background-size: resize(733px) resize(666px) !important;
                background-position: resize(-347px) resize(-10px);
                margin-left: 7px;
                margin-top: 5px;
            }

            .bg-shrine-spyglass-glow-purple {
                background-position: resize(-10px) resize(-190px);
            }
        }
    }
    .biome-6 {
        .shrine {
            .points-spinner {
                top: 50px;
                left: 28px;
            }

            .shrine-stock {
                width: 100px;
                top: 20px;
                left: -5px;
            }

            .bg-shrine {
                width: resize(121px);
                height: resize(117px);
                background-size: resize(710px) resize(623px) !important;
                background-position: resize(-13px) resize(-190px);
            }

            .bg-shrine-glow-gold,
            .bg-shrine-glow-purple {
                width: resize(150px);
                height: resize(160px);
                background-size: resize(710px) resize(623px) !important;
                background-position: resize(-10px) resize(-10px);
                margin-left: 0px !important;
                margin-top: 0px !important;
            }

            .bg-shrine-glow-purple {
                background-position: resize(-179px) resize(-10px);
            }

            .bg-shrine-spyglass {
                width: resize(67px);
                height: resize(117px);
                background-size: resize(710px) resize(623px) !important;
                background-position: resize(-455px) resize(-10px);
            }

            .bg-shrine-spyglass-glow-gold,
            .bg-shrine-spyglass-glow-purple {
                width: resize(87px);
                height: resize(133px);
                background-size: resize(710px) resize(623px) !important;
                background-position: resize(-348px) resize(-10px);
                margin-left: 5px;
                margin-top: 5px;
            }

            .bg-shrine-spyglass-glow-purple {
                background-position: resize(-348px) resize(-163px);
            }
        }
    }
    .biome-7 {
        .shrine {
            .points-spinner {
                top: 50px;
                left: 28px;
            }

            .shrine-stock {
                width: 90px;
                top: 20px;
                left: -5px;
            }

            .bg-shrine {
                width: resize(96px);
                height: resize(117px);
                background-size: resize(696px) resize(620px) !important;
                background-position: resize(-117px) resize(-193px);
            }

            .bg-shrine-glow-gold,
            .bg-shrine-glow-purple {
                width: resize(142px);
                height: resize(163px);
                background-size: resize(696px) resize(620px) !important;
                background-position: resize(-10px) resize(-10px);
                margin-left: -4px !important;
                margin-top: -1px !important;
            }

            .bg-shrine-glow-purple {
                background-position: resize(-172px) resize(-10px);
            }

            .bg-shrine-spyglass {
                width: resize(67px);
                height: resize(117px);
                background-size: resize(696px) resize(620px) !important;
                background-position: resize(-441px) resize(-10px);
            }

            .bg-shrine-spyglass-glow-gold,
            .bg-shrine-spyglass-glow-purple {
                width: resize(87px);
                height: resize(133px);
                background-size: resize(696px) resize(620px) !important;
                background-position: resize(-10px) resize(-193px);
                margin-left: 5px;
                margin-top: 5px;
            }

            .bg-shrine-spyglass-glow-purple {
                background-position: resize(-334px) resize(-10px);
            }
        }
    }

    .biome-1-cycle-2,
    .biome-2-cycle-2,
    .biome-3-cycle-2,
    .biome-4-cycle-2,
    .biome-5-cycle-2,
    .biome-6-cycle-2,
    .biome-7-cycle-2 {
        .shrine-stock {
            width: 120px;
            top: 20px;
            left: -7px;
        }

        .points {
            width: 70px;
            text-align: center;
            font-size: 22px;
            position: absolute;
            top: 65px !important;
            display: block;
            left: 5px !important;
        }

        .points-spinner {
            position: absolute;
            top: 65px !important;
            left: 31px !important;
        }

    }

    .biome-1-cycle-2,
    .biome-2-cycle-2,
    .biome-3-cycle-2,
    .biome-4-cycle-2,
    .biome-5-cycle-2,
    .biome-6-cycle-2,
    .biome-7-cycle-2 {
        .bg-shrine-1-header {
            background-size: resize(1167px) resize(1133px) !important;
            width: resize(60px);
            height: resize(28px);
            background: url('/img/staking-map/css_sprites_v1.png') resize(-861px) resize(-657px);
        }

        .bg-shrine-2-glow-gold {
            margin-top: 11px;
            margin-left: 3px;
            background-size: resize(1167px) resize(1133px) !important;
            width: resize(154px);
            height: resize(160px);
            background: url('/img/staking-map/css_sprites_v1.png') resize(-10px) resize(-10px);
        }

        .bg-mystery-random {
            background-size: resize(1167px) resize(1133px) !important;
            width: resize(82px);
            height: resize(82px);
            background: url('/img/staking-map/css_sprites_v1.png') resize(-214px) resize(-939px);
        }

        .bg-shrine-1 {
            background-size: resize(1167px) resize(1133px) !important;
            width: resize(125px);
            height: resize(165px);
            background: url('/img/staking-map/css_sprites_v1.png') resize(-155px) resize(-550px);
        }

        .bg-shrine-2 {
            margin-top: 14px;
            margin-left: 7px;
            background-size: resize(1167px) resize(1133px) !important;
            width: resize(108px);
            height: resize(114px);
            background: url('/img/staking-map/css_sprites_v1.png') resize(-445px) resize(-550px);
        }

        .bg-shrine-2-header {
            background-size: resize(1167px) resize(1133px) !important;
            width: resize(89px);
            height: resize(60px);
            background: url('/img/staking-map/css_sprites_v1.png') resize(-429px) resize(-939px);
        }

        .bg-shrine-1-glow-purple {
            background-size: resize(1167px) resize(1133px) !important;
            width: resize(125px);
            height: resize(165px);
            background: url('/img/staking-map/css_sprites_v1.png') resize(-300px) resize(-550px);
            margin: 10px 0 0 10px;
        }

        .bg-shrine-1-glow-gold {
            background-size: resize(1167px) resize(1133px) !important;
            width: resize(125px);
            height: resize(165px);
            background: url('/img/staking-map/css_sprites_v1.png') resize(-10px) resize(-550px);
            margin: 10px 0 0 10px;
        }

        .bg-shrine-3-glow-gold {
            background-size: resize(1167px) resize(1133px) !important;
            width: resize(152px);
            height: resize(160px);
            background: url('/img/staking-map/css_sprites_v1.png') resize(-358px) resize(-10px);
        }

        .bg-shrine-2-glow-purple {
            margin-top: 11px;
            margin-left: 3px;
            background-size: resize(1167px) resize(1133px) !important;
            width: resize(154px);
            height: resize(160px);
            background: url('/img/staking-map/css_sprites_v1.png') resize(-184px) resize(-10px);
        }

        .bg-shrine-3 {
            background-size: resize(1167px) resize(1133px) !important;
            width: resize(103px);
            height: resize(114px);
            background: url('/img/staking-map/css_sprites_v1.png') resize(-573px) resize(-550px);
        }

        .bg-shrine-3-glow-purple {
            background-size: resize(1167px) resize(1133px) !important;
            width: resize(152px);
            height: resize(160px);
            background: url('/img/staking-map/css_sprites_v1.png') resize(-10px) resize(-190px);
        }

        .bg-shrine-3-header {
            background-size: resize(1167px) resize(1133px) !important;
            width: resize(88px);
            height: resize(60px);
            background: url('/img/staking-map/css_sprites_v1.png') resize(-538px) resize(-939px);
        }

        .bg-shrine-4-glow-purple {
            background-size: resize(1167px) resize(1133px) !important;
            width: resize(149px);
            height: resize(160px);
            background: url('/img/staking-map/css_sprites_v1.png') resize(-182px) resize(-190px);
        }

        .bg-shrine-4-header {
            background-size: resize(1167px) resize(1133px) !important;
            width: resize(72px);
            height: resize(60px);
            background: url('/img/staking-map/css_sprites_v1.png') resize(-746px) resize(-939px);
        }

        .bg-shrine-6 {
            background-size: resize(1167px) resize(1133px) !important;
            width: resize(121px);
            height: resize(117px);
            background: url('/img/staking-map/css_sprites_v1.png') resize(-699px) resize(-376px);
        }

        .bg-shrine-4-glow-gold {
            background-size: resize(1167px) resize(1133px) !important;
            width: resize(149px);
            height: resize(160px);
            background: url('/img/staking-map/css_sprites_v1.png') resize(-351px) resize(-190px);
        }

        .bg-shrine-5-glow-purple {
            background-size: resize(1167px) resize(1133px) !important;
            width: resize(148px);
            height: resize(159px);
            background: url('/img/staking-map/css_sprites_v1.png') resize(-179px) resize(-370px);
        }

        .bg-shrine-5-header {
            background-size: resize(1167px) resize(1133px) !important;
            width: resize(93px);
            height: resize(60px);
            background: url('/img/staking-map/css_sprites_v1.png') resize(-316px) resize(-939px);
        }

        .bg-shrine-5-glow-gold {
            background-size: resize(1167px) resize(1133px) !important;
            width: resize(149px);
            height: resize(160px);
            background: url('/img/staking-map/css_sprites_v1.png') resize(-530px) resize(-10px);
        }

        .bg-shrine-4 {
            background-size: resize(1167px) resize(1133px) !important;
            width: resize(111px);
            height: resize(117px);
            background: url('/img/staking-map/css_sprites_v1.png') resize(-479px) resize(-370px);
        }

        .bg-shrine-6-glow-gold {
            background-size: resize(1167px) resize(1133px) !important;
            width: resize(149px);
            height: resize(160px);
            background: url('/img/staking-map/css_sprites_v1.png') resize(-530px) resize(-190px);
        }

        .bg-shrine-6-header {
            background-size: resize(1167px) resize(1133px) !important;
            width: resize(80px);
            height: resize(60px);
            background: url('/img/staking-map/css_sprites_v1.png') resize(-646px) resize(-939px);
        }

        .bg-shrine-5 {
            background-size: resize(1167px) resize(1133px) !important;
            width: resize(112px);
            height: resize(117px);
            background: url('/img/staking-map/css_sprites_v1.png') resize(-347px) resize(-370px);
        }

        .bg-shrine-6-glow-purple {
            background-size: resize(1167px) resize(1133px) !important;
            width: resize(149px);
            height: resize(160px);
            background: url('/img/staking-map/css_sprites_v1.png') resize(-10px) resize(-370px);
        }

        .bg-shrine-7 {
            background-size: resize(1167px) resize(1133px) !important;
            width: resize(96px);
            height: resize(117px);
            background: url('/img/staking-map/css_sprites_v1.png') resize(-861px) resize(-10px);
        }

        .bg-shrine-7-header {
            background-size: resize(1167px) resize(1133px) !important;
            width: resize(70px);
            height: resize(60px);
            background: url('/img/staking-map/css_sprites_v1.png') resize(-838px) resize(-939px);
        }

        .bg-shrine-7-glow-purple {
            background-size: resize(1167px) resize(1133px) !important;
            width: resize(142px);
            height: resize(163px);
            background: url('/img/staking-map/css_sprites_v1.png') resize(-699px) resize(-10px);
        }

        .bg-shrine-7-glow-gold {
            background-size: resize(1167px) resize(1133px) !important;
            width: resize(142px);
            height: resize(163px);
            background: url('/img/staking-map/css_sprites_v1.png') resize(-699px) resize(-193px);
        }
    }
}
</style>
