<template>
    <v-row>
        <div id="map" :class="{'nextMapPreview':spyglassUsed}" class="biome-7">
            <canvas id="canvas" data-paper-hidpi="off"/>
            <v-tooltip v-for="pawn in pawnDetails" :key="pawn.id"
                       top
                       z-index="1000"
            >
                <template v-slot:activator="{ on, attrs }">
                    <div :style="getPawnDetailsFrameStyle(pawn.position, pawn.height, pawn.width)"
                         class="pawn-details-container" v-bind="attrs"
                         v-on="on">
                    </div>
                </template>
                <div class="pawn-details-tooltip">
                    Caravan Details:
                    <ul>
                        <li>On map: {{ pawn.offsetDayOnActiveMap }} days</li>
                        <li>
                            Work points: {{ pawn.size }} <img class="work-icon" src="/img/staking-map/work_16.png"/>
                        </li>
                        <li v-if="pawn.activeBonuses[0]">Veteran: Desert
                            <img class="work-icon"
                                 src="/img/staking-map/pawns/mini/minion-desert.png"/>
                        </li>
                        <li v-if="pawn.activeBonuses[1]">Veteran: Forest
                            <img class="work-icon"
                                 src="/img/staking-map/pawns/mini/minion-shrooms.png"/>
                        </li>
                        <li v-if="pawn.activeBonuses[2]">Veteran: Spooky
                            <img class="work-icon"
                                 src="/img/staking-map/pawns/mini/minion-spooky-30.png"/>
                        </li>
                        <li v-if="pawn.activeBonuses[3]">Veteran: Clouds
                            <img class="work-icon"
                                 src="/img/staking-map/pawns/mini/minion-clouds-30.png"/>
                        </li>
                        <li v-if="pawn.activeBonuses[4]">Veteran: Shores
                            <img class="work-icon"
                                 src="/img/staking-map/pawns/mini/minion-shores-30.png"/>
                        </li>
                        <li v-if="pawn.activeBonuses[5]">Veteran: Volcano
                            <img class="work-icon"
                                 src="/img/staking-map/pawns/mini/minion-volcano-30.png"/>
                        </li>
                    </ul>
                </div>
            </v-tooltip>

            <div id="map-img" :class="{'nextMapPreviewMap':spyglassUsed}"/>
            <img
                v-for="(pawn) in pawns"
                :id="'pawn-'+pawn.id" :src="pawn.img"
                class="d-none"
            />
            <img
                v-for="(pawn) in pawns"
                :id="'mini-pawn-'+pawn.id" :src="pawn.imgMini"
                class="d-none"
            />
            <img
                v-for="(pawn) in pawns"
                :id="'mini-pawn-'+pawn.id+'-minion-desert'" class="d-none"
                src="/img/staking-map/pawns/mini/minion-desert.png"
            />
            <img
                v-for="(pawn) in pawns"
                :id="'mini-pawn-'+pawn.id+'-minion-shrooms'" class="d-none"
                src="/img/staking-map/pawns/mini/minion-shrooms.png"
            />
            <img
                v-for="(pawn) in pawns"
                :id="'mini-pawn-'+pawn.id+'-minion-spooky'" class="d-none"
                src="/img/staking-map/pawns/mini/minion-spooky-30.png"
            />
            <img
                v-for="(pawn) in pawns"
                :id="'mini-pawn-'+pawn.id+'-minion-shores'" class="d-none"
                src="/img/staking-map/pawns/mini/minion-clouds-30.png"
            />
            <img
                v-for="(pawn) in pawns"
                :id="'mini-pawn-'+pawn.id+'-minion-volcano'" class="d-none"
                src="/img/staking-map/pawns/mini/minion-shores-30.png"
            />
            <img
                v-for="(pawn) in pawns"
                :id="'mini-pawn-'+pawn.id+'-minion-sweeties'" class="d-none"
                src="/img/staking-map/pawns/mini/minion-volcano-30.png"
            />

            <div class="bg-end-signpost"></div>
            <img id="work-icon" class="d-none" src="/img/staking-map/work_16.png"/>

            <div v-for="(m,id) in milestoneDays" :key="'m'+id" :class="'milestone mile-'+(id+1)">{{ m }}</div>

            <div v-for="(shrine) in milestones" :key="'sh'+shrine.id"
                 :class="'step-' + shrine.id" class="step d-flex flex-column"
            >
                <div
                    v-if="parseInt(shrine.id) === 43"
                    @mouseleave="spyglassUsed=false"
                    @mouseover="isShrineOpen(shrine) ? spyglassUsed=true : false"
                >
                    <reward-shrine-v2
                        :milestoneId="parseInt(shrine.id)"
                        :open="isShrineOpen(shrine)"
                        :shrine="shrine"
                        content-class="biome-7"
                    ></reward-shrine-v2>
                </div>
                <div v-else>
                    <reward-shrine-v2
                        :decimals=2
                        :milestoneId="parseInt(shrine.id)"
                        :open="isShrineOpen(shrine)"
                        :shrine="shrine"
                        content-class="biome-7"
                    ></reward-shrine-v2>
                </div>
            </div>

            <!--            Left as reminder for future shields-->
            <!--            <div class="step d-flex flex-column step-14 unknown-icon">-->
            <!--                <img src="/img/staking-map/biomes/shrooms/mystery-shrine-icon.svg" />-->
            <!--            </div>-->

        </div>
    </v-row>

</template>

<script>
import Price from "@/components/price";
import paper from "paper";
import RewardShrineV2 from "@/components/staking-map/reward-shrine-v2";

const MAP_ID = 6;

export default {
    components: {RewardShrineV2, Price},
    props: {
        pawns: Array,
        shrineDetails: Object,
    },
    data: function () {
        return {
            currentSize: 'm',
            rewardPopup: {
                1: false,
                2: false,
                3: false,
                4: false,
                5: false,
                6: false,
            },
            spyglassUsed: false,
            pawnDetails: []
        };
    },
    created() {
        if (this.isWindowSizeSm()) {
            this.currentSize = 's';
        }

        if (this.isWindowSizeXXSm()) {
            this.currentSize = 'xs';
        }

        window.addEventListener("resize", this.resizeWindowHandler);
    },
    destroyed() {
        window.removeEventListener("resize", this.resizeWindowHandler);
    },
    mounted: function () {
        this.initCanvas();
    },

    methods: {
        resizeWindowHandler() {
            let newSize = null;
            if (this.windowWidth() <= 520) {
                newSize = 'xs'
            } else if (this.windowWidth() <= 959) {
                newSize = 's'
            } else {
                newSize = 'm'
            }
            if (newSize !== this.currentSize) {
                this.currentSize = newSize;
                this.initCanvas();
            }
        },
        windowWidth() {
            return window.innerWidth;
        },
        isWindowSizeSm() {
            return this.windowWidth() <= 959;
        },
        isWindowSizeXXSm() {
            return this.windowWidth() <= 520;
        },
        isOnStartingPosition(day) {
            return day < 1;
        },
        drawCanvas(pawns, _MAP_ID, mapPathSvg, mobileMapPathSvg, mapPathPosition, mobileMapPathPosition, xsMobileMapPathPosition, pathCorrectionEmptySteps, traveledPathFillColor) {
            let canvas = new paper.PaperScope();
            canvas.setup('canvas');

            let mapScale = 1;
            if ('s' === this.currentSize || 'xs' === this.currentSize) {
                mapPathSvg = mobileMapPathSvg;
                mapPathPosition = mobileMapPathPosition;
            }

            if ('xs' === this.currentSize) {
                mapScale = 0.43;
                mapPathPosition = xsMobileMapPathPosition;
            }

            const pawnPathSegment = (_map, _pawn, _pathCorrectionEmptySteps) => {
                return _map.children[_pawn.offsetDayOnActiveMap + _pathCorrectionEmptySteps] || _map.children[_map.children.length - 1];
            }

            canvas.paper.project.importSVG(mapPathSvg, (map) => {
                map.position = new paper.Point(mapPathPosition.x, mapPathPosition.y);
                map.scale(mapScale);

                // Pawns
                this.pawnDetails = [];
                if (pawns.length) {
                    // Move pawns
                    const pawnCorrectionY = 2;

                    for (const pawn of pawns) {
                        if (_MAP_ID !== pawn.activeMap) {  //Display pawns only for this map
                            continue;
                        }
                        const currentPathSegment = pawnPathSegment(map, pawn, pathCorrectionEmptySteps);
                        let pawnImg = new paper.Raster(pawn.fullId);
                        pawnImg.position = currentPathSegment.position;
                        pawnImg.opacity = 0;
                        this.pawnDetails.push(
                            {
                                position: pawnImg.position,
                                height: pawnImg.height,
                                width: pawnImg.width,
                                ...pawn
                            })

                        let pawnText = new paper.PointText(new paper.Point(30, 30));
                        pawnText.content = '+' + pawn.size;
                        pawnText.fontSize = 14;
                        pawnText.fillColor = '#fff';
                        pawnText.position = currentPathSegment.position;
                        pawnText.position.x += 8;
                        pawnText.position.y -= 12 + pawnCorrectionY;
                        pawnText.opacity = 0;

                        let workIcon = new paper.Raster('work-icon');
                        workIcon.position = currentPathSegment.position;
                        workIcon.position.x -= 13;
                        workIcon.position.y -= 13 + pawnCorrectionY;
                        workIcon.size = 16;
                        workIcon.opacity = 0;

                        let minionDesert = new paper.Raster('mini-' + pawn.fullId + '-minion-desert')
                        minionDesert.position = currentPathSegment.position;
                        minionDesert.position.x -= (minionDesert.width / 2) - 5;
                        minionDesert.position.y -= 35 + pawnCorrectionY;
                        minionDesert.opacity = 0;

                        let minionShrooms = new paper.Raster('mini-' + pawn.fullId + '-minion-shrooms')
                        minionShrooms.position = currentPathSegment.position;
                        minionShrooms.position.x -= (minionShrooms.width / 2) - 33;
                        minionShrooms.position.y -= 35 + pawnCorrectionY;
                        minionShrooms.opacity = 0;

                        let minionSpooky = new paper.Raster('mini-' + pawn.fullId + '-minion-spooky')
                        minionSpooky.position = currentPathSegment.position;
                        minionSpooky.position.x -= -(minionSpooky.width * 1.6);
                        minionSpooky.position.y -= pawnImg.height - 30;
                        minionSpooky.opacity = 0;

                        let minionShores = new paper.Raster('mini-' + pawn.fullId + '-minion-shores')
                        minionShores.position = currentPathSegment.position;
                        minionShores.position.x -= minionShores.width * 1.6;
                        minionShores.position.y -= pawnImg.height - 15;
                        minionShores.opacity = 0;

                        let minionVolcano = new paper.Raster('mini-' + pawn.fullId + '-minion-volcano')
                        minionVolcano.position = currentPathSegment.position;
                        minionVolcano.position.x -= 0;
                        minionVolcano.position.y -= pawnImg.height;
                        minionVolcano.opacity = 0;
                        minionShores.opacity = 0;

                        let minionSweeties = new paper.Raster('mini-' + pawn.fullId + '-minion-sweeties')
                        minionSweeties.position = currentPathSegment.position;
                        minionSweeties.position.x += pawnImg.width / 2;
                        minionSweeties.position.y -= pawnImg.height / 2;
                        minionSweeties.opacity = 0;

                        setTimeout(() => {
                            if (pawnImg.height > 0) {
                                pawnImg.position.y -= pawnImg.height / 2 + pawnCorrectionY;
                                pawnImg.opacity = 1;
                                pawnText.opacity = 1;
                                workIcon.opacity = 1;
                                minionDesert.opacity = pawn.activeBonuses[0] ? 1 : 0;
                                minionShrooms.opacity = pawn.activeBonuses[1] ? 1 : 0;
                                minionSpooky.opacity = pawn.activeBonuses[2] ? 1 : 0;
                                minionShores.opacity = pawn.activeBonuses[3] ? 1 : 0;
                                minionVolcano.opacity = pawn.activeBonuses[4] ? 1 : 0;
                                minionSweeties.opacity = pawn.activeBonuses[5] ? 1 : 0;
                            }
                        }, 250);

                        // Color pawn's position
                        const pathSegment = pawnPathSegment(map, pawn, pathCorrectionEmptySteps);
                        pathSegment.fillColor = traveledPathFillColor;
                    }

                    // Color path walked
                    for (let i = 1; i <= Math.min(this.maxDay + pathCorrectionEmptySteps, map.children.length - 1); i++) {
                        map.children[i].fillColor = '#000';
                    }
                }
            });
        },
        initCanvas() {
            this.drawCanvas(this.pawns, MAP_ID, '/img/staking-map/biomes/sweeties/map-path.svg', '/img/staking-map/biomes/sweeties/map-path-mobile.svg',
                {x: 500, y: 330}, {x: 272, y: 950}, {x: 125, y: 400}, 3, 'grey'
            );
        },
        isShrineOpen(shrine) {
            return this.maxDay >= shrine.day;
        },
        getPawnDetailsFrameStyle(position, height, width) {
            let style = "";
            style += `height: ${height}px;`
            style += `width: ${width}px;`
            style += `top: ${position.y - height}px;`;
            style += `left: ${position.x - width / 2}px;`;
            return style
        }
    },
    computed: {
        milestones() {
            return Object.values(this.shrineDetails.milestones);
        },
        milestoneDays() {
            return this.milestones.map(v => v.day);
        },
        maxDay() {
            const daysOnMap = this.pawns.filter(a => a.activeMap === MAP_ID).map((a) => a.offsetDayOnActiveMap);
            const daysOfVeterans = this.pawns.filter(a => !!Number(a.activeBonuses[MAP_ID])).map((a) => a.day);
            return Math.max(...daysOnMap, ...daysOfVeterans);
        },
    },
    watch: {
        pawns(newPawns, oldPawns) {
            if (oldPawns.length === newPawns.length) {
                //do nothing
            } else {
                this.initCanvas();
            }
        }
    }
}
</script>

<style lang="scss" scoped>
#map {
    width: 1000px;
    height: 600px;
    position: relative;
    margin: auto;
}

#canvas {
    width: 1000px;
    height: 600px;
    position: relative;
    z-index: 10;
}

#map-img {
    width: 1000px;
    height: 600px;
    position: absolute;
    top: 0;
    left: 0;
    background: url('/img/staking-map/biomes/sweeties/map-big.png') 0 0 no-repeat;

    &.nextMapPreviewMap {
        background: url('/img/staking-map/biomes/desert/map-big.png') 0 0 no-repeat;
    }
}

.work-icon {
    width: auto;
    height: 16px;
}

.pawn-details-container {
    z-index: 15;
    position: absolute;
    height: 50px;
    width: 50px;
}

.bg-end-signpost {
    width: 119px;
    height: 74px;
    background: url('/img/staking-map/biomes/sweeties/css_sprites_2.png') -233px -193px;
    position: absolute;
    top: 120px;
    right: 50px;
}

$milestone-top-offset: 100px;
$milestone-left-offset: 20px;

.milestone {
    top: 0;
    left: 0;
    position: absolute;
    margin: 0;
    padding: 0;
    color: black;
    font-weight: bold;

    &.mile-1 {
        top: 404px;
        left: 138px;
    }

    &.mile-2 {
        top: 180px;
        left: 291px;
    }

    &.mile-3 {
        top: 402px;
        left: 417px;
    }

    &.mile-4 {
        top: 163px;
        left: 531px;
    }

    &.mile-5 {
        top: 418px;
        left: 615px;
    }

    &.mile-6 {
        top: 306px;
        left: 810px;
    }
}

$shrine-height: 117px;
$shrine-width: 96px;
$shrine-top-offset: 0px;

.step {
    top: 0;
    left: 0;
    position: absolute;
    margin: 0;
    padding: 0;

    &.step-39 {
        top: 450px;
        left: 90px;
    }

    &.step-40 {
        top: 218px;
        left: 220px;
    }

    &.step-41 {
        top: 444px;
        left: 358px;
    }

    &.step-42 {
        top: 208px;
        left: 510px;
    }

    &.step-43 {
        top: 440px;
        left: 555px;
    }

    &.step-44 {
        top: 225px;
        left: 820px;
    }

    &.unknown-icon {
        padding: 20px 0 0 20px;

        img {
            width: 80px;
        }
    }
}

@media only screen and (max-width: $sm) {
    #map {
        width: 556px;
        height: 2166px;
    }

    #canvas {
        width: 556px;
        height: 2166px;
    }

    #map-img {
        width: 556px;
        height: 2166px;
        background: url('/img/staking-map/biomes/sweeties/map-mobile.png') 0 0 no-repeat;

        &.nextMapPreviewMap {
            background: url('/img/staking-map/biomes/desert/map-mobile.png') 0 0 no-repeat;
        }
    }

    .bg-end-signpost {
        top: auto;
        bottom: 680px;
        right: auto;
        left: 200px;
    }

    .milestone {
        font-size: 30px;

        &.mile-1 {
            top: 508px;
            left: 500px;
        }

        &.mile-2 {
            top: 610px;
            left: 60px;
        }

        &.mile-3 {
            top: 905px;
            left: 185px;
        }

        &.mile-4 {
            top: 1170px;
            left: 500px;
        }

        &.mile-5 {
            top: 1275px;
            left: 62px;
            color: #fff;
        }

        &.mile-6 {
            top: 1575px;
            left: 185px;
            color: #000;
        }
    }

    .step {
        &.step-39 {
            top: calc(430px);
            left: calc(320px);
        }

        &.step-40 {
            top: calc(690px);
            left: calc(70px);
        }

        &.step-41 {
            top: calc(990px);
            left: calc(150px);
        }

        &.step-42 {
            top: calc(1100px);
            left: calc(320px);
        }

        &.step-43 {
            top: 1350px;
            left: 80px;
        }

        &.step-44 {
            top: calc(1670px);
            left: calc(120px);
        }

        &.unknown-icon {
            img {
                width: 80px;
            }
        }
    }
}

@media only screen and (max-width: 520px) {
    #map {
        width: 250px;
        height: 850px;
    }

    #canvas {
        width: 250px;
        height: 820px;
    }

    #map-img {
        width: 250px;
        height: 820px;
        background: url('/img/staking-map/biomes/sweeties/map-mobile-xs.png') 0 0 no-repeat;

        &.nextMapPreviewMap {
            background: url('/img/staking-map/biomes/desert/map-mobile-xs.png') 0 0 no-repeat;
            background-size: 100% 100%;
        }
    }

    .bg-end-signpost {
        display: none;
    }

    .milestone {
        font-size: 30px;
        display: none;
    }

    .step {
        &.step-39 {
            top: calc(150px);
            left: calc(120px);
        }

        &.step-40 {
            top: calc(270px);
            left: calc(50px);
        }

        &.step-41 {
            top: calc(400px);
            left: calc(30px);
        }

        &.step-42 {
            top: calc(450px);
            left: calc(120px);
        }

        &.step-43 {
            top: 570px;
            left: 40px;
        }

        &.step-44 {
            top: 700px;
            left: 50px;
        }

        &.unknown-icon {
            img {
                width: 50px;
            }
        }
    }
}
</style>
