<template>
    <div class="select-dialog">
        <v-dialog
            v-model="isOpen"
            scrollable
            width="600"
            max-width="96%"
            :content-class="contentClass"
        >
            <v-card class="dialog-box-decorated-edges-mini">
                <div class="dialog-box-decorated-edges-close-btn-mini" @click="closeBtnClick()"></div>
                <v-card-title class="title">
                    <div class="ml-md--2" :class="'bg-'+shrine.headerClass"></div>
                    <span class="ml-md-4 title-text">{{ title }}</span>
                    <span class="ml-auto mr-1 title-day">{{ day }} day</span>
                </v-card-title>

                <v-card-text class="rewards mt-2 pt-6 text-center" v-if="loadingForSuccess">
                    <div class="progress-container">
                        <v-progress-circular
                            indeterminate
                            v-if="loadingForSuccess"
                            :size="150"
                            color="amber"
                        ></v-progress-circular>
                    </div>
                </v-card-text>

                <v-card-text class="rewards mt-2 pt-6 pa-2" v-if="!loadingForSuccess && !isSuccessInformationView">
                    <v-tabs v-model="rewardTab" grow background-color="transparent"
                            class="tabs-names"
                            :class="!shrine.rewardTabs?'tabs-hidden':''"
                    >
                        <v-tab v-for="(tabs, id) in shrine.rewards" :key="'t'+id" class="tab-name">
                            <icon-element v-if="tabs.icon" :element="tabs.icon" inline></icon-element>
                            {{ tabs.name }}
                        </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="rewardTab" class="tabs-content">
                        <v-tab-item v-for="(tabs, tabId) in shrine.rewards" :key="'r'+tabId">
                            <v-row class="reward ml-0 mt-0" v-for="(rew, id) in tabs.items" :key="'rr'+id"
                                   :no-gutters="$vuetify.breakpoint.xsOnly">
                                <v-col cols="3" sm="2" class="pa-0 text-center pt-2">
                                    <div class="reward-img" :class="'bg-'+rew.imgClass"></div>
                                </v-col>
                                <v-col cols="9" sm="7">
                                    <h3>{{ rew.name }}</h3>
                                    <div class="mt-2">{{ rew.description }}</div>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <div class="reward-cost">
                                        {{ rew.cost }} <img class="work-icon" src="/img/staking-map/work.svg"/>
                                    </div>
                                    <div
                                        v-if="!isWalletConnected && ((8 === shrine.id && 5 === rew.rewardId) || (14 === shrine.id && 3 === rew.rewardId))">
                                        <span class="text--primary">
                                            <center>
                                            Connect wallet to see remaining amount
                                            </center>
                                        </span>
                                    </div>
                                    <div v-else-if="limitedRewards[rew.rewardId] && limitedRewards[rew.rewardId].max>0">
                                        <div class="my-2">
                                            <span>Available:
                                                {{ limitedRewards[rew.rewardId].remaining }}
                                                /
                                                {{ limitedRewards[rew.rewardId].max }}
                                            </span>
                                            <v-progress-linear
                                                :color="availableRewardsCounterColor(limitedRewards[rew.rewardId].remaining)"
                                                :value="limitedRewards[rew.rewardId].remaining/limitedRewards[rew.rewardId].max*100"></v-progress-linear>
                                        </div>

                                        <input-amount
                                            v-if="limitedRewards[rew.rewardId].remaining>0"
                                            :id="rew.rewardId"
                                            allowZeroValue
                                            :init-value="selectedAmounts[rew.rewardId]"
                                            v-on:valueChanged="valueChanged"
                                            :max-value="Math.min(calculateMaxValue(rew.rewardId, rew),limitedRewards[rew.rewardId].remaining)"
                                        ></input-amount>
                                    </div>

                                    <input-amount
                                        v-else
                                        :id="rew.rewardId"
                                        allowZeroValue
                                        :init-value="selectedAmounts[rew.rewardId]"
                                        v-on:valueChanged="valueChanged"
                                        :max-value="calculateMaxValue(rew.rewardId, rew)"
                                    ></input-amount>

                                </v-col>
                            </v-row>

                        </v-tab-item>
                    </v-tabs-items>
                </v-card-text>

                <v-card-text class="rewards mt-2 pt-6 text-center" v-if="isSuccessInformationView">
                    <h3 class="mb-4 text-none">You have received:</h3>
                    <div v-for="row in Object.entries(this.selectedAmounts)" v-if="row[1] > 0">
                        <span class="m-0 p-0 d-flex align-center" style="font-size: 120%">
                            {{ row[1] }}x
                            <div class="ml-2 mr-2" :class="'bg-'+rewardObjectByRewardId(row[0]).imgClass"></div>
                            {{ rewardObjectByRewardId(row[0]).name }}
                        </span>
                    </div>
                    <br/>
                    You can view them in:
                    <br/>
                    <v-btn v-if="isEnhancementType" :to="{ name: 'MyEnhancements', params: {}}">Bottomless Bag</v-btn>
                    <v-btn v-if="isCardNftType" :to="{ name: 'MyCards', params: {}}">My Cards</v-btn>
                </v-card-text>

                <v-card-actions class="card-action">
                    <span class="action-text">
<!--                        You have <b>{{ pointsAmount }}.{{ pointsDecimals }}</b>-->
                        You have <b>{{ pointsAmount }}</b>
                        <img class="work-icon ml-1" style="margin-top:-3px" src="/img/staking-map/work.svg"/>
                        remaining at this milestone
                    </span>

                    <v-btn class="btn-get"
                           :disabled="!isCostWithinPoints || 0===sumAllChosenRewards"
                           @click="requestReward()"
                    >
                        Get for
                        -<span :class="!isCostWithinPoints?'red--text':''">{{ sumAllChosenRewards }}</span>
                        <img class="work-icon ml-1" src="/img/staking-map/work.svg"/>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import InputAmount from "../input-amount";
import Vue from "vue";
import IconElement from "../icon-element";
import {MilestoneType, StakingMapMilestoneFactory} from "../../services/contracts/stakingMapV2Contract";
import {UserWalletError} from "@/errors/WalletErrors";

export default {
    components: {IconElement, InputAmount},
    props: {
        milestoneId: Number,
        shrine: Object,
        model: Boolean,
        pointsAmount: {
            type: Number,
            default: 0,
        },
        pointsDecimals: {
            type: Number,
            default: 0,
        },
        rewardType: String,
        limitedRewards: Object,
        contentClass: String,
    },
    data() {
        return {
            selectedAmounts: {},
            loadingForSuccess: false,
            isSuccessInformationView: false,
            rewardTab: null,
        }
    },
    computed: {
        title() {
            return this.shrine.name;
        },
        day() {
            return this.shrine.day;
        },
        web3() {
            return this.$store.state.web3;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        },
        componentId() {
            return this.id || 'dialog';
        },
        isOpen: {
            get() {
                return this.model
            },
            set() {
                // close dialog event
                this.$emit('dialogClosed', {componentId: this.componentId})
            }
        },
        sumAllChosenRewards() {
            let sum = 0;
            this.shrine.rewards.forEach((tab, id) => {
                tab.items.forEach((item, id2) => {
                    sum += item.cost * (this.selectedAmounts[item.rewardId] || 0);
                });
            });

            return sum;
        },
        isCostWithinPoints() {
            return this.sumAllChosenRewards <= this.pointsAmount;
        },
        isEnhancementType() {
            return (new MilestoneType()).isEnhancement(this.rewardType);
        },
        isCardNftType() {
            return (new MilestoneType()).isCardNft(this.rewardType);
        }
    },
    methods: {
        availableRewardsCounterColor(remaining) {
            switch (true) {
                case remaining == 0:
                    return 'red';
                case remaining <= 10:
                    return 'secondary';
                default:
                    return 'green';
            }
        },
        closeBtnClick() {
            if (this.isSuccessInformationView) {
                window.location.reload();
            }
            this.isOpen = false;
        },
        calculateMaxValue(id, rew) {
            return (this.pointsAmount - this.sumAllChosenRewards + (this.selectedAmounts[id] ? (this.selectedAmounts[id] * rew.cost) : 0)) / rew.cost
        },
        valueChanged(obj) {
            Vue.set(this.selectedAmounts, obj.id, obj.value);
        },
        sumRewards(selectedId, cost) {
            return (this.selectedAmounts[selectedId] || 0) * cost;
        },
        rewardObjectByRewardId(rewardId) {
            let output = null;
            this.shrine.rewards.forEach((tab, id) => {
                tab.items.forEach((item, id2) => {
                    if (parseInt(item.rewardId) === parseInt(rewardId)) {
                        output = this.shrine.rewards[id].items[id2];
                    }
                });
            });

            return output;
        },
        requestReward() {
            const stakingMapMilestone = new StakingMapMilestoneFactory(this.rewardType);
            const stakingMapMilestoneContract = stakingMapMilestone.getContractClass(this.web3)
            this.loadingForSuccess = true;
            let rewardIdArray = [];
            let amountArray = [];
            Object.entries(this.selectedAmounts).forEach((row) => {
                const amount = parseInt(row[1]);
                if (amount > 0) {
                    rewardIdArray.push(parseInt(row[0]));
                    amountArray.push(amount);
                }
            })

            let rewardGetter = null;
            if (1 === rewardIdArray.length) {
                rewardGetter = stakingMapMilestoneContract.getSingleRewardAmountForMilestone(this.milestoneId, rewardIdArray[0], amountArray[0]);
            } else {
                rewardGetter = stakingMapMilestoneContract.getMultiRewardAmountForMilestone(this.milestoneId, rewardIdArray, amountArray);
            }

            rewardGetter
                .then((res) => {
                    this.isSuccessInformationView = true;
                })
                .catch((error) => {
                    if (error instanceof UserWalletError) {
                        this.flashMessage.show({
                            status: 'error',
                            message: 'Transaction rejected by the user'
                        });
                    } else {
                        if ('object' === typeof error) {
                            error = JSON.stringify(error);
                        }
                        console.error(error);
                        alert('An error occurred when loading Map Reward contract. Please try again later or contact Wizarre support.');
                    }
                })
                .finally(() => {
                    this.loadingForSuccess = false;
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.v-dialog .v-card .v-card__title {
    height: 90px;
    padding: 20px 30px;
}

.rewards {
    background: #2a1c33;

    h3 {
        line-height: 30px;
        text-transform: none;
    }

    .progress-container {
        min-height: 172px;
    }
}

.reward-cost {
    font-size: 30px;
    height: 39px;
    padding: 10px 0 0 0;
    text-align: center;
    color: #000;
    background: url('/img/staking-map/label-price.png') 50% 0 no-repeat;

    .work-icon {
        height: 20px;
    }
}

.reward-img {
    margin: auto;
}

.work-icon {
    width: auto;
    height: 16px;
}

.tabs-names {
    border-bottom: 2px solid var(--v-frames-base);

    .v-tab--active {
        background: var(--v-buttonBg-base);
    }

    .tab-name {
        color: #fff;
    }
}

.tabs-content {
    background: transparent !important;
}

.tabs-hidden {
    display: none !important;
}

.v-card__actions {
    white-space: pre;
    border-top: 2px solid var(--v-frames-base);
    padding: 20px 30px !important;
    background: #312441;

    .btn-get {
        margin: auto 0 auto auto;
    }
}

@media only screen and (max-width: $sm) {
    .v-dialog .v-card .v-card__title {
        height: 70px;
        padding: 10px 10px;
    }
    .title {
        position: relative;
    }
    .title-day {
        margin: 0 !important;
        position: absolute;
        bottom: -5px;
        right: 20%;
    }

    .action-text {
        margin: -6px 0 0 -20px;
        height: 60px;
        display: block;
    }

    .card-action {
        position: relative;

        .btn-get {
            position: absolute;
            bottom: 10%;
            right: 10%;
        }
    }
}

@media only screen and (max-width: $xs) {
    .reward-cost {
        width: 70px;
        margin: 0;
        position: absolute;
        right: 5%;
    }
}
</style>
