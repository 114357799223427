<template>
    <div class="select-dialog">
        <v-dialog
            v-model="isOpen"
            width="600"
        >
            <v-card class="box-decorated-edges-mini">
                <div class="close-btn pull-right" @click="isOpen=false">
                    <v-icon>mdi-close-box</v-icon>
                </div>
                <v-card-title class="title justify-space-between">
                    <span>Reduce stake #{{ id }}</span>
                </v-card-title>

                <v-card-text class="rewards mt-2 pt-6">
                    <v-row no-gutters>
                        <v-col cols=4 md=3>
                            <img :src="image" class="" style="height:105px"/>
                        </v-col>
                        <v-col cols=8 md=5>
                            <p class="">
                                Return selected amount of SCRL and reduce size of Caravan on the map.
                            </p>
                            <div class="d-flex flex-row pos-relative mt-6">
                                <input-amount
                                    :init-value="initialAmountToReduce"
                                    v-on:valueChanged="valueChanged"
                                    :max-value="stakeAmount"
                                ></input-amount>
                                <v-btn x-small
                                       style="position: absolute;bottom:3px;left:0;width: 200px"
                                       @click="initialAmountToReduce = stakeAmount"
                                >
                                    Max
                                </v-btn>
                            </div>
                        </v-col>
                        <v-col cols=12 md=4>
                            <price class="mt-8 pull-right" :price="stakesPrice"></price>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-btn
                        class="pull-right ml-auto mr-5"
                        @click="requestReduce()"
                        :disabled="amountToReduce>stakeAmount || amountToReduce <= 0"
                        :loading="loadingData"
                    >
                        Reduce
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Price from "../price";
import InputAmount from "../input-amount";
import {StakingMapV2Contract} from "../../services/contracts/stakingMapV2Contract";

const SINGLE_STAKE_TOKEN_VALUE = 100000;

export default {
    components: {Price, InputAmount},
    props: {
        id: Number,
        model: Boolean,
        image: String,
        stakeAmount: {
            type: Number,
        },
    },
    data() {
        return {
            loadingData: false,
            initialAmountToReduce: this.stakeAmount,
            amountToReduce: 0,
        }
    },
    computed: {
        web3() {
            return this.$store.state.web3;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        },
        componentId() {
            return this.id || 'dialog';
        },
        isOpen: {
            get() {
                return this.model
            },
            set() {
                // close dialog event
                this.$emit('dialogClosed', {componentId: this.componentId})
            }
        },
        stakesPrice() {
            return BigInt(this.amountToReduce) * BigInt(SINGLE_STAKE_TOKEN_VALUE);
        },
    },
    methods: {
        valueChanged(obj) {
            this.amountToReduce = obj.value;
        },
        requestReduce() {
            this.loadingData = true;
            const stakingMapContract = new StakingMapV2Contract(this.web3);
            stakingMapContract.reduceStakeStakingMapV2(this.id, this.amountToReduce)
                .then(() => {
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                })
                .finally(() => {
                    this.loadingData = false;
                    this.isOpen = false;
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.v-dialog .v-card .v-card__title {
    height: 50px;
}

.rewards {
    background: #3C0851;
}

</style>
