var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-row", [
    _c(
      "div",
      {
        staticClass: "biome-2",
        class: { nextMapPreview: _vm.spyglassUsed },
        attrs: { id: "map" },
      },
      [
        _c("canvas", { attrs: { id: "canvas", "data-paper-hidpi": "off" } }),
        _vm._l(_vm.pawnDetails, function (pawn) {
          return _c(
            "v-tooltip",
            {
              key: pawn.id,
              attrs: { "z-index": "1000", top: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(
                          "div",
                          _vm._g(
                            _vm._b(
                              {
                                staticClass: "pawn-details-container",
                                style: _vm.getPawnDetailsFrameStyle(
                                  pawn.position,
                                  pawn.height,
                                  pawn.width
                                ),
                              },
                              "div",
                              attrs,
                              false
                            ),
                            on
                          )
                        ),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            },
            [
              _c("div", { staticClass: "pawn-details-tooltip" }, [
                _vm._v(" Caravan Details: "),
                _c("ul", [
                  _c("li", [
                    _vm._v(
                      "On map: " + _vm._s(pawn.offsetDayOnActiveMap) + " days"
                    ),
                  ]),
                  _c("li", [
                    _vm._v("Work points: " + _vm._s(pawn.size) + " "),
                    _c("img", {
                      staticClass: "work-icon",
                      attrs: { src: "/img/staking-map/work_16.png" },
                    }),
                  ]),
                  pawn.activeBonuses[0]
                    ? _c("li", [
                        _vm._v("Veteran: Desert "),
                        _c("img", {
                          staticClass: "work-icon",
                          attrs: {
                            src: "/img/staking-map/pawns/mini/minion-desert.png",
                          },
                        }),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]
          )
        }),
        _c("div", {
          class: { nextMapPreviewMap: _vm.spyglassUsed },
          attrs: { id: "map-img" },
        }),
        _vm._l(_vm.pawns, function (pawn) {
          return _c("img", {
            staticClass: "d-none",
            attrs: { id: "pawn-" + pawn.id, src: pawn.img },
          })
        }),
        _vm._l(_vm.pawns, function (pawn) {
          return _c("img", {
            staticClass: "d-none",
            attrs: { id: "mini-pawn-" + pawn.id, src: pawn.imgMini },
          })
        }),
        _vm._l(_vm.pawns, function (pawn) {
          return _c("img", {
            staticClass: "d-none",
            attrs: {
              id: "mini-pawn-" + pawn.id + "-minion-desert",
              src: "/img/staking-map/pawns/mini/minion-desert.png",
            },
          })
        }),
        _c("div", { staticClass: "bg-end-signpost" }),
        _c("img", {
          staticClass: "d-none",
          attrs: { id: "work-icon", src: "/img/staking-map/work_16.png" },
        }),
        _c("img", {
          staticClass: "d-none",
          attrs: { id: "start-stand", src: "/img/staking-map/start-stand.png" },
        }),
        _vm._l(_vm.milestoneDays, function (m, id) {
          return _c(
            "div",
            { key: "m" + id, class: "milestone mile-" + (id + 1) },
            [_vm._v(_vm._s(m))]
          )
        }),
        _vm._l(_vm.milestones, function (shrine) {
          return _c(
            "div",
            {
              key: "sh" + shrine.id,
              staticClass: "step d-flex flex-column",
              class: "step-" + shrine.id,
            },
            [
              parseInt(shrine.id) === 13
                ? _c(
                    "div",
                    {
                      on: {
                        mouseover: function ($event) {
                          _vm.isShrineOpen(shrine)
                            ? (_vm.spyglassUsed = true)
                            : null
                        },
                        mouseleave: function ($event) {
                          _vm.spyglassUsed = false
                        },
                      },
                    },
                    [
                      _c("reward-shrine-v2", {
                        attrs: {
                          milestoneId: parseInt(shrine.id),
                          shrine: shrine,
                          open: _vm.isShrineOpen(shrine),
                          "content-class": "biome-2",
                        },
                      }),
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c("reward-shrine-v2", {
                        attrs: {
                          milestoneId: parseInt(shrine.id),
                          shrine: shrine,
                          open: _vm.isShrineOpen(shrine),
                          "content-class": "biome-2",
                          decimals: 2,
                          "limited-reward-id":
                            Number(shrine.id) === 14 ? 3 : undefined,
                        },
                      }),
                    ],
                    1
                  ),
            ]
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }