<template>
    <v-row>
        <div id="map" :class="{'nextMapPreview':spyglassUsed}" class="biome-2">
            <canvas id="canvas" data-paper-hidpi="off" />
            <v-tooltip v-for="pawn in pawnDetails" :key="pawn.id"
                       z-index="1000"
                       top
            >
                <template v-slot:activator="{ on, attrs }">
                    <div :style="getPawnDetailsFrameStyle(pawn.position, pawn.height, pawn.width)"
                         class="pawn-details-container" v-bind="attrs"
                         v-on="on">
                    </div>
                </template>
                <div class="pawn-details-tooltip">
                    Caravan Details:
                    <ul>
                        <li>On map: {{ pawn.offsetDayOnActiveMap }} days</li>
                        <li>Work points: {{ pawn.size }} <img class="work-icon" src="/img/staking-map/work_16.png"/>
                        </li>
                        <li v-if="pawn.activeBonuses[0]">Veteran: Desert
                            <img class="work-icon"
                                 src="/img/staking-map/pawns/mini/minion-desert.png"/>
                        </li>
                    </ul>
                </div>
            </v-tooltip>

            <div id="map-img" :class="{'nextMapPreviewMap':spyglassUsed}"/>
            <img
                v-for="(pawn) in pawns"
                :id="'pawn-'+pawn.id" class="d-none"
                :src="pawn.img"
            />
            <img
                v-for="(pawn) in pawns"
                :id="'mini-pawn-'+pawn.id" class="d-none"
                :src="pawn.imgMini"
            />
            <img
                v-for="(pawn) in pawns"
                :id="'mini-pawn-'+pawn.id+'-minion-desert'" class="d-none"
                src="/img/staking-map/pawns/mini/minion-desert.png"
            />

            <div class="bg-end-signpost"></div>

            <img id="work-icon" class="d-none" src="/img/staking-map/work_16.png"/>

            <img id="start-stand" src="/img/staking-map/start-stand.png" class="d-none"/>

            <div :class="'milestone mile-'+(id+1)" v-for="(m,id) in milestoneDays" :key="'m'+id">{{ m }}</div>

            <div v-for="(shrine) in milestones" :key="'sh'+shrine.id"
                 class="step d-flex flex-column" :class="'step-' + shrine.id"
            >
                <div
                    v-if="parseInt(shrine.id) === 13"
                    @mouseover="isShrineOpen(shrine) ? spyglassUsed=true : null"
                    @mouseleave="spyglassUsed=false"
                >
                    <reward-shrine-v2
                        :milestoneId="parseInt(shrine.id)"
                        :shrine="shrine"
                        :open="isShrineOpen(shrine)"
                        content-class="biome-2"
                    ></reward-shrine-v2>
                </div>
                <div v-else>
                    <reward-shrine-v2
                        :milestoneId="parseInt(shrine.id)"
                        :shrine="shrine"
                        :open="isShrineOpen(shrine)"
                        content-class="biome-2"
                        :decimals=2
                        :limited-reward-id="Number(shrine.id) === 14 ? 3 : undefined"
                    ></reward-shrine-v2>
                </div>
            </div>

            <!--            Left as reminder for future shields-->
            <!--            <div class="step d-flex flex-column step-14 unknown-icon">-->
            <!--                <img src="/img/staking-map/biomes/shrooms/mystery-shrine-icon.svg" />-->
            <!--            </div>-->

        </div>
    </v-row>

</template>

<script>
import Price from "@/components/price";
import paper from "paper";
import RewardShrineV2 from "@/components/staking-map/reward-shrine-v2";

const MAP_ID = 1;

export default {
    components: {RewardShrineV2, Price},
    props: {
        pawns: Array,
        shrineDetails: Object
    },
    data: function () {
        return {
            currentSize: 'm',
            rewardPopup: {
                1: false,
                2: false,
                3: false,
                4: false,
                5: false,
                6: false,
            },
            spyglassUsed: false,
            pawnDetails: []
        };
    },
    created() {
        if (this.isWindowSizeSm()) {
            this.currentSize = 's';
        }

        if (this.isWindowSizeXXSm()) {
            this.currentSize = 'xs';
        }

        window.addEventListener("resize", this.resizeWindowHandler);
    },
    destroyed() {
        window.removeEventListener("resize", this.resizeWindowHandler);
    },
    mounted: function () {
        this.initCanvas();
    },

    methods: {
        resizeWindowHandler() {
            let newSize = null;
            if (this.windowWidth() <= 520) {
                newSize = 'xs'
            } else if (this.windowWidth() <= 959) {
                newSize = 's'
            } else {
                newSize = 'm'
            }
            if (newSize !== this.currentSize) {
                this.currentSize = newSize;
                this.initCanvas();
            }
        },
        windowWidth() {
            return window.innerWidth;
        },
        isWindowSizeSm() {
            return this.windowWidth() <= 959; //TODO: export number to global var
        },
        isWindowSizeXXSm() {
            return this.windowWidth() <= 520; //TODO: export number to global var
        },
        isOnStartingPosition(day) {
            return day < 1;
        },
        initCanvas: function () {
            let canvas = new paper.PaperScope();
            canvas.setup('canvas');

            let mapPathSvg = '/img/staking-map/biomes/shrooms/map-path.svg';
            let mapPathPosition = {x: 500, y: 330};
            let pawnStartPosition = new paper.Point(140, 200);
            let mapScale = 1;
            if ('s' === this.currentSize || 'xs' === this.currentSize) {
                mapPathSvg = '/img/staking-map/biomes/shrooms/map-path-mobile.svg';
                mapPathPosition = {x: 272, y: 1000};
                pawnStartPosition = new paper.Point(70, 200);
            }

            if ('xs' === this.currentSize) {
                mapScale = 0.43;
                mapPathPosition = {x: 125, y: 440};
                pawnStartPosition = new paper.Point(50, 120);
            }

            canvas.paper.project.importSVG(mapPathSvg, (map) => {
                map.position = new paper.Point(mapPathPosition.x, mapPathPosition.y);
                map.scale(mapScale);

                // Pawns
                this.pawnDetails = [];
                if (this.pawns.length) {
                    // Move pawns
                    const posCorrection = 0;
                    const pawnCorrectionY = 2;
                    for (const pawn of this.pawns) {
                        if (MAP_ID !== pawn.activeMap) {  //Display pawns only for this map
                            continue;
                        }
                        const currentPathSegment = map.children[pawn.offsetDayOnActiveMap + posCorrection] || map.children[map.children.length - 1];
                        let pawnImg = new paper.Raster(pawn.fullId);
                        pawnImg.position = currentPathSegment.position;

                        this.pawnDetails.push(
                            {
                                position: pawnImg.position,
                                height: pawnImg.height,
                                width: pawnImg.width,
                                ...pawn
                            })

                        //1st day
                        if (this.isOnStartingPosition(pawn.offsetDayOnActiveMap)) {
                            let startStand = new paper.Raster('start-stand');
                            startStand.position = pawnStartPosition;
                            pawnImg = new paper.Raster('mini-' + pawn.fullId);
                            pawnImg.position = pawnStartPosition;
                        }
                        pawnImg.opacity = 0;

                        let pawnText = new paper.PointText(new paper.Point(30, 30));
                        pawnText.content = '+' + pawn.size;
                        pawnText.fontSize = 14;
                        pawnText.fillColor = '#fff';
                        pawnText.position = currentPathSegment.position;
                        pawnText.position.x += 8;
                        pawnText.position.y -= 12 + pawnCorrectionY;
                        pawnText.opacity = 0;

                        let workIcon = new paper.Raster('work-icon');
                        workIcon.position = currentPathSegment.position;
                        workIcon.position.x -= 13;
                        workIcon.position.y -= 13 + pawnCorrectionY;
                        workIcon.size = 16;
                        workIcon.opacity = 0;

                        let minionDesert = new paper.Raster('mini-' + pawn.fullId + '-minion-desert')
                        minionDesert.position = currentPathSegment.position;
                        minionDesert.position.x -= (minionDesert.width / 2) - 3;
                        minionDesert.position.y -= 35 + pawnCorrectionY;
                        minionDesert.opacity = 0;

                        setTimeout(() => {
                            if (pawnImg.height > 0) {
                                pawnImg.position.y -= pawnImg.height / 2 + pawnCorrectionY;
                                pawnImg.opacity = 1;
                                pawnText.opacity = 1;
                                workIcon.opacity = 1;
                                minionDesert.opacity = pawn.activeBonuses[0] ? 1 : 0;

                                if (this.isOnStartingPosition(pawn.offsetDayOnActiveMap)) {
                                    pawnText.opacity = 0;
                                    workIcon.opacity = 0;
                                    minionDesert.opacity = 0
                                }
                            }
                        }, 100);

                        // Color pawn's position
                        const pathSegment = map.children[pawn.offsetDayOnActiveMap + posCorrection] || map.children[map.children.length - 1];
                        pathSegment.fillColor = 'purple';
                    }

                    // Color path walked
                    for (let i = 1; i <= Math.min(this.maxDay, map.children.length - 1); i++) {
                        map.children[i].fillColor = '#000';
                    }
                }
            });
        },
        isShrineOpen(shrine) {
            return this.maxDay >= shrine.day;
        },
        getPawnDetailsFrameStyle(position, height, width) {
            let style = "";
            style += `height: ${height}px;`
            style += `width: ${width}px;`
            style += `top: ${position.y - height}px;`;
            style += `left: ${position.x - width / 2}px;`;
            return style
        }
    },
    computed: {
        milestones() {
            return Object.values(this.shrineDetails.milestones);
        },
        milestoneDays() {
            return this.milestones.map(v => v.day);
        },
        maxDay() {
            //TODO: get max days from contract (all stakes)?
            const daysOnMap = this.pawns.filter(a => a.activeMap === MAP_ID).map((a) => a.offsetDayOnActiveMap);
            const daysOfVeterans = this.pawns.filter(a => !!Number(a.activeBonuses[MAP_ID])).map((a) => a.day);
            return Math.max(...daysOnMap, ...daysOfVeterans);
        },
    },
    watch: {
        pawns(newPawns, oldPawns) {
            if (oldPawns.length === newPawns.length) {
                //do nothing
            } else {
                this.initCanvas();
            }
        }
    }
}
</script>

<style lang="scss" scoped>
#map {
    width: 1000px;
    height: 600px;
    position: relative;
    margin: auto;
}

#canvas {
    width: 1000px;
    height: 600px;
    position: relative;
    z-index: 10;
}

#map-img {
    width: 1000px;
    height: 600px;
    position: absolute;
    top: 0;
    left: 0;
    background: url('/img/staking-map/biomes/shrooms/map-big.png') 0 0 no-repeat;

    &.nextMapPreviewMap {
        background: url('/img/staking-map/biomes/spooky/map-big.png') 0 0 no-repeat;
    }
}

.work-icon {
    width: auto;
    height: 16px;
}

.start-stand {
    position: absolute;
    top: 200px;
    left: 70px;
}

.pawn-details-container {
    z-index: 15;
    position: absolute;
    height: 50px;
    width: 50px;
}

.bg-end-signpost {
    width: 119px;
    height: 74px;
    background: url('/img/staking-map/biomes/spooky/shrine-sprite3.png') -133px -190px;
    position: absolute;
    top: 120px;
    right: 50px;
}

$milestone-top-offset: 100px;
$milestone-left-offset: 20px;

.milestone {
    top: 0;
    left: 0;
    position: absolute;
    margin: 0;
    padding: 0;

    &.mile-1 {
        top: calc(269px + #{$milestone-top-offset});
        left: calc(140px - #{$milestone-left-offset});
    }

    &.mile-2 {
        top: calc(13px + #{$milestone-top-offset});
        left: calc(288px - #{$milestone-left-offset});
    }

    &.mile-3 {
        top: calc(274px + #{$milestone-top-offset});
        left: calc(402px - #{$milestone-left-offset});
    }

    &.mile-4 {
        top: calc(51px + #{$milestone-top-offset});
        left: calc(535px - #{$milestone-left-offset});
    }

    &.mile-5 {
        top: calc(312px + #{$milestone-top-offset});
        left: calc(648px - #{$milestone-left-offset});
    }

    &.mile-6 {
        top: calc(74px + #{$milestone-top-offset});
        left: calc(760px - #{$milestone-left-offset});
    }
}

$shrine-height: 150px;
$shrine-width: 105px;
$shrine-top-offset: 0px;

.step {
    top: 0;
    left: 0;
    position: absolute;
    margin: 0;
    padding: 0;

    &.step-9 {
        top: calc(289px + #{$shrine-height});
        left: calc(140px - calc(#{$shrine-width} / 2));
    }

    &.step-10 {
        top: calc(33px + #{$shrine-height});
        left: calc(270px - calc(#{$shrine-width} / 2));
    }

    &.step-11 {
        top: calc(294px + #{$shrine-height});
        left: calc(395px - calc(#{$shrine-width} / 2));
    }

    &.step-12 {
        top: calc(75px + #{$shrine-height});
        left: calc(520px - calc(#{$shrine-width} / 2));
    }

    &.step-13 {
        top: calc(320px + #{$shrine-height});
        left: calc(600px - calc(#{$shrine-width} / 2));
    }

    &.step-14 {
        top: calc(74px - 25px + #{$shrine-height});
        left: calc(760px + 50px - calc(#{$shrine-width} / 2));
    }

    &.unknown-icon {
        padding: 20px 0 0 20px;

        img {
            width: 80px;
        }
    }
}

@media only screen and (max-width: $sm) {
    #map {
        width: 556px;
        height: 2166px;
    }

    #canvas {
        width: 556px;
        height: 2166px;
    }

    #map-img {
        width: 556px;
        height: 2166px;
        background: url('/img/staking-map/biomes/shrooms/map-mobile.png') 0 0 no-repeat;

        &.nextMapPreviewMap {
            background: url('/img/staking-map/biomes/spooky/map-mobile.png') 0 0 no-repeat;
        }
    }

    .bg-end-signpost {
        top: auto;
        bottom: 680px;
        right: auto;
        left: 200px;
    }

    .milestone {
        font-size: 30px;

        &.mile-1 {
            top: 328px;
            left: 517px;
        }

        &.mile-2 {
            top: 475px;
            left: 164px;
        }

        &.mile-3 {
            top: 800px;
            left: 130px;
        }

        &.mile-4 {
            top: 995px;
            left: 515px;
        }

        &.mile-5 {
            top: 1165px;
            left: 165px;
        }

        &.mile-6 {
            top: 1475px;
            left: 112px;
        }

        &.mile-7 {
            top: 1670px;
            left: 530px;
        }

        &.mile-8 {
            top: 1850px;
            left: 174px;
        }
    }

    .step {
        &.step-9 {
            top: calc(360px - 60px - 20px);
            left: calc(510px - 150px);
        }

        &.step-10 {
            top: calc(500px + 80px - 20px);
            left: calc(172px - 40px);
        }

        &.step-11 {
            top: calc(802px + 90px);
            left: calc(142px - 50px);
        }

        &.step-12 {
            top: calc(995px - 10px);
            left: calc(515px - 150px);
        }

        &.step-13 {
            top: 1250px;
            left: 130px;
        }

        &.step-14 {
            top: calc(1600px - 10px - 20px);
            left: calc(112px - 25px);
        }

        &.unknown-icon {
            img {
                width: 80px;
            }
        }
    }
}

@media only screen and (max-width: 520px) {
    #map {
        width: 250px;
        height: 974px;
    }

    #canvas {
        width: 250px;
        height: 974px;
    }

    #map-img {
        width: 250px;
        height: 820px;
        background: url('/img/staking-map/biomes/shrooms/map-mobile-xs.png') 0 0 no-repeat;

        &.nextMapPreviewMap {
            background: url('/img/staking-map/biomes/spooky/map-mobile.png') 0 0 no-repeat;
            background-size: 100% 100%;
        }
    }

    .bg-end-signpost {
        display: none;
    }

    .milestone {
        font-size: 30px;
        display: none;
    }

    .step {
        &.step-9 {
            top: calc(130px);
            left: calc(130px);
        }

        &.step-10 {
            top: calc(250px);
            left: calc(60px);
        }

        &.step-11 {
            top: calc(390px);
            left: calc(30px);
        }

        &.step-12 {
            top: calc(405px);
            left: calc(140px);
        }

        &.step-13 {
            top: 535px;
            left: 50px;
        }

        &.step-14 {
            top: 680px;
            left: 30px;
        }

        &.unknown-icon {
            img {
                width: 50px;
            }
        }

        &.tornado-icon {
            img {
                width: 60px;
            }
        }
    }
}
</style>
