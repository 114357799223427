var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "select-dialog" },
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "600" },
          model: {
            value: _vm.isOpen,
            callback: function ($$v) {
              _vm.isOpen = $$v
            },
            expression: "isOpen",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "box-decorated-edges-mini" },
            [
              _c(
                "div",
                {
                  staticClass: "close-btn pull-right",
                  on: {
                    click: function ($event) {
                      _vm.isOpen = false
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close-box")])],
                1
              ),
              _c(
                "v-card-title",
                { staticClass: "title justify-space-between" },
                [_c("span", [_vm._v("Reduce stake #" + _vm._s(_vm.id))])]
              ),
              _c(
                "v-card-text",
                { staticClass: "rewards mt-2 pt-6" },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c("v-col", { attrs: { cols: "4", md: "3" } }, [
                        _c("img", {
                          staticStyle: { height: "105px" },
                          attrs: { src: _vm.image },
                        }),
                      ]),
                      _c("v-col", { attrs: { cols: "8", md: "5" } }, [
                        _c("p", {}, [
                          _vm._v(
                            " Return selected amount of SCRL and reduce size of Caravan on the map. "
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "d-flex flex-row pos-relative mt-6" },
                          [
                            _c("input-amount", {
                              attrs: {
                                "init-value": _vm.initialAmountToReduce,
                                "max-value": _vm.stakeAmount,
                              },
                              on: { valueChanged: _vm.valueChanged },
                            }),
                            _c(
                              "v-btn",
                              {
                                staticStyle: {
                                  position: "absolute",
                                  bottom: "3px",
                                  left: "0",
                                  width: "200px",
                                },
                                attrs: { "x-small": "" },
                                on: {
                                  click: function ($event) {
                                    _vm.initialAmountToReduce = _vm.stakeAmount
                                  },
                                },
                              },
                              [_vm._v(" Max ")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("price", {
                            staticClass: "mt-8 pull-right",
                            attrs: { price: _vm.stakesPrice },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "pull-right ml-auto mr-5",
                      attrs: {
                        disabled:
                          _vm.amountToReduce > _vm.stakeAmount ||
                          _vm.amountToReduce <= 0,
                        loading: _vm.loadingData,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.requestReduce()
                        },
                      },
                    },
                    [_vm._v(" Reduce ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }